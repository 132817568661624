import { Dispatch, SetStateAction, useState } from 'react';
import { ProtocolReview } from '../../../shared/types/protocol';
import { useTestCmmImport } from '../../hooks/useTestCmmImport';
import { CMM_FILE_EXTENSIONS } from '../../services/cmm-import/importCmmFiles';
import PositionSelection from '../../types/positionSelection';
import { ProtocolDraft } from '../../types/sharedTypeImpl';
import { openLinkInNewTab } from '../../utils/navigation';
import Button from '../button';
import CmmFilesDialog from '../cmm-import/cmmFilesDialog';
import FileInputButton from '../fileInputButton';
import OperatorSelect from '../operatorSelect';
import { TestImportDialog } from '../testImportDialog';
import ProtocolFormPositions from './protocolFormPositions';
import ProtocolMetaFields from './protocolMetaFields';
import useFormKeyboardNavigation from './useFormKeyboardNavigation';
import { useUpdateProtocol } from './useUpdateProtocol';
import { validateProtocolDraft, validateProtocolReview } from './validateProtocolForm';


interface Props {
    className?: string;
    mode: 'edit' | 'view' | 'review';
    protocol: ProtocolDraft;
    setProtocol: Dispatch<SetStateAction<ProtocolDraft | null>>;
    pdfData: Uint8Array | null;
    setPdfData: (pdfData: Uint8Array | null) => void;
    positionSelection: PositionSelection | null;
    setPositionSelection: (positionSelection: PositionSelection | null) => void;
    showAllMarkers: boolean;
    setShowAllMarkers: (showAllMarkers: boolean) => void;
    showAllPosNrs: boolean;
    setShowAllPosNrs: (showAllPosNrs: boolean) => void;
    isDirty: boolean;
    setIsDirty: (isDirty: boolean) => void;
    isSaving: boolean;
    onDraftSave: () => void;
    onFinalSave: (protocol: ProtocolDraft) => void;
    onReviewSave: (review: ProtocolReview) => void;
    onCloseClicked: () => void;
}

export default function ProtocolForm({
    className,
    mode,
    protocol,
    setProtocol,
    pdfData,
    setPdfData,
    positionSelection,
    setPositionSelection,
    showAllMarkers,
    setShowAllMarkers,
    showAllPosNrs,
    setShowAllPosNrs,
    isDirty,
    setIsDirty,
    isSaving,
    onDraftSave,
    onFinalSave,
    onReviewSave,
    onCloseClicked,
}: Props) {

    const isFormReadOnly = mode === 'view' || mode === 'review';
    const [reviewer, setReviewer] = useState('');
    const [reviewComment, setReviewComment] = useState('');

    const {
        setProtocolField,
    } = useUpdateProtocol(protocol, setProtocol, setPositionSelection, setIsDirty);

    const {
        isManualImportDisabled,
        filesDialogState,
        positionsDialogState,
        handleManualImportClicked,
        handleFilesDialogSubmit,
        handleLocalFilesSelected,
        handleFilesDialogClose,
        handlePositionsDialogClose,
    } = useTestCmmImport({ protocol });

    const formRef = useFormKeyboardNavigation();


    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (mode !== 'edit') return;

        if (!pdfData) {
            alert('Must set a pdf');
            return;
        }
        if (!validateProtocolDraft(protocol)) {
            return;
        }

        onFinalSave(protocol);
    }

    const handleSaveDraft = (e: React.MouseEvent) => {
        e.preventDefault();
        onDraftSave();
    }

    const handleApproveClicked = (e: React.MouseEvent) => {
        e.preventDefault();

        const review = {
            approved: true,
            operator: reviewer,
        };

        if (!validateProtocolReview(review, protocol)) {
            return;
        }
        if (confirm('Approve?')) {
            onReviewSave({ approved: true, operator: reviewer });
        }
    }

    const handleRejectClicked = (e: React.MouseEvent) => {
        e.preventDefault();

        const review = {
            approved: false,
            operator: reviewer,
            comment: reviewComment,
        };

        if (!validateProtocolReview(review, protocol)) {
            return;
        }
        if (confirm('Reject?')) {
            onReviewSave({ approved: false, operator: reviewer, comment: reviewComment });
        }
    }

    const handleCloseClicked = (e: React.MouseEvent) => {
        e.preventDefault();
        onCloseClicked();
    }


    const latestReview = protocol.reviews?.at(-1);
    const showReviewer = mode === 'review' || latestReview;
    const showReviewComment = mode === 'review' || protocol.status === 'rejected';

    return (
        <div className={`${className} measurement-form`}>
            <Button
                onClick={() => openLinkInNewTab('https://berryglade.sharepoint.com/:w:/s/BGNET/EQqUpUnfYEtHkUFOiuILpRMBhOc9JOqz4pizmpFhLq1B5w?e=ECJOby')}
                content='Help'
            />

            <form
                ref={formRef}
                onSubmit={handleSubmit}
            >
                <table className='record-form--table'>
                    <tbody>
                        <ProtocolMetaFields
                            protocol={protocol}
                            readOnly={isFormReadOnly}
                            setProtocolField={setProtocolField}
                            setPdfData={setPdfData}
                        />
                    </tbody>
                </table>

                <div className='cmm-import-button-bar' style={{ marginTop: '12px' }}>
                    <Button
                        onClick={handleManualImportClicked}
                        disabled={isManualImportDisabled}
                        content='Test manual import'
                    />
                    <FileInputButton
                        allowedExtensions={CMM_FILE_EXTENSIONS}
                        onFilesSelected={handleLocalFilesSelected}
                        multiple
                        content='Test local import'
                    />
                </div>

                {protocol.positions.length > 0 &&
                    <ProtocolFormPositions
                        isReadOnly={isFormReadOnly}
                        protocol={protocol}
                        setProtocol={setProtocol}
                        positionSelection={positionSelection}
                        setPositionSelection={setPositionSelection}
                        showAllMarkers={showAllMarkers}
                        setShowAllMarkers={setShowAllMarkers}
                        showAllPosNrs={showAllPosNrs}
                        setShowAllPosNrs={setShowAllPosNrs}
                        setIsDirty={setIsDirty}
                    />
                }
                {(!isFormReadOnly && !protocol.positions.length) && <p>To add positions, right-click the pdf</p>}

                {(showReviewer || showReviewComment) &&
                    <table className='record-form--table' style={{ marginTop: '20px' }}>
                        <tbody>
                            {showReviewer && <tr>
                                <td>Reviewer</td>
                                <td>
                                    <OperatorSelect
                                        operatorInitials={mode === 'review' ? reviewer : latestReview?.operator ?? ''}
                                        readOnly={mode !== 'review'}
                                        onChange={(initials) => setReviewer(initials)}
                                        required
                                    />
                                </td>
                            </tr>}
                            {showReviewComment && <tr>
                                <td>Comment</td>
                                <td>
                                    <textarea
                                        name='reviewComment'
                                        value={mode === 'review' ? reviewComment : latestReview?.comment ?? ''}
                                        rows={3}
                                        readOnly={mode !== 'review'}
                                        onChange={(e) => setReviewComment(e.target.value)}
                                    />
                                </td>
                            </tr>}
                        </tbody>
                    </table>
                }


                <div style={{ marginTop: '20px' }}>
                    {mode === 'edit' && <>
                        <button type='submit'>Submit for review</button>
                        <button onClick={handleSaveDraft}>Save draft</button>
                    </>}
                    {mode === 'review' && <>
                        <button onClick={handleApproveClicked}>Approve</button>
                        <button onClick={handleRejectClicked}>Reject</button>
                    </>}
                    <button onClick={handleCloseClicked}>Close</button>
                    <span>{isSaving ? ' Saving...' : (!isDirty && !isFormReadOnly && protocol._id) && ' Changes saved'}</span>
                </div>
            </form>

            {filesDialogState &&
                <CmmFilesDialog
                    isOpen={true}
                    cmmReports={filesDialogState.files}
                    initialFilter={filesDialogState.initialFilter}
                    onCancel={handleFilesDialogClose}
                    onSubmit={handleFilesDialogSubmit}
                />
            }
            {positionsDialogState &&
                <TestImportDialog
                    importResult={positionsDialogState}
                    onClose={handlePositionsDialogClose}
                />
            }
        </div>
    );
}
