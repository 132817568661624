import { DateTime } from 'luxon';


//If given the exact moment a shift changes, then it is considered to be a shift end 
// and the previous shift start is returned.
export function getShiftStart(date: Date): Date;
export function getShiftStart(date: DateTime): DateTime;
export function getShiftStart(date: Date | DateTime): Date | DateTime {
    const convertFromJsDate = date instanceof Date;
    const dateLuxon = convertFromJsDate ? toLuxonDate(date) : date;

    let shiftStart;
    const dateAtHour = dateLuxon.set({ minute: 0, second: 0, millisecond: 0 });
    if (dateAtHour.hour > 22) {
        shiftStart = dateAtHour.set({ hour: 22 });
    } else if (dateAtHour.hour > 14) {
        shiftStart = dateAtHour.set({ hour: 14 });
    } else if (dateAtHour.hour > 6) {
        shiftStart = dateAtHour.set({ hour: 6 });
    } else {
        shiftStart = dateAtHour.minus({ days: 1 }).set({ hour: 22 });
    }

    return (convertFromJsDate ? shiftStart.toJSDate() : shiftStart);
}

//If given the exact moment a shift changes, then it is considered to be a shift start 
// and the next shift end is returned. 
export function getShiftEnd(date: Date): Date;
export function getShiftEnd(date: DateTime): DateTime;
export function getShiftEnd(date: Date | DateTime): Date | DateTime {
    getShiftStart(new Date())
    const convertFromJsDate = date instanceof Date;
    const dateLuxon = convertFromJsDate ? toLuxonDate(date) : date;

    let shiftEnd;
    const dateAtHour = dateLuxon.set({ minute: 0, second: 0, millisecond: 0 });
    if (dateAtHour.hour >= 22) {
        shiftEnd = dateAtHour.plus({ days: 1 }).set({ hour: 6 });
    } else if (dateAtHour.hour >= 14) {
        shiftEnd = dateAtHour.set({ hour: 22 });
    } else if (dateAtHour.hour >= 6) {
        shiftEnd = dateAtHour.set({ hour: 14 });
    } else {
        shiftEnd = dateAtHour.set({ hour: 6 });
    }

    return (convertFromJsDate ? shiftEnd.toJSDate() : shiftEnd);
}

export function getLastWorkDayStart(date: Date): Date;
export function getLastWorkDayStart(date: DateTime): DateTime;
export function getLastWorkDayStart(date: Date | DateTime): Date | DateTime {
    const convertFromJsDate = date instanceof Date;
    const dateLuxon = convertFromJsDate ? toLuxonDate(date) : date;

    let firstShiftStart;
    const dateAtHour = dateLuxon.set({ minute: 0, second: 0, millisecond: 0 });
    if (dateAtHour.hour > 6) {
        firstShiftStart = dateAtHour.set({ hour: 6 });
    } else {
        firstShiftStart = dateAtHour.minus({ days: 1 }).set({ hour: 6 });
    }

    return (convertFromJsDate ? firstShiftStart.toJSDate() : firstShiftStart);
}

//If given the exact moment a shift changes, then it is considered to be a shift start
export function getShiftClassAt(date: Date | DateTime): string {
    const convertFromJsDate = date instanceof Date;
    const dateLuxon = convertFromJsDate ? toLuxonDate(date) : date;

    if (dateLuxon.hour >= 22) {
        return 'night';
    } else if (dateLuxon.hour >= 14) {
        return 'evening';
    } else if (dateLuxon.hour >= 6) {
        return 'morning';
    } else {
        return 'night';
    }
}

//If given the exact moment a shift changes, then it is considered to be a shift start
export function isCoreShift(date: Date | DateTime): boolean {
    const convertFromJsDate = date instanceof Date;
    const dateLuxon = convertFromJsDate ? toLuxonDate(date) : date;

    if (dateLuxon.weekday >= 6 || dateLuxon.hour >= 22) {
        return false;
    } else if (dateLuxon.hour >= 6) {
        return true;
    } else {
        return false;
    }
}

//If given the exact moment a shift changes, then it is considered to be a shift start
export function getShiftNameAt(date: Date | DateTime): string {
    const convertFromJsDate = date instanceof Date;
    const dateLuxon = convertFromJsDate ? toLuxonDate(date) : date;

    const firstMondayOf2023 = DateTime.fromISO('2023-01-02T00:00:00', { zone: 'Europe/Helsinki' });
    const weeksDifference = Math.floor(dateLuxon.diff(firstMondayOf2023, 'weeks').weeks);
    const isEvenWeek = weeksDifference % 2 === 0;

    if (dateLuxon.weekday == 7
        || (dateLuxon.weekday == 6 && dateLuxon.hour >= 6)
        || (dateLuxon.weekday == 1 && dateLuxon.hour < 6)) {
        return 'W';
    } else if (dateLuxon.hour >= 22) {
        return 'N';
    } else if (dateLuxon.hour >= 14) {
        return isEvenWeek ? 'B' : 'A';
    } else if (dateLuxon.hour >= 6) {
        return isEvenWeek ? 'A' : 'B';
    } else {
        return 'N';
    }
}

function toLuxonDate(date: Date): DateTime {
    return DateTime.fromJSDate(date).setZone('Europe/Helsinki');
}
