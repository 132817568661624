import { IoqcType } from '../../../shared/types/record';


interface Props {
    value: IoqcType | undefined;
    onChange: (value: IoqcType) => void;
    required?: boolean;
    readonly?: boolean;
    disabled?: boolean;
}

export default function IoqcTypeSelect({
    value,
    onChange,
    required,
    readonly,
    disabled,
}: Props) {


    const options: { value: IoqcType; label: string }[] = [
        { value: 'iqc', label: 'IQC' },
        { value: 'oqc', label: 'OQC' },
        { value: 'rma', label: 'RMA' },
    ];

    return (
        <select
            value={value ?? ''}
            onChange={(e) => onChange(e.target.value as IoqcType)}
            style={{ pointerEvents: readonly ? 'none' : 'auto' }}
            required={required}
            disabled={disabled}
        >
            <option value='' disabled hidden />
            {options?.map((it) => (
                <option key={it.value} value={it.value}>
                    {it.label}
                </option>
            ))}
        </select>
    )
}
