import { Dispatch, SetStateAction, useState } from 'react';
import { Point } from '../../../shared/types/mathTypes';
import PositionSelection from '../../types/positionSelection';
import { ProtocolDraft } from '../../types/sharedTypeImpl';
import FormPositionMenu from './formPositionMenu';
import { MeasurementPosition } from './measurementPosition';
import { useUpdateProtocol } from './useUpdateProtocol';
import { VisualInspectionPosition } from './visualInspectionPosition';

interface PositionMenuState {
    location: Point;
    positionSelection: PositionSelection;
}


interface Props {
    isReadOnly: boolean;
    protocol: ProtocolDraft,
    setProtocol: Dispatch<SetStateAction<ProtocolDraft | null>>,
    positionSelection: PositionSelection | null,
    setPositionSelection: (positionSelection: PositionSelection | null) => void,
    showAllMarkers: boolean,
    setShowAllMarkers: (showAllMarkers: boolean) => void,
    showAllPosNrs: boolean,
    setShowAllPosNrs: (showAllPosNrs: boolean) => void,
    setIsDirty: (isDirty: boolean) => void
}

export default function ProtocolFormPositions({
    isReadOnly,
    protocol,
    setProtocol,
    positionSelection,
    setPositionSelection,
    showAllMarkers,
    setShowAllMarkers,
    showAllPosNrs,
    setShowAllPosNrs,
    setIsDirty,
}: Props) {
    const [positionMenu, setPositionMenu] = useState<PositionMenuState | null>(null);

    const {
        setMarkerField,
        reorderPositions,
        removePosition,
        removeMarker,
    } = useUpdateProtocol(protocol, setProtocol, setPositionSelection, setIsDirty);


    function handleMenuOpen(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        if (isReadOnly || !positionSelection) return;
        setPositionMenu({
            location: { x: e.clientX, y: e.clientY },
            positionSelection,
        });
    }


    return (
        <>
            <table className='measurement-form__positions'>
                <thead>
                    <tr>
                        <th style={{ width: '65px' }} />
                        <th>Nominal</th>
                        <th style={{ width: '70px' }} >Symbol</th>
                        <th>Upper tol.</th>
                        <th>Lower tol.</th>
                        <th style={{ width: '70px' }} >Critical</th>
                        <th style={{ width: '70px' }} >Use GT</th>
                        <th style={{ width: '75px' }} >Chamfer</th>
                        <th style={{ width: '88px' }} >Tool</th>
                        <th style={{ width: '40px' }} />
                    </tr>
                </thead>
                <tbody>
                    {protocol.positions?.map((it, index) =>
                        it.type === 'measurement'
                            ? <MeasurementPosition
                                key={it.key}
                                index={index}
                                protocol={protocol}
                                setProtocol={setProtocol}
                                positionSelection={positionSelection}
                                setPositionSelection={setPositionSelection}
                                setIsDirty={setIsDirty}
                                isReadOnly={isReadOnly}
                                onMenuOpenClick={handleMenuOpen}
                            />
                            : <VisualInspectionPosition
                                key={it.key}
                                index={index}
                                protocol={protocol}
                                setProtocol={setProtocol}
                                positionSelection={positionSelection}
                                setPositionSelection={setPositionSelection}
                                setIsDirty={setIsDirty}
                                isReadOnly={isReadOnly}
                                onMenuOpenClick={handleMenuOpen}
                            />
                    )}
                </tbody>
            </table>

            <div className='flex--horz' style={{ gap: '20px', marginTop: '8px', marginLeft: '4px' }}>
                {!isReadOnly &&
                    <button
                        style={{ display: 'block' }}
                        type='button'
                        onClick={reorderPositions}
                        children='Reorder positions'
                    />
                }
                <label className='with-checkbox'>
                    Show all positions
                    <input
                        type='checkbox'
                        checked={showAllMarkers}
                        onChange={(e) => setShowAllMarkers(e.target.checked)}
                    />
                </label>
                <label className='with-checkbox'>
                    Show positions numbers
                    <input
                        type='checkbox'
                        checked={showAllPosNrs}
                        onChange={(e) => setShowAllPosNrs(e.target.checked)}
                    />
                </label>
            </div>

            {positionMenu && <FormPositionMenu
                protocol={protocol}
                positionSelection={positionMenu.positionSelection}
                screenCoords={positionMenu.location}
                setMarkerField={setMarkerField}
                removePosition={removePosition}
                removeMarker={removeMarker}
                onClose={() => setPositionMenu(null)}
            />}
        </>
    )
}
