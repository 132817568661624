import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { sortBy } from '../../../shared/utils/arrayUtils';
import { fetchProtocols } from '../../api/requests';
import Button from '../../components/button';
import ModalDialog from '../../components/modalDialog';
import { Record } from '../../types/sharedTypeImpl';
import { formatDatetime } from '../../utils/fieldFormats';
import { trpc } from '../../utils/trpc';


interface Props {
    isOpen: boolean,
    onRecordCreated: (record: Record) => void,
    onClose: () => void,
}

export default function ProtocolSelectDialog({
    isOpen,
    onRecordCreated,
    onClose,
}: Props) {

    const [filter, setFilter] = useState('');


    const { data: protocols, isLoading } = useQuery({
        queryKey: ['protocols',],
        queryFn: async () => fetchProtocols({ isNotDisabled: true }),
        select: (protocols) => sortBy([...protocols], it => it.partDisplayName),
        enabled: isOpen,
    });

    const recordDraftMutation = trpc.records.createRecordDraft.useMutation({
        onSuccess: (record) => {
            onRecordCreated(record);
            onClose();
        },
        onError: (err) => {
            console.error(err);
            alert('Failed to create record');
        },
    });


    const filteredProtocols = filter
        ? protocols?.filter(it => {
            const query = filter.toLowerCase().trim();
            return it.partDisplayName.toLowerCase().includes(query)
                || it.programs.some(it => it.toLowerCase().includes(query))
        })
        : protocols;


    const handleProtocolSelected = async (protocolId: string) => {
        if (recordDraftMutation.isPending || recordDraftMutation.isSuccess) return;
        recordDraftMutation.mutate({
            measurementType: 'ioqc',
            protocolId,
        });
    }


    const selectedProtocol = recordDraftMutation.variables?.protocolId;

    return (
        <ModalDialog
            isOpen={isOpen}
            cancelOnOutsideClick={true}
            onCancel={onClose}
        >
            <div style={{ paddingBottom: '8px' }}>
                <span>Filter: </span>
                <input
                    type='text'
                    value={filter}
                    onChange={e => setFilter(e.target.value)}
                />
            </div>

            {isLoading
                ? <p>Loading protocols...</p>
                : <table className='table--protocol-selection'>
                    <thead>
                        <tr>
                            <th>Target</th>
                            <th>Version</th>
                            <th>Date created</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {filteredProtocols?.map(it =>
                            <tr key={it._id}>
                                <td>{it.partDisplayName}</td>
                                <td>{it.version}</td>
                                <td>{formatDatetime(it.createTime)}</td>
                                <td>
                                    <Button
                                        onClick={() => handleProtocolSelected(it._id!)}
                                        content={it._id === selectedProtocol ? 'Adding...' : 'Measure'}
                                    />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            }
        </ModalDialog>
    );
}
