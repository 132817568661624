import { overlappingTime } from '../../../back/utils/time-periods/overlappingPeriods.js';
import { getShiftEnd, getShiftStart, isCoreShift } from './shifts.js';


export function getWorkTimeInPeriod(start: Date, end: Date): number {
    const period = { start, end };

    let timeInPeriod = 0;
    let currentShift = getShiftUsingStart(getShiftStart(start));
    while (true) {
        const addCurrentShift = isCoreShift(currentShift.start);
        if (addCurrentShift) {
            timeInPeriod += overlappingTime(currentShift, period);
        }

        if (currentShift.end >= end) {
            break;
        } else {
            currentShift = getShiftUsingStart(currentShift.end);
        }
    }
    return timeInPeriod;
}

function getShiftUsingStart(shiftStartTime: Date) {
    return {
        start: shiftStartTime,
        end: getShiftEnd(shiftStartTime),
    };
}
