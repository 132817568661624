
import { useEffect, useRef, useState } from 'react';
import { SpcTogglesMap } from '../../../shared/types/spc';
import { ProtocolFinal, RecordDraft, RecordWithProtocol } from '../../types/sharedTypeImpl';
import ModalDialog from '../modalDialog';
import drawMeasurementGraph from './drawMeasurementGraph';
import getMetaData, { MetaData } from './getMetaData';
import MeasurementGraphSettings from './measurementGraphSettings';
import transformDataPoints, { DataPoint } from './transformDataPoints';


const settingsDefault = { lineVisibilities: { nominal: true, tolerance: true, mean: true } };


interface Props {
    className?: string,
    style?: React.CSSProperties,
    protocol: ProtocolFinal,
    prevRecords: RecordWithProtocol[] | undefined,
    currentRecord: RecordDraft | null,
    selectedPositionIdx: number | undefined,
    openDialogOnClick: boolean,
    maxDataPoints?: number | null,
    settings?: MeasurementGraphSettings | null,
    spcTogglesMap?: SpcTogglesMap,
}

export default function MeasurementsGraph({
    className,
    style,
    protocol,
    prevRecords,
    currentRecord,
    selectedPositionIdx,
    openDialogOnClick,
    spcTogglesMap,
    maxDataPoints,
    settings,
}: Props) {

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dataPoints, setDataPoints] = useState<DataPoint[]>([]);
    const [metaData, setMetaData] = useState<MetaData | null>(null);

    const defaultContainerRef = useRef<HTMLDivElement>(null);
    const dialogContainerRef = useRef<HTMLDivElement>(null);

    const settingsCurrent = settings ?? settingsDefault;


    //Create graph data
    useEffect(() => {
        if (selectedPositionIdx == null) return undefined;

        const positionNumber = protocol.positions[selectedPositionIdx].positionNumber;
        const enabledChecks = spcTogglesMap?.[positionNumber];
        const dataPoints = transformDataPoints(
            protocol, prevRecords, currentRecord, selectedPositionIdx, enabledChecks, maxDataPoints);
        const metaData = getMetaData(dataPoints, protocol, selectedPositionIdx, settingsCurrent);
        const finalDataPoints = dataPoints.filter(it => it.isInRange);

        setDataPoints(finalDataPoints);
        setMetaData(metaData);
    }, [protocol, prevRecords, currentRecord, selectedPositionIdx, maxDataPoints, spcTogglesMap, settingsCurrent]);

    //Draw default graph
    useEffect(() => {
        if (metaData == null) return undefined;
        drawMeasurementGraph(
            defaultContainerRef.current!,
            dataPoints,
            metaData,
            true,
            settingsCurrent
        );
    }, [dataPoints, metaData, settingsCurrent]);

    //Draw dialog graph
    useEffect(() => {
        if (!isDialogOpen || metaData == null) return undefined;
        drawMeasurementGraph(
            dialogContainerRef.current!,
            dataPoints,
            metaData,
            false,
            settingsCurrent);
    }, [isDialogOpen, dataPoints, metaData, settingsCurrent]);


    return (
        <>
            <div
                ref={defaultContainerRef}
                className={className}
                style={style}
                onClick={() => openDialogOnClick && setIsDialogOpen(true)}
            />

            <ModalDialog
                isOpen={isDialogOpen}
                cancelOnOutsideClick={true}
                onCancel={() => setIsDialogOpen(false)}
            >
                <div
                    style={{ width: '1200px', height: '400px' }}
                    ref={dialogContainerRef}
                    className={className}
                />
            </ModalDialog>
        </>
    );
}
