import { CmmFileRead } from './types';


export default function readKeyenceCmmFile(cmmFileText: string): CmmFileRead {
    try {
        const table = cmmFileText.trim().split('\n').map(row => row.split(';').map(cell => cell.trim()));

        const isValidated = table.every(row => row.length === 6);
        if (!isValidated) {
            return { success: false, errorMessage: 'Invalid file format' };
        }

        const positions = [];
        for (const row of table) {
            const positionNumber = row[0].match(/Pos ?(\d+(\.\d+)?).*?/)?.[1];
            const actualAsFloat = Number.parseFloat(row[1].replace(',', '.'));
            if (!positionNumber || isNaN(actualAsFloat)) continue;
            positions.push({ positionNumber, value: actualAsFloat.toFixed(3) });
        }

        return {
            success: true,
            valueSource: 'vmm',
            valueSourceSubType: 'keyence',
            positions,
        }
    } catch (error) {
        console.error('Error reading file:', error);
        return { success: false, errorMessage: 'Error reading file' };
    }
}
