import { useState } from 'react';
import Button from '../../components/button';
import { trpc } from '../../utils/trpc';
import CommentDialog from './commentDialog';
import { SelectedEquipment, SelectedPart } from './types';


interface Props {
    selectedEquipment: SelectedEquipment | null;
    selectedPart: SelectedPart | null;
}

export default function CommentPanel({
    selectedEquipment,
    selectedPart,
}: Props) {

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const target = selectedEquipment?.source === 'mtlinki' && selectedPart?.partName
        ? { target: selectedPart.partName, targetType: 'partName' as const, displayName: selectedPart.partName }
        : selectedEquipment?.source === 'custom'
            ? { target: selectedEquipment.id, targetType: 'equipment' as const, displayName: selectedEquipment.displayName }
            : undefined;

    const { data: comment, refetch } = trpc.comments.get.useQuery(target!, { enabled: !!target });


    return (
        <>
            <div style={{ display: 'flex', marginTop: '8px' }}>
                <textarea
                    className='product-comment'
                    style={{ flexGrow: 1 }}
                    readOnly
                    value={comment?.text ?? ''}
                    rows={3}
                />
                <Button
                    disabled={target == null}
                    onClick={() => setIsDialogOpen(true)}
                    content='Edit'
                />
            </div>

            {(target && isDialogOpen) &&
                <CommentDialog
                    target={target.target}
                    targetType={target.targetType}
                    displayName={target.displayName}
                    initialText={comment?.text}
                    onSubmited={() => refetch()}
                    onClose={() => setIsDialogOpen(false)}
                />
            }
        </>
    );
}
