import { Position, PositionType, ToolType } from '../../../shared/types/protocol';
import { sortBy } from '../../../shared/utils/arrayUtils';
import { getToleranceRange, getToleranceRangeStrings } from '../../../shared/utils/getToleranceRange';
import { Protocol } from '../../types/sharedTypeImpl';
import readCmmFile from './readCmmFile';
import { CmmFileRead, TestImportFile, TestImportPosition } from './types';


export async function testImportCmmFiles(
    protocol: Protocol,
    files: File[],
): Promise<TestImportFile> {
    const cmmReadResults = await Promise.all(files.map(readCmmFile));
    const readErrors = cmmReadResults.filter(it => !it.success).map(it => it.errorMessage);
    if (readErrors.length > 0) {
        return {
            success: false,
            errorMessage: readErrors[0],
        }
    }

    const positionNumberCounts = countPositionNumbers(cmmReadResults);
    const positionResults = checkPositions(cmmReadResults, protocol, positionNumberCounts);

    sortBy(positionResults, it => +it.positionNumber);
    return {
        success: true,
        positions: positionResults,
    };
}


function countPositionNumbers(cmmReadResults: CmmFileRead[]): Map<string, number> {
    const positionNumberCounts = new Map<string, number>();
    for (const cmmReadResult of cmmReadResults) {
        for (const position of cmmReadResult.positions!) {
            positionNumberCounts.set(
                position.positionNumber,
                (positionNumberCounts.get(position.positionNumber) ?? 0) + 1
            );
        }
    }
    return positionNumberCounts;
}

function checkPositions(
    cmmReadResults: CmmFileRead[],
    protocol: Protocol,
    positionNumberCounts: Map<string, number>
): TestImportPosition[] {
    // Check positions in CMM file
    const positionResults: TestImportPosition[] = [];
    for (const cmmReadResult of cmmReadResults) {
        for (const cmmPosition of cmmReadResult.positions!) {
            const parsedImportedValue = parseFloat(cmmPosition.value);
            const protocolPositionIdx = protocol.positions.findIndex(it => it.positionNumber === cmmPosition.positionNumber);
            const protocolPosition = protocol.positions[protocolPositionIdx];

            const issues: string[] = [];
            if (protocolPositionIdx < 0) {
                issues.push('Position not in protocol');
            } else if (protocolPosition.type === 'visualInspection') {
                issues.push('Invalid position type');
            } else {
                const { min, max } = getToleranceRange(protocolPosition);
                if (parsedImportedValue < min || parsedImportedValue > max) {
                    const { min: minString, max: maxString } = getToleranceRangeStrings(protocolPosition);
                    issues.push(`Out of tolerance: ${minString}...${maxString}`);
                }
            }
            if (positionNumberCounts.get(cmmPosition.positionNumber)! > 1) {
                issues.push('Duplicate position');
            }

            positionResults.push({
                positionNumber: cmmPosition.positionNumber,
                positionType: getPositionType(protocolPosition),
                value: cmmPosition.value,
                isOk: issues.length === 0,
                issues,
            });
        }
    }

    // Check for missing positions
    for (const position of protocol.positions) {
        if (positionNumberCounts.get(position.positionNumber)) continue

        const isIssue = position.type === 'measurement'
            && (position.toolType === 'CMM' || position.toolType === 'VMM');

        positionResults.push({
            positionNumber: position.positionNumber,
            positionType: getPositionType(position),
            value: '',
            isOk: !isIssue,
            issues: isIssue ? ['Position not in CMM file'] : [],
        });
    }

    return positionResults;
}

function getPositionType(
    position: Position | undefined
): PositionType | ToolType | undefined {
    if (!position) return undefined;
    return position.type === 'visualInspection'
        ? position.type
        : position.toolType;
}