import { useState } from 'react';
import { MaintenanceOperationType, MaintenanceOperationTypeLabels, MaintenanceOperationTypes } from '../../../shared/types/maintenanceOperation';
import { postMaintenanceOperation } from '../../api/requests';
import ModalDialog from '../../components/modalDialog';
import OperatorSelect from '../../components/operatorSelect';
import { MaintenanceOperation } from '../../types/sharedTypeImpl';


interface Props {
    equipment: string;
    operationUnderEdit?: MaintenanceOperation;
    onOperationSaved: () => void;
    onClose: () => void;
}

export default function MaintenanceOperationDialog({
    equipment,
    operationUnderEdit,
    onOperationSaved,
    onClose,
}: Props) {

    const [operation, setOperation] = useState<Partial<MaintenanceOperation>>(operationUnderEdit ?? { equipment });


    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        await postMaintenanceOperation(operation as MaintenanceOperation);
        onOperationSaved();
        onClose();
    }


    return (
        <ModalDialog
            isOpen={true}
            frameClassName='modal-frame--operation-dialog'
            contentClassName='modal-content--tablet'
            onCancel={onClose}
        >
            <form
                onSubmit={handleSubmit}
            >
                <div className='dialog-form__field--with-margins'>
                    <div>Operation type</div>
                    <OperationType
                        operationType={operation.operationType ?? null}
                        onOperationTypeChange={(operationType) => setOperation({ ...operation, operationType })}
                    />
                </div>
                <div>
                    <div>Comment</div>
                    <textarea
                        className='dialog-form__input'
                        rows={3}
                        value={operation.comment ?? ''}
                        required
                        onChange={(e) => setOperation({ ...operation, comment: e.target.value })}
                    />
                </div>
                <div className='dialog-form__field--with-margins'>
                    <div>Operator</div>
                    <OperatorSelect
                        className='dialog-form__input'
                        operatorInitials={operation.operator ?? ''}
                        required
                        onChange={(operatorInitials) => setOperation({ ...operation, operator: operatorInitials })}
                    />
                </div>

                <div className='dialog--button-bar'>
                    <button className='button--large' type='submit'>Submit</button>
                </div>
            </form>
        </ModalDialog>
    )
}


interface OperationTypeProps {
    operationType: MaintenanceOperationType | null;
    onOperationTypeChange: (operationType: MaintenanceOperationType) => void;
}
function OperationType({
    operationType,
    onOperationTypeChange,
}: OperationTypeProps) {
    return (
        <fieldset className='dialog-form__fieldset'>
            {MaintenanceOperationTypes.map(it => (
                <label key={it}>
                    <input
                        type='radio'
                        name='operationType'
                        value={it}
                        required
                        checked={it === operationType}
                        onChange={() => onOperationTypeChange(it)}
                    />
                    {MaintenanceOperationTypeLabels[it]}
                </label>
            ))}
        </fieldset>
    );
}
