import { Equipment } from '../../../shared/types/equipment';
import LinkButton from '../../components/linkButton';


interface Props {
    style: React.CSSProperties;
    equipments: Equipment[];
    onIsActiveChange: (equipment: Equipment, isActive: boolean) => void;
    onEditClick: (equipment: Equipment) => void;
}

export default function CustomEquipmentsTable({
    style,
    equipments,
    onIsActiveChange,
    onEditClick,
}: Props) {
    return (
        <table style={style}>
            <thead>
                <tr>
                    <th>Is active</th>
                    <th>Id</th>
                    <th>Display name</th>
                    <th>Edit</th>
                </tr>
            </thead>
            <tbody>
                {equipments?.map(it =>
                    <tr key={it.id}>
                        <td>
                            <input
                                type='checkbox'
                                checked={it.isActive}
                                onChange={(e) => onIsActiveChange(it, e.target.checked)}
                            />
                        </td>
                        <td>{it.id}</td>
                        <td>{it.displayName}</td>
                        <td>
                            <LinkButton
                                onClick={() => onEditClick(it)}
                                content='Edit'
                            />
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}
