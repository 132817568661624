import NavBar from '../../components/navBar';
import { PageTitle } from '../../components/pageTitle';
import Diagnostics from './diagnostics';
import ReleaseNotes from './releaseNotes';


export default function Index() {

    return (
        <>
            <PageTitle title='BG Web Apps' />
            <NavBar />
            <div
                className='content'
                style={{marginTop: '30px', marginLeft: '40px', marginRight: '40px'}}
            >
                <Diagnostics />
                <ReleaseNotes />
            </div>
        </>
    );
}
