import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LinkButton from '../../components/linkButton';
import { Record, RecordFinal } from '../../types/sharedTypeImpl';
import { formatDatetime } from '../../utils/fieldFormats';
import RecordContextMenu, { RecordContextMenuState } from './recordContextMenu';


interface Props {
    isLoading: boolean;
    error: any;
    records: Record[] | undefined;
    onRecordDelete: (record: Record) => void;
    onRecordsExport: (record: Record) => void;
    onSearchTextChange: (searchText: string) => void;
}

export default function RecordListTable({
    isLoading,
    error,
    records,
    onRecordDelete,
    onRecordsExport,
    onSearchTextChange,
}: Props) {
    const [menuProps, setMenuProps] = useState<RecordContextMenuState | undefined>(undefined);

    const location = useLocation();
    const navigate = useNavigate();

    function handleMenuOpen(e: React.MouseEvent, record: Record) {
        e.preventDefault();
        setMenuProps({ record, screenCoords: { x: e.clientX, y: e.clientY } });
    }

    function handleOpenRecord(record: Record, editMode?: boolean) {
        const mode = editMode ? 'edit' : 'view';
        const newUrl = `/record-view?id=${record._id}&mode=${mode}`;
        const returnTo = location.pathname + location.search;
        navigate(newUrl, { state: { returnTo } });
    }


    if (!records && isLoading) return <div>Loading...</div>;
    if (error) return <div>Failed to fetch records: {error.message}</div>;
    return (
        <>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Equipment</th>
                        <th>Part number</th>
                        <th>Version</th>
                        <th>Order number</th>
                        <th>Part count</th>
                        <th>Measurement time</th>
                        <th>User</th>
                        <th>Status</th>
                        <th>Measured</th>
                        <th>Out of spec</th>
                        <th />
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {records?.map(record =>
                        <Row
                            key={record._id}
                            record={record}
                            onMenuOpen={handleMenuOpen}
                            onSearchTextChange={onSearchTextChange}
                        />
                    )}
                </tbody>
            </table>

            {menuProps && <RecordContextMenu
                record={menuProps.record}
                screenCoords={menuProps.screenCoords}
                onDeleteSelected={onRecordDelete}
                onEditSelected={record => handleOpenRecord(record, true)}
                onExportSelected={onRecordsExport}
                onClose={() => setMenuProps(undefined)}
            />}
        </>
    );
}

function Row({
    record,
    onMenuOpen,
    onSearchTextChange,
}: {
    record: Record,
    onMenuOpen: (e: React.MouseEvent, record: Record) => void,
    onSearchTextChange: (searchText: string) => void,
}) {
    const numPosFields = record.parts.length * (record.parts[0].positionValues.length + 1);
    const numPosFilled = record.numValuesFilled;
    const measuredCellClass =
        record.criticalValuesMissing && !record.isDraft ? 'records-table--highlight-critical'
            : numPosFilled < numPosFields && !record.isDraft ? 'records-table--highlight-warning'
                : '';

    const numIssues = !record.isDraft ? (record as RecordFinal).numIssues : undefined;
    const issuesCellClass = numIssues && numIssues > 0 ? 'records-table--highlight-warning' : '';

    return (
        <tr key={record._id}>
            <td>
                <Link
                    className='link-button--non-intrusive'
                    to={`/machine-log#equipment=${record.equipment}`}
                    children={record.equipmentDisplayName}
                />
            </td>
            <td>
                <span
                    className='link-button--non-intrusive'
                    onClick={() => onSearchTextChange(record.protocolPartDisplayName)}
                    children={record.protocolPartDisplayName}
                />
            </td>
            <td>{record.protocolVersion}</td>
            <td>
                <span
                    className='link-button--non-intrusive'
                    onClick={() => onSearchTextChange(record.orderNumber)}
                    children={record.orderNumber}
                />
            </td>
            <td>{record.partCount}</td>
            <td>{formatDatetime(record.measurementStart)}</td>
            <td>{record.user}</td>
            <td>{record.isDraft ? 'Draft' : ''}</td>
            <td className={measuredCellClass}>
                {numPosFilled} / {numPosFields}
            </td>
            <td className={issuesCellClass}>
                {numIssues}
            </td>
            <td>{record.isDraft
                ? <Link
                    to={`/record-view?id=${record._id}&mode=edit`}
                    state={{ returnTo: location.pathname + location.search }}
                    children='Continue'
                />
                : <Link
                    to={`/record-view?id=${record._id}&mode=view`}
                    state={{ returnTo: location.pathname + location.search }}
                    children='View'
                />
            }</td>
            <td>
                <LinkButton
                    onClick={(e) => { onMenuOpen(e, record) }}
                    children='Options'
                />
            </td>
        </tr >
    );
}
