import { useEffect, useRef } from 'react';
import { getMeasurementDelayBucket, getMeasurementDelayBucketColor, NUM_MEASUREMENT_DELAY_BUCKETS } from '../../../shared/services/measurementDelayBuckets';
import { MeasurementDelay } from '../../../shared/types/measurementDelay';
import { sumOf } from '../../../shared/utils/arrayUtils';
import { ReportPeriod } from '../../types/timePeriods';
import { DelaysGraphData } from './delaysGraphData';
import { drawDelaysGraph } from './drawDelaysGraph';


interface Props {
    style?: React.CSSProperties;
    measurementDelays: MeasurementDelay[];
    periods: ReportPeriod[];
}

export default function DelaysGraph({
    style,
    measurementDelays,
    periods,
}: Props) {
    const graphContainerRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        const transformedData = transformData(measurementDelays, periods);
        drawDelaysGraph(graphContainerRef.current!, transformedData);
    }, [measurementDelays, periods]);


    function transformData(
        measurementDelays: MeasurementDelay[],
        periods: ReportPeriod[],
    ): DelaysGraphData[] {
        const data: DelaysGraphData[] = [];
        for (const period of periods) {
            const measurementDelaysInPeriod = measurementDelays.filter(it =>
                it.measurementTime >= period.start && it.measurementTime < period.end
            );

            const bucketCounts = new Array(NUM_MEASUREMENT_DELAY_BUCKETS).fill(0);
            for (const measurementDelay of measurementDelaysInPeriod) {
                if (measurementDelay.delay == null) continue;
                if (measurementDelay.targetShiftName === 'N' || measurementDelay.targetShiftName === 'W') continue;
                if (measurementDelay.targetShiftName !== measurementDelay.measurementShiftName) continue;
                const bucket = getMeasurementDelayBucket(measurementDelay.delay);
                bucketCounts[bucket]++;
            }
            const totalCount = sumOf(bucketCounts, it => it);

            const bucketPercentages = bucketCounts.map(it => it / totalCount);
            const percentagesRounded = bucketPercentages.map(it => Math.round(it * 100));
            const bucketLabels = percentagesRounded.map(it => `${it}%`);
            const bucketColors = Array.from(
                { length: NUM_MEASUREMENT_DELAY_BUCKETS },
                (_, i) => getMeasurementDelayBucketColor(i)
            );

            const sections = bucketCounts.map((count, i) => ({
                percentage: bucketPercentages[i],
                label: bucketLabels[i],
                color: bucketColors[i],
            }));

            data.push({
                ...period,
                sections,
            });
        }
        return data;
    }


    return (
        <div style={{ ...style, height: '100%' }}
            ref={graphContainerRef}
        />
    )
}
