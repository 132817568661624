import { OperationFront } from '../../../shared/types/operation';
import { formatDate, formatDatetime } from '../../utils/fieldFormats';
import { HandledSrapsFilters } from './handledScrapsFilter';
import { ScrapTotals } from './useScrapTotal';


export function createExportTable(
    filters: HandledSrapsFilters,
    operations: OperationFront[],
    scrapTotals: ScrapTotals,
): Array<Array<string | number>> {
    if (filters == null || operations == null || scrapTotals == null) {
        throw new Error('Filters, operations, and scrap totals must be defined to create the table.');
    };

    const filtersSection = [
        ['Part number:', filters?.partDisplayName?.trim() ?? ''],
        ['Order number:', filters?.orderNumber?.trim() ?? ''],
        ['Start date:', filters?.startDate ? formatDate(filters.startDate) : ''],
        ['End date:', filters?.endDate ? formatDate(filters.endDate) : ''],
    ];

    const scrapTotalsSection = [
        ['Cases:', scrapTotals?.numCases ?? ''],
        ['Initial scraps:', scrapTotals?.initialScraps ?? ''],
        ['Potential scraps:', scrapTotals?.potentialScraps ?? ''],
        ['Confirmed scraps:', scrapTotals?.confirmedScraps ?? ''],
        ['Released scraps:', scrapTotals?.releasedScraps ?? ''],
    ];

    const operationsHeader = [
        'Equipment',
        'Part number',
        'Program',
        'Operation performed',
        'Operation comment',
        'Order number',
        'Initial scraps',
        'Potential scraps',
        'Confirmed scraps',
        'Part count',
        'Operation date',
        'Operation operator',
        'Scrap reason',
        'Close comment',
        'Close operator',
        'Close date',
        'Time spent',
    ];
    const operationsData = operations.map((operation) => {
        const scrapCloseForm = operation.scrapCloseForm!;
        const timeSpentHours = Math.floor((scrapCloseForm.timeSpent ?? 0) / 60_000 / 60);
        const timeSpentMinutes = Math.floor((scrapCloseForm.timeSpent ?? 0) / 60_000 % 60);
        const timeSpent = scrapCloseForm.timeSpent != null ? `${timeSpentHours}h ${timeSpentMinutes}m` : '';

        return [
            operation.equipmentDisplayName,
            operation.partDisplayName ?? '',
            operation.product ?? '',
            operation.details.join(' > '),
            operation.comment,
            operation.orderNumber ?? '',
            operation.discardCount ?? 0,
            operation.potentialDiscards ?? 0,
            scrapCloseForm.finalConfirmedScrap ?? 0,
            operation.partCount ?? 0,
            formatDatetime(operation.time),
            operation.operator ?? '',
            scrapCloseForm.scrapReason ?? '',
            scrapCloseForm.comment ?? '',
            scrapCloseForm.operator ?? '',
            formatDatetime(scrapCloseForm?.time),
            timeSpent,
        ];
    });

    const finalTable = [
        ['Filters'],
        ...filtersSection,
        [''],
        ['Scrap totals'],
        ...scrapTotalsSection,
        [''],
        ['Operations'],
        operationsHeader,
        ...operationsData,
    ]
    return finalTable;
}
