import { countSpcIssues } from '../../../shared/services/spc/countSpcIssues';
import { SpcTogglesMap } from '../../../shared/types/spc';
import { Protocol, RecordWithProtocol } from '../../types/sharedTypeImpl';


interface Props {
    protocol: Protocol | undefined;
    records: RecordWithProtocol[] | undefined;
    spcTogglesMap: SpcTogglesMap | undefined;
    numLatest: number | null;
}

export function useNumIssues({
    protocol,
    records,
    spcTogglesMap,
    numLatest,
}: Props) {

    if (protocol && records && spcTogglesMap) {
        return countSpcIssues(protocol, records, spcTogglesMap, numLatest ?? undefined);
    } else {
        return null;
    }
}
