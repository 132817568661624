import React from 'react';


interface Props {
    className?: string;
    style?: React.CSSProperties;
    value: number | null;
    onChange: (value: number | null) => void;
    min?: number;
    max?: number;
    step?: number;
    disabled?: boolean;
}

export function IntegerInput({
    className,
    style,
    value,
    onChange,
    min,
    max,
    step,
    disabled,
}: Props) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;
        if (input === '') {
            onChange(null);
            return;
        }

        // Only allow integers
        const parsed = parseInt(input, 10);
        if (!isNaN(parsed)) {
            onChange(parsed);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // Allow: navigation and control keys
        if (e.key === 'Backspace' ||
            e.key === 'Delete' ||
            e.key === 'Tab' ||
            e.key === 'Escape' ||
            e.key === 'Enter' ||
            e.key === 'ArrowLeft' ||
            e.key === 'ArrowRight' ||
            e.key === 'ArrowUp' ||
            e.key === 'ArrowDown' ||
            e.key === 'Home' ||
            e.key === 'End' ||
            (e.key === 'a' && (e.ctrlKey || e.metaKey)) ||
            (e.key === 'z' && (e.ctrlKey || e.metaKey)) ||
            (e.key === 'x' && (e.ctrlKey || e.metaKey)) ||
            (e.key === 'c' && (e.ctrlKey || e.metaKey)) ||
            (e.key === 'v' && (e.ctrlKey || e.metaKey))
        ) {
            return;
        }

        // Allow minus sign only at the start of input
        if (e.key === '-') {
            const input = e.currentTarget.value;
            const selectionStart = e.currentTarget.selectionStart;
            if (input === '' || (selectionStart === 0 && !input.includes('-'))) {
                return;
            }
        }

        // Block any key that isn't a number
        if (!/^[0-9]$/.test(e.key)) {
            e.preventDefault();
        }
    };

    return (
        <input
            className={className}
            style={style}
            type='number'
            value={value === null ? '' : value.toString()}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            min={min}
            max={max}
            disabled={disabled}
            step={step ?? 1}
        />
    );
}
