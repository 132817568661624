import { useEffect, useState } from 'react';


interface Props {
    dataId: string,
    label: string,
    defaultValue: string,
    suggestions: string[],
    onSubmit: (value: string) => void,
}

export default function ComboBox({
    dataId,
    label,
    defaultValue,
    suggestions,
    onSubmit,
}: Props) {
    const [value, setValue] = useState(defaultValue);
    const [isFocused, setIsFocused] = useState(false);


    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(value);
    }

    return (
        <form
            style={{ marginLeft: '12px' }}
            onSubmit={handleSubmit}
        >
            <span>{label}:</span>
            <input
                style={{ marginLeft: '6px' }}
                type='text'
                value={value}
                list={dataId}
                onChange={handleInput}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
            />
            {isFocused && (
                <datalist id={dataId}>
                    {suggestions.map(suggestion => (
                        <option key={suggestion} value={suggestion} />
                    ))}
                </datalist>
            )}
            <input type='submit' value='Submit' />
        </form>
    )
}
