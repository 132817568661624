import { Position } from '../../../shared/types/protocol';
import TriStateToggle from '../../components/triStateToggle';


interface VisualInspectionProps {
    index: number,
    position: Position,
    positionVal: boolean | undefined,
    onUpdatePositionValue: (index: number, value: boolean) => void,
    isActive: boolean,
    isViewMode: boolean,
    onPositionFocus: (index: number) => void,
}

export function VisualInspection({
    index,
    position,
    positionVal,
    onUpdatePositionValue,
    isActive,
    isViewMode,
    onPositionFocus,
}: VisualInspectionProps) {

    return (
        <tr
            className={`${isActive && 'highlight--selected'}`}
            onClick={() => onPositionFocus(index)}
        >
            <td>#<b>{`${position.positionNumber}`}</b></td>
            <td>
                <TriStateToggle
                    selection={positionVal}
                    onSelectionChange={selection => onUpdatePositionValue(index, selection)}
                    onFocus={() => onPositionFocus(index)}
                    disabled={isViewMode}
                    trueLabel='OK'
                    falseLabel='NOK'
                />
            </td>
            <td
                colSpan={4}
                title={position.description}
            >
                {position.description}
            </td>
        </tr>
    );
}
