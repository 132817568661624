import { spcCheckPosition } from '../../../shared/services/spc/spcCheckPosition';
import { Position } from '../../../shared/types/protocol';
import { SpcResultsFlags, SpcTogglesPos } from '../../../shared/types/spc';
import { NUM_SPC_CHECKS } from '../../../shared/utils/spcConstants';
import { ProtocolFinal, RecordDraft, RecordWithProtocol } from '../../types/sharedTypeImpl';
import { logError } from '../../utils/errorLogging';


export interface DataPoint {
    value: number | null,
    valueAsString: string,
    partCount: number | null,
    measurementStart: Date,
    isInRange: boolean,
    shouldDraw: boolean,
    shouldHighlight: boolean,
    checkResults: SpcResultsFlags,
}

type DataPointInput = Omit<DataPoint, 'shouldHighlight' | 'checkResults'>;

const SPC_CHECKS_TO_HIGHLIGHT: SpcTogglesPos = [
    true, true, true, true, true, true, true, false
]


export default function transformDataPoints(
    protocol: ProtocolFinal,
    prevRecords: RecordWithProtocol[] | undefined,
    currentRecord: RecordDraft | null,
    selectedPositionIdx: number,
    spcToggles?: SpcTogglesPos,
    maxDataPoints?: number | null,
): DataPoint[] {
    const position = protocol.positions[selectedPositionIdx];

    const inputData = [
        ...(prevRecords ? getPrevPoints(prevRecords, position) : []),
        ...(currentRecord ? getCurrentPoints(currentRecord!, selectedPositionIdx) : []),
    ]

    setIsInRange(inputData, maxDataPoints);
    const outputData = setSpcFlags(inputData, position, spcToggles);
    return outputData;
}

function getPrevPoints(prevRecords: RecordWithProtocol[], currentPosition: Position): DataPointInput[] {
    const dataPoints: DataPointInput[] = [];
    for (const record of prevRecords) {
        if (record.isDraft) continue;
        if (record.measurementType !== 'production') {
            logError(`Unexpected measurement type: ${record.measurementType}`);
        }

        const positionIndex = record.protocol.positions.findIndex(it => it.positionNumber === currentPosition.positionNumber);
        if (positionIndex === -1) continue;

        const position = record.protocol.positions[positionIndex];
        if (position.type === 'visualInspection') continue;
        if (position.nominal != currentPosition.nominal) continue;

        const positionValue = record.parts[0].positionValues[positionIndex];
        if (positionValue?.value == null) continue;

        const valueAsString = positionValue.value as string;
        const valueAsNumber = valueAsString !== '' ? +valueAsString : null;
        dataPoints.push({
            value: valueAsNumber,
            valueAsString,
            partCount: record.partCount ?? null,
            measurementStart: record.measurementStart!,
            isInRange: true,
            shouldDraw: valueAsNumber != null,
        });
    }
    return dataPoints;
}

function getCurrentPoints(currentRecord: RecordDraft, selectedPositionIdx: number): DataPointInput[] {
    const dataPoints: DataPointInput[] = [];
    for (const [index, part] of currentRecord.parts.entries()) {
        const valueAsString = (part.positionValues[selectedPositionIdx].value ?? '') as string;
        const valueAsNumber = valueAsString?.length > 0 ? Number.parseFloat(valueAsString) : null;
        const shouldDraw = valueAsNumber != null && !isNaN(valueAsNumber);
        const partCount = currentRecord.measurementType === 'production'
            ? currentRecord.partCount ?? null
            : index + 1;

        dataPoints.push({
            value: valueAsNumber,
            valueAsString,
            partCount,
            measurementStart: currentRecord.measurementStart!,
            isInRange: true,
            shouldDraw,
        });
    }
    return dataPoints;
}

function setIsInRange(dataEntries: DataPointInput[], maxDataPoints: number | null | undefined) {
    if (maxDataPoints == null) return;
    const minIndexInRange = dataEntries.length - maxDataPoints;
    for (let i = 0; i < minIndexInRange; i++) {
        dataEntries[i].isInRange = false;
    }
}

function setSpcFlags(
    dataEntries: DataPointInput[],
    position: Position,
    spcToggles?: SpcTogglesPos,
): DataPoint[] {
    const values = dataEntries.map(it => it.valueAsString);
    const finalChecks = spcToggles ?? [true, false, false, false, false, false, false, false];
    const positionResults = spcCheckPosition(values, position, finalChecks);

    const dataPoints: DataPoint[] = [];
    for (let i = 0; i < positionResults.length; i++) {
        dataPoints.push({
            ...dataEntries[i],
            shouldHighlight: positionResults[i].hasIssues && shouldhighlight(positionResults[i].checkResults),
            checkResults: positionResults[i].checkResults,
        });
    }
    return dataPoints;
}

function shouldhighlight(checkResults: SpcResultsFlags): boolean {
    if (checkResults.length !== NUM_SPC_CHECKS) throw new Error('Invalid check results array length');
    return checkResults.some((it, idx) => it && SPC_CHECKS_TO_HIGHLIGHT[idx]);
}
