import classNames from 'classnames';
import { Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import { Position } from '../../../shared/types/protocol';
import PositionSelection from '../../types/positionSelection';
import { ProtocolDraft } from '../../types/sharedTypeImpl';
import { normalizeFloat } from '../../utils/positionInputParse';
import Input from '../input';
import FormMarkerList from './formMarkerList';
import { useUpdateProtocol } from './useUpdateProtocol';


interface Props {
    index: number,
    protocol: ProtocolDraft,
    setProtocol: Dispatch<SetStateAction<ProtocolDraft | null>>,
    positionSelection: PositionSelection | null,
    setPositionSelection: (positionSelection: PositionSelection | null) => void,
    setIsDirty: (isDirty: boolean) => void
    isReadOnly: boolean;
    onMenuOpenClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
}

export function MeasurementPosition({
    index,
    protocol,
    setProtocol,
    positionSelection,
    setPositionSelection,
    setIsDirty,
    isReadOnly,
    onMenuOpenClick,
}: Props) {
    const position = protocol.positions[index];
    const isActive = index === positionSelection?.positionIdx;
    const isAuto = position.toolType === 'CMM' || position.toolType === 'VMM';
    const rowClass = classNames({
        'highlight--selected': isActive && !isAuto,
        'automatic': !isActive && isAuto,
        'automatic--selected': isActive && isAuto,
    });


    const {
        setPositionField,
        // setMarkerField,
    } = useUpdateProtocol(protocol, setProtocol, setPositionSelection, setIsDirty);


    function handleSelect() {
        setPositionSelection({ positionIdx: index });
    }

    function reformatOnBlur(e: React.FocusEvent<HTMLInputElement>, field: keyof Position) {
        const value = e.target.value;
        const newValue = normalizeFloat(value);
        setPositionField(index, field, newValue);
    }

    function handleSymbolChange(e: React.FocusEvent<HTMLInputElement>) {
        const value = e.target.value;
        if (value.includes('◆') || value.includes('♦')) {
            toast.error(`The diamond character is not allowed in the symbol field. Use 'Crit' checkbox instead.`);
            e.preventDefault();
            return;
        }
        setPositionField(index, 'symbol', value);
    }


    const checkBoxStyle: React.CSSProperties = {
        margin: '0 auto',//Remove horizontal margin to help with alignment
        pointerEvents: isReadOnly ? 'none' : 'auto'
    }

    return (
        <>
            <tr
                className={rowClass}
                onClick={handleSelect}
            >
                {/* {`page: ${position.page}, x: ${position.x.toFixed(0)}, y: ${position.y.toFixed(0)}`} <br /> */}
                <td>
                    <Input
                        type='number'
                        value={position.positionNumber}
                        onChange={(e) => { setPositionField(index, 'positionNumber', e.target.value) }}
                        onFocus={handleSelect}
                        readOnly={isReadOnly}
                        style={{ pointerEvents: isReadOnly ? 'none' : 'auto' }} //Let clicks pass through when readonly
                    />
                </td>
                <td>
                    <Input
                        type='number'
                        value={position.nominal ?? ''}
                        onChange={(e) => { setPositionField(index, 'nominal', e.target.value) }}
                        onFocus={handleSelect}
                        onBlur={(e) => reformatOnBlur(e, 'nominal')}
                        readOnly={isReadOnly}
                        style={{ pointerEvents: isReadOnly ? 'none' : 'auto' }} //Let clicks pass through when readonly
                    />
                </td>
                <td>
                    <input
                        value={position.symbol ?? ''}
                        onChange={handleSymbolChange}
                        onFocus={handleSelect}
                        readOnly={isReadOnly}
                        style={{ pointerEvents: isReadOnly ? 'none' : 'auto' }} //Let clicks pass through when readonly
                    />
                </td>
                <td>
                    <Input
                        type='number'
                        value={position.upperTolerance ?? ''}
                        onChange={(e) => { setPositionField(index, 'upperTolerance', e.target.value) }}
                        onFocus={handleSelect}
                        onBlur={(e) => reformatOnBlur(e, 'upperTolerance')}
                        readOnly={isReadOnly || position.useGeneralTolerance}
                        style={{ pointerEvents: isReadOnly ? 'none' : 'auto' }} //Let clicks pass through when readonly
                    />
                </td>
                <td>
                    <Input
                        type='number'
                        value={position.lowerTolerance ?? ''}
                        onChange={(e) => { setPositionField(index, 'lowerTolerance', e.target.value) }}
                        onFocus={handleSelect}
                        onBlur={(e) => reformatOnBlur(e, 'lowerTolerance')}
                        readOnly={isReadOnly || position.useGeneralTolerance}
                        style={{ pointerEvents: isReadOnly ? 'none' : 'auto' }} //Let clicks pass through when readonly
                    />
                </td>
                <td>
                    <input
                        type='checkbox'
                        name='isCritical'
                        checked={position.isCritical ?? false}
                        onChange={(e) => { setPositionField(index, 'isCritical', e.target.checked) }}
                        onFocus={handleSelect}
                        readOnly={isReadOnly}
                        style={checkBoxStyle} //Let clicks pass through when readonly
                    />
                </td>
                <td>
                    <input
                        type='checkbox'
                        name='useGeneralTolerance'
                        checked={position.useGeneralTolerance ?? false}
                        onChange={(e) => { setPositionField(index, 'useGeneralTolerance', e.target.checked) }}
                        onFocus={handleSelect}
                        readOnly={isReadOnly}
                        style={checkBoxStyle}
                    />
                </td>
                <td>
                    <input
                        type='checkbox'
                        name='isChamfer'
                        checked={position.isChamfer ?? false}
                        onChange={(e) => { setPositionField(index, 'isChamfer', e.target.checked) }}
                        onFocus={handleSelect}
                        readOnly={isReadOnly}
                        style={checkBoxStyle}
                    />
                </td>
                <td>
                    <select
                        name='toolType'
                        value={position.toolType ?? ''}
                        onChange={(e) => { setPositionField(index, 'toolType', e.target.value) }}
                        onFocus={handleSelect}
                        style={{ pointerEvents: isReadOnly ? 'none' : 'auto' }} //Let clicks pass through when readonly
                    >
                        {position.toolType == null && <option value='' />}
                        <option value='CAL'>CAL</option>
                        <option value='TG'>TG</option>
                        <option value='PG'>PG</option>
                        <option value='MM'>MM</option>
                        <option value='CMM'>CMM</option>
                        <option value='VMM'>VMM</option>
                        <option value='Other'>Other</option>
                    </select>
                </td>
                <td>
                    <button
                        className='td__menu-button'
                        onClick={onMenuOpenClick}
                        onFocus={handleSelect}>
                        &#8942;
                    </button>
                </td>
            </tr>

            {position.markers.length > 1 &&
                <FormMarkerList
                    position={position}
                    positionIdx={index}
                    positionSelection={positionSelection}
                    setPositionSelection={setPositionSelection}
                    onMenuOpenClick={onMenuOpenClick}
                />
            }
        </>
    );
}
