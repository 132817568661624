import classNames from 'classnames';
import { formatDatetime } from '../../utils/fieldFormats';
import TriStateToggle from '../triStateToggle';
import { PreviewTable } from './types';


interface Props {
    measurementType: 'production' | 'ioqc',
    previewTable: PreviewTable,
    onIsIncludedChange: (recordIdx: number, isSelected: boolean) => void,
}

export function RecordsExportTable({
    measurementType,
    previewTable,
    onIsIncludedChange,
}: Props) {

    const metadataRows = [
        { label: 'Nominal values', getValue: (index: number) => previewTable.nominalValues[index] },
        { label: 'Upper tolerances', getValue: (index: number) => previewTable.upperTolerances[index] },
        { label: 'Lower tolerances', getValue: (index: number) => previewTable.lowerTolerances[index] },
    ];


    const partCountColumnName = measurementType === 'production' ? 'Part count' : 'Part';
    const hasTimeColumn = measurementType === 'production';
    const hasCommentColumn = measurementType === 'production';

    return (
        <table className='record-report-table'>
            <thead>
                <tr>
                    <th>Is included</th>
                    <th>{partCountColumnName}</th>
                    {previewTable.positionNumbers.map((positionNumber, index) => (
                        <th key={index}>{positionNumber}</th>
                    ))}
                    <th>Gen.</th>
                    {hasTimeColumn && <th>Time</th>}
                    {hasCommentColumn && <th>Comment</th>}
                </tr>
            </thead>
            <tbody>
                {metadataRows.map((metadataRow) => (
                    <tr key={metadataRow.label} className='record-report-tr--meta-data'>
                        <td colSpan={2}>
                            {metadataRow.label}
                        </td>
                        {previewTable.positionNumbers.map((_, index) => (
                            <td key={index}>{metadataRow.getValue(index)}</td>
                        ))}
                        <td />
                        {hasTimeColumn && <td />}
                        {hasCommentColumn && <td />}
                    </tr>
                ))}

                {previewTable.recordRows.map((recordRow, index) => (
                    <tr key={index}>
                        <td>
                            <TriStateToggle
                                selection={recordRow.includeInReport}
                                onSelectionChange={(isSelected) => onIsIncludedChange(index, isSelected)}
                            />
                        </td>
                        <td className={classNames({ 'record-report-td--warning': recordRow.isPartNumberHighlighted })}>
                            {recordRow.partCount}
                        </td>
                        {recordRow.values.map((value, index) => (
                            <td
                                key={index}
                                className={classNames({ 'record-report-td--warning': value.isHighlighted })}
                            >
                                {value.value}
                            </td>
                        ))}
                        <td className={classNames({ 'record-report-td--warning': recordRow.isGeneralVisualInspectionHighlighted })}>
                            {recordRow.generalVisualInspection}
                        </td>
                        {hasTimeColumn &&
                            <td>{formatDatetime(recordRow.measurementStart)}</td>
                        }
                        {hasCommentColumn &&
                            <td className='record-report-td--comment'>{recordRow.comment}</td>
                        }
                    </tr>
                ))}
            </tbody>
        </table>
    );
}