import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { maxBy } from '../../shared/utils/arrayUtils';
import { fetchProtocols } from '../api/requests';
import { useFetchProtocolPdfOnce } from '../hooks/fetchHooks';
import { Protocol } from '../types/sharedTypeImpl';
import ModalDialog from './modalDialog';
import ProtocolPdf from './protocol-pdf/protocolPdf';


interface Props {
    protocol?: Protocol | undefined;
    product?: string | undefined;
    selectedPositionIdx: number | null;
    show: boolean;
    onClose: () => void;
}

export default function ProtocolPdfDialog({
    protocol,
    product,
    selectedPositionIdx,
    show,
    onClose
}: Props) {

    const protocolFetch = useQuery({
        queryKey: ['protocol', product, false, false],
        queryFn: () => fetchProtocols({ product, status: 'approved', isNotDisabled: true }),
        enabled: !!product,
        select: (protocols) => maxBy(protocols, p => +(p.createTime ?? 0)),
    });

    const protocolFinal = protocol ?? protocolFetch.data;

    const pdfFetch = useFetchProtocolPdfOnce(protocolFinal?._id);


    const positionSelection = useMemo(() => {
        return selectedPositionIdx != null
            ? { positionIdx: selectedPositionIdx }
            : null;
    }, [selectedPositionIdx]);

    return (
        <ModalDialog
            isOpen={show}
            frameStyle={{ width: '80%', height: '90%' }}
            contentStyle={{ height: '100%' }}
            cancelOnOutsideClick={true}
            onCancel={onClose}
        >
            <ProtocolPdf
                className='measurement-pdf-container'
                style={{ width: '100%', height: '70vh' }}
                isViewMode={true}
                positions={protocolFinal?.positions}
                positionSelection={positionSelection}
                showAllMarkers={false}
                pdfContent={pdfFetch.data ?? null}
                isPdfLoading={pdfFetch.isLoading}
            />
        </ModalDialog>
    );
}
