import { useEffect, useRef } from 'react';


interface Props {
    numParts: number;
    partIndex: number;
    setPartIndex: (index: number) => void;
}

export function usePartSelectHotkeys({
    numParts,
    partIndex,
    setPartIndex,
}: Props) {
    const listenerDivRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.ctrlKey && e.key === 'ArrowLeft') {
                e.preventDefault();
                prevPartRequested(partIndex, setPartIndex);
            } else if (e.ctrlKey && e.key === 'ArrowRight') {
                e.preventDefault();
                nextPartRequested(numParts, partIndex, setPartIndex);
            }
        };

        const currentForm = listenerDivRef.current;
        currentForm?.addEventListener('keydown', handleKeyDown);
        return () => {
            currentForm?.removeEventListener('keydown', handleKeyDown);
        };
    }, [numParts, partIndex, setPartIndex]);


    return listenerDivRef;
}

function prevPartRequested(partIndex: number, setPartIndex: (index: number) => void) {
    if (partIndex > 0) {
        setPartIndex(partIndex - 1);
    }
}

function nextPartRequested(numParts: number, partIndex: number, setPartIndex: (index: number) => void) {
    if (partIndex < numParts - 1) {
        setPartIndex(partIndex + 1);
    }
}
