import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useEffect } from 'react';


interface UseFetchOnceParams<T> extends UseQueryOptions<T> {
    queryKey: UseQueryOptions<T>['queryKey'];
    queryFn: () => Promise<T>;
    enabled?: boolean;
    onSuccess?: (data: T) => void;
}

export default function useFetchOnce<T>({
    queryKey,
    queryFn,
    enabled,
    onSuccess,
    ...queryOptions
}: UseFetchOnceParams<T>) {
    const { data, ...rest } = useQuery<T>({
        queryKey,
        queryFn,
        enabled,
        refetchOnWindowFocus: false,
        ...queryOptions,
    });

    useEffect(() => {
        if (data && onSuccess) {
            onSuccess(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return { data, ...rest };
}
