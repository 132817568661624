import { useEffect, useState } from 'react';
import ModalDialog from './modalDialog';


interface Props {
    className?: string,
    imageData: Uint8Array | File | null | undefined;
    isViewMode: boolean;
    onImageChange?: (imageFile: File) => void;
}

export default function VisualInspectionImage({
    className,
    imageData,
    isViewMode,
    onImageChange,
}: Props) {
    const [showingZoomedImage, setShowingZoomedImage] = useState(false);
    const [imageUrl, setImageUrl] = useState<string | null>(null);

    useEffect(() => {
        if (imageData) {
            const objectUrl = URL.createObjectURL(new Blob([imageData], { type: 'image/jpeg' }));
            setImageUrl(objectUrl);

            return () => {
                URL.revokeObjectURL(objectUrl);
            };
        } else {
            setImageUrl(null);
            return undefined;
        }
    }, [imageData]);


    function handleImageChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files[0]) {
            const selectedImage = e.target.files[0];
            onImageChange?.(selectedImage);
        }
    }


    return (
        <div
            className={className}
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                // backgroundColor: 'green',
                // minHeight: '0px',//Required to keep it from growing beyond the parent's bounds
                // minWidth: '0px',//Required to keep it from growing beyond the parent's bounds
                // objectFit: 'contain',
                // maxWidth: '100%',
                // maxHeight: '100%',
                // overflow: 'hidden'
            }}
        >
            {!isViewMode &&
                <input
                    type='file'
                    accept='image/*'
                    onChange={handleImageChange}
                />
            }
            {imageUrl &&
                <img
                    style={{
                        flex: '1 1',
                        objectFit: 'contain',
                        minHeight: '0px',//Required to keep it from growing beyond the parent's bounds
                    }}
                    src={imageUrl}
                    onClick={() => setShowingZoomedImage(true)}
                    alt='Visual inspection image'
                />}
            {imageUrl &&
                <ModalDialog
                    isOpen={showingZoomedImage}
                    cancelOnOutsideClick={true}
                    onCancel={() => setShowingZoomedImage(false)}
                >
                    <img
                        style={{
                            maxWidth: '60vw',
                            maxHeight: '75vh',
                        }}
                        src={imageUrl}
                        alt='Visual inspection image'
                    />
                </ModalDialog>
            }
        </div>
    );
}
