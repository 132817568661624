import { useState } from 'react';
import { Point, Size } from '../../../shared/types/mathTypes';
import { calcDistance } from '../../../shared/utils/mathUtils';
import useMouseEvents from './useMouseEvents';


export interface ArrowCoords {
    start: Point;
    end: Point;
}


interface Props {
    isEnabled: boolean;
    pdfSize: Size | null;
    arrowCoordsRef: React.MutableRefObject<ArrowCoords | null>;
    screenToPdfCoords: (screenCoords: Point) => Point;
    onStart?: () => void;
    onMinDistanceMoved?: () => void;
    onMove: () => void;
    onEnd: (start: Point, end: Point) => void;
    onCancel: () => void;
}

export default function useArrowCreate({
    isEnabled,
    pdfSize,
    arrowCoordsRef,
    screenToPdfCoords,
    onStart,
    onMinDistanceMoved,
    onMove,
    onEnd,
    onCancel,
}: Props) {
    const minDistance = 20;
    const [isDragging, setIsDragging] = useState(false);
    const [minDistanceMoved, setMinDistanceMoved] = useState(false);


    const onMouseDown = (e: MouseEvent) => {
        if (!isEnabled || !pdfSize) return;
        if (e.button !== 2) return; // Only continue if the button is mouse2

        const pdfCoords = screenToPdfCoords({ x: e.clientX, y: e.clientY });
        if (!isInPdfBounds(pdfCoords, pdfSize)) return;

        setIsDragging(true);
        setMinDistanceMoved(false);
        arrowCoordsRef.current = { start: pdfCoords, end: pdfCoords };
        onStart?.();
    }

    const onMouseMove = (e: MouseEvent) => {
        if (!isDragging || !pdfSize || !arrowCoordsRef.current) return;

        const pdfCoords = screenToPdfCoords({ x: e.clientX, y: e.clientY });
        if (!isInPdfBounds(pdfCoords, pdfSize)) return;

        arrowCoordsRef.current.end = pdfCoords;
        onMove();

        if (!minDistanceMoved) {
            const distance = calcDistance(arrowCoordsRef.current.start, arrowCoordsRef.current.end);
            if (distance > minDistance) {
                setMinDistanceMoved(true);
                onMinDistanceMoved?.();
            }
        }
    }

    const onMouseUp = (e: MouseEvent) => {
        if (!isDragging || !pdfSize || !arrowCoordsRef.current) return;
        if (e.button !== 2) return; // Only continue if the button is mouse2
        setIsDragging(false);

        const pdfCoords = screenToPdfCoords({ x: e.clientX, y: e.clientY });
        if (!isInPdfBounds(pdfCoords, pdfSize)) {
            arrowCoordsRef.current = null;
            onCancel();
            return;
        }

        const start = arrowCoordsRef.current.start;
        onEnd(start, pdfCoords);
    }

    useMouseEvents({
        onMouseDown,
        onMouseMove,
        onMouseUp,
    });

    const isInPdfBounds = (pdfCoords: Point, pdfSize: Size): boolean => {
        if (!pdfSize) return false;
        return pdfCoords.x >= 0 && pdfCoords.x <= pdfSize.width
            && pdfCoords.y >= 0 && pdfCoords.y <= pdfSize.height;
    }
}
