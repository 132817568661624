

export const SPC_ENABLED_DEFAULT = false;
export const NUM_SPC_CHECKS = 8 as const;
export const MIN_POINTS_FOR_SPC = 10;

export const SPC_DESCRIPTIONS = [
    'One point beyond the tolerance limit',
    'One point beyond the 3σ control limit',
    'Eight or more points on one side of the mean without crossing',
    'Four out of five points beyond 1σ',
    'Six points or more in a row steadily increasing or decreasing',
    'Two out of three points beyond 2σ',
    '14 points in a row alternating up and down',
    'Cpk is less than 1.0',
];
