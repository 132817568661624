import { Position } from '../../types/protocol.js';
import { SpcResultsFlags, SpcResultsMeasurement, SpcTogglesPos } from '../../types/spc.js';
import { getPositionValuesPrecision } from '../../utils/getPositionPrecision.js';
import { NUM_SPC_CHECKS } from '../../utils/spcConstants.js';
import { checkConsecutiveChange } from './checkConsecutiveChange.js';
import { checkOnOneSideOfMean } from './checkOnOneSideOfMean.js';
import { ControlValues, getControlValues } from './getControlValues.js';
import { getCpk } from './getCpk.js';


export function spcCheckPosition(
    values: Array<string>,
    position: Position,
    spcTogglesPos: SpcTogglesPos,
): SpcResultsMeasurement[] {
    if (position.type !== 'measurement') {
        throw new Error('SPC checks can only be performed on positions of type "measurement"');
    }

    const limits = getControlValues(values, position);
    if (!limits) {
        return values.map(value => ({
            value,
            checkResults: Array(NUM_SPC_CHECKS).fill(null) as SpcResultsFlags,
            hasIssues: false,
        }));
    }

    const numberValues = values.map(it => it !== '' ? parseFloat(it) : null);
    const precision = getPositionValuesPrecision(values);
    const checks = [
        spcTogglesPos[0] && values.length > 0 ? checkToleranceLimits(numberValues, limits) : Array(values.length).fill(null),
        spcTogglesPos[1] && values.length > 9 ? checkControlLimits(numberValues, limits) : Array(values.length).fill(null),
        spcTogglesPos[2] && values.length > 9 ? checkOnOneSideOfMean(numberValues, limits.mean, precision) : Array(values.length).fill(null),
        spcTogglesPos[3] && values.length > 9 ? checkBZoneLimit(numberValues, limits) : Array(values.length).fill(null),
        spcTogglesPos[4] && values.length > 9 ? checkConsecutiveChange(numberValues) : Array(values.length).fill(null),
        spcTogglesPos[5] && values.length > 9 ? checkAZoneLimit(numberValues, limits) : Array(values.length).fill(null),
        spcTogglesPos[6] && values.length > 9 ? checkAlternatingPattern(numberValues) : Array(values.length).fill(null),
        spcTogglesPos[7] && values.length > 9 ? checkCpk(numberValues, limits) : Array(values.length).fill(null),
    ];
    if (checks.length !== NUM_SPC_CHECKS) throw new Error('Invalid number of spc checks');

    const checksTransposed = transpose(checks) as SpcResultsFlags[];
    const result = values.map((value, i) => ({
        value,
        checkResults: checksTransposed[i],
        hasIssues: checksTransposed[i].some(it => it === true),
    }));
    return result;
}


//One point beyond the tolerance limit
function checkToleranceLimits(values: Array<number | null>, limits: ControlValues): Array<boolean | null> {
    const flags = values.map(it => {
        if (it === null) return null;
        return it < limits.tolerances[0] || it > limits.tolerances[1];
    });
    return flags;
}

//One point beyond the 3 σ control limit
function checkControlLimits(values: Array<number | null>, limits: ControlValues): Array<boolean | null> {
    const flags = values.map(it => {
        if (it === null) return null;
        return it < limits.sigma3[0] || it > limits.sigma3[1];
    });
    return flags;
}

//Four out of five points in zone B or beyond
function checkBZoneLimit(values: Array<number | null>, limits: ControlValues): Array<boolean | null> {
    const flags = flagSequenceByLimits(
        values,
        limits.sigma1, //zone B begins after zone C ends
        4,
        true
    );
    // if (flags) console.log('triggered: checkBZoneLimit');
    return flags;
}

//Two out of three points in zone A
function checkAZoneLimit(values: Array<number | null>, limits: ControlValues): Array<boolean | null> {
    const flags = flagSequenceByLimits(
        values,
        limits.sigma2, //zone A begins after zone B ends
        2,
        true
    );
    // if (flags) console.log('triggered: checkAZoneLimit');
    return flags;
}

//14 points in a row alternating up and down
function checkAlternatingPattern(values: Array<number | null>): Array<boolean | null> {
    const NUM_CONSECUTIVE = 14;
    let sequenceStart = 0;
    let countAlternating = 1;
    let lastDirectionUp: boolean | null = null; // true if up, false if down, null for the first comparison
    let prevValue = values[0];

    const flags = values.map(it => it == null ? null : false);
    for (let i = 1; i < values.length; i++) {
        if (values[i] === null) continue;
        if (prevValue === null) {
            prevValue = values[i];
            continue;
        }

        const delta = values[i]! - prevValue;
        if (delta === 0) {
            countAlternating = 0;
            lastDirectionUp = null;
            continue;
        }

        const currentChangeUp = delta > 0;
        if (lastDirectionUp !== null && currentChangeUp !== lastDirectionUp) {
            countAlternating++;
        } else {
            countAlternating = 2; // Reset to 2 to include the current and the previous point
            sequenceStart = i - 1;
        }

        lastDirectionUp = currentChangeUp;

        if (countAlternating >= NUM_CONSECUTIVE) {
            setSpcFlagsTrue(flags, sequenceStart, i + 1);
            // console.log('triggered: checkAlternatingPattern');
        }
    }
    return flags;
}

function checkCpk(values: Array<number | null>, controlValues: ControlValues): Array<boolean | null> {
    const cpk = getCpk(controlValues);
    return cpk < 1
        ? values.map(it => it === null ? null : true)
        : Array(values.length).fill(false);
}


function flagSequenceByLimits(
    values: Array<number | null>,
    limits: [number, number],
    requiredCount: number,
    allowOneSkip: boolean
): Array<boolean | null> {
    const flags = values.map(it => it === null ? null : false);

    for (let startIdx = 0; startIdx < values.length - requiredCount + 1; startIdx++) {

        let outsideCount = 0;
        let skipUsed = false;
        for (let currentIdx = startIdx; currentIdx < values.length; currentIdx++) {
            const currentValue = values[currentIdx];
            if (currentValue === null) continue;

            const isOutsideLimits = currentValue > limits[1] || currentValue < limits[0];
            if (isOutsideLimits) {
                outsideCount++;
                if (outsideCount >= requiredCount) {
                    setSpcFlagsTrue(flags, startIdx, currentIdx + 1);
                }
            } else if (allowOneSkip && !skipUsed && currentIdx != startIdx) {
                skipUsed = true;
            } else {
                break;
            }

        }
    }
    return flags;
}

// Set flags to true for all non-null values in the range
function setSpcFlagsTrue(
    flags: Array<boolean | null>,
    startIdx: number,
    endIdxExclusive: number
) {
    if (endIdxExclusive < startIdx) return;
    for (let i = startIdx; i < endIdxExclusive; i++) {
        if (flags[i] === null) continue;
        flags[i] = true;
    }
}

function transpose<T>(matrix: T[][]): T[][] {
    return matrix[0].map((_, colIndex) => matrix.map(row => row[colIndex]));
}


export const testExports = {
    checkToleranceLimits,
    checkControlLimits,
    checkBZoneLimit,
    checkConsecutiveChange,
    checkAZoneLimit,
    checkAlternatingPattern,
}
