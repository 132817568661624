import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Equipment } from '../../../shared/types/equipment';
import { fetchCustomEquipments, patchCustomEquipment } from '../../api/requests';
import Button from '../../components/button';
import CustomEquipmentDialog from './customEquipmentDialog';
import CustomEquipmentsTable from './customEquipmentsTable';

interface DialogState {
    isOpen: boolean,
    equipment?: Equipment,
}


export default function CustomEquipments() {

    const [customEquipmentDialog, setCustomEquipmentDialog] = useState<DialogState>({ isOpen: false });

    const equipmentsQuery = useQuery({
        queryKey: ['custom-equipments'],
        queryFn: async () => await fetchCustomEquipments(),
        select: (equipments) => [...equipments].sort((a, b) => a.displayName.localeCompare(b.displayName)),
    });
    const equipments = equipmentsQuery.data;


    const handleIsActiveChange = async (equipment: Equipment, isActive: boolean) => {
        await patchCustomEquipment({ ...equipment, isActive });
        equipmentsQuery.refetch();
    }


    return <>
        <div>
            <h2>Custom equipments</h2>
            <Button
                onClick={() => setCustomEquipmentDialog({ isOpen: true })}
                content='Add equipment'
            />

            {equipmentsQuery.isLoading && <div>Loading...</div>}
            {equipmentsQuery.error && <div>Error retrieving data</div>}
            {equipments &&
                <CustomEquipmentsTable
                    style={{ width: '800px' }}
                    equipments={equipments}
                    onEditClick={equipment => setCustomEquipmentDialog({ isOpen: true, equipment })}
                    onIsActiveChange={handleIsActiveChange}
                />
            }
        </div>

        {(customEquipmentDialog.isOpen && equipments) &&
            <CustomEquipmentDialog
                equipmentUnderEdit={customEquipmentDialog.equipment}
                equipments={equipments}
                onEquipmentSaved={() => equipmentsQuery.refetch()}
                onClose={() => { setCustomEquipmentDialog({ isOpen: false }) }}
            />
        }
    </>;
}
