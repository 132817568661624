import { CmmFileRead, CmmPositionRead } from './types';


export default async function readZeissCmmFile(xlsxFile: File): Promise<CmmFileRead> {
    try {
        //We're using node-xlsx since we need to handle xls and xlsx files. Other libraries
        // don't support xls or they are not maintained. In theory, node-xlsx is meant for
        // the back-end, but it seems to work in the front-end as well.
        const xlsx = await import('node-xlsx');

        const arrayBuffer = await xlsxFile.arrayBuffer();
        const document = xlsx.parse(arrayBuffer, { raw: true });
        const worksheet = document[0].data;

        const validation = worksheet[11][0] === 'Characteristic' && worksheet[11][1] === 'Actual';
        if (!validation) {
            return { success: false, errorMessage: 'Invalid file format' };
        }

        const positions: CmmPositionRead[] = [];
        worksheet.slice(12).forEach(row => {
            const positionNumber = row[0]?.match(/Pos (\d+(\.\d+)?)(_[XYZ])?/)?.[1];
            const valueAsFloat = Number.parseFloat(row[1]);
            if (!positionNumber || isNaN(valueAsFloat)) return;
            positions.push({ positionNumber, value: valueAsFloat.toFixed(3) });
        });

        return {
            success: true,
            valueSource: 'cmm',
            valueSourceSubType: 'zeiss',
            positions,
        }
    } catch (error) {
        console.error('Error reading file:', error);
        return { success: false, errorMessage: 'Error reading file' };
    }
}
