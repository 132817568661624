import { useState } from 'react';
import MeasurementGraphControl from '../../components/measurement-graph/measurementGraphControl';
import MeasurementGraphSettings from '../../components/measurement-graph/measurementGraphSettings';
import MeasurementsGraph from '../../components/measurement-graph/measurementsGraph';
import { PageTitle } from '../../components/pageTitle';
import ProtocolPdfDialog from '../../components/protocolPdfDialog';
import SigmaHistogram from '../../components/sigma-histogram/sigmaHistogram';
import Overview from './overview';
import PositionDetails from './positionDetails';
import RangeSelection from './rangeSelection';
import SpcTogglesTable from './spcTogglesTable';
import { useLoadRecords } from './useLoadRecords';
import { useNumIssues } from './useNumIssues';
import { RangeKey, useRangeOptions } from './useRangeOptions';
import useSpcToggles from './useSpcToggles';


export default function SpcDetails() {
    const searchParams = new URLSearchParams(window.location.search);
    const equipment = searchParams.get('equipment') as string;
    const product = searchParams.get('product') as string;

    const [activePositionIdx, setActivePositionIdx] = useState<number | null>(null);
    const [selectedRangeKey, setSelectedRangeKey] = useState<RangeKey>('orderNumber');
    const [showPdfDialog, setShowPdfDialog] = useState(false);
    const [measurementGraphSettings, setMeasurementGraphSettings] = useState<MeasurementGraphSettings>({
        lineVisibilities: { nominal: true, tolerance: true, mean: true, }
    });

    const {
        records,
        protocol,
        orderNumber,
    } = useLoadRecords({ equipment, product });

    const {
        rangeOptions,
        numLatestRecords,
    } = useRangeOptions({ orderNumber, records, selectedRangeKey });

    const { spcTogglesMap, handleSpcTogglesChange } = useSpcToggles({ protocol });
    const issueCount = useNumIssues({ protocol, records, spcTogglesMap, numLatest: numLatestRecords });


    return (
        <>
            <PageTitle title='Spc details' />
            <div className='measurement-container'>
                <div className='measurement-main-pane' style={{ padding: '12px' }} >

                    {protocol &&
                        <button style={{ float: 'right' }} onClick={() => setShowPdfDialog(true)}>
                            View pdf
                        </button>
                    }

                    {(orderNumber && protocol && records && activePositionIdx != null) && <>
                        <MeasurementGraphControl
                            settings={measurementGraphSettings}
                            setSettings={setMeasurementGraphSettings}
                        />

                        <MeasurementsGraph
                            style={{ width: '100%', height: '40%', marginTop: '12px' }}
                            protocol={protocol}
                            prevRecords={records}
                            currentRecord={null}
                            selectedPositionIdx={activePositionIdx}
                            openDialogOnClick={false}
                            settings={measurementGraphSettings}
                            spcTogglesMap={spcTogglesMap}
                            maxDataPoints={numLatestRecords}
                        />

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }} >
                            <SigmaHistogram
                                style={{ width: '600px', height: '300px', marginTop: '12px' }}
                                protocol={protocol}
                                records={records}
                                activePositionIdx={activePositionIdx}
                                numLatest={numLatestRecords}
                            />

                            <div style={{ width: '300px', display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '24px' }} >
                                <PositionDetails
                                    style={{ marginTop: '24px' }}
                                    protocol={protocol}
                                    records={records}
                                    activePositionIdx={activePositionIdx}
                                />

                                <RangeSelection
                                    style={{ marginTop: '24px' }}
                                    rangeOptions={rangeOptions}
                                    selectedRangeKey={selectedRangeKey}
                                    setSelectedRangeKey={setSelectedRangeKey}
                                />
                            </div>
                        </div>
                    </>}

                    {activePositionIdx == null && <div style={{ marginLeft: '40px', marginTop: '20px' }}>
                        Select a position to view details
                    </div>}
                </div>

                <div
                    className='measurement-side-pane'
                    style={{ overflowY: 'auto' }}
                >
                    <div style={{ marginTop: '20px', marginBottom: '20px', }}>
                        {(protocol && records) &&
                            <Overview
                                orderNumber={orderNumber}
                                protocol={protocol}
                                batchRecords={records}
                                enabledIssues={issueCount?.enabledIssues}
                                allIssues={issueCount?.allIssues}
                            />
                        }

                        {(protocol && records && spcTogglesMap) &&
                            <SpcTogglesTable
                                style={{ marginTop: '12px' }}
                                protocol={protocol}
                                records={records}
                                activePositionIdx={activePositionIdx}
                                setActivePositionIdx={setActivePositionIdx}
                                spcTogglesMap={spcTogglesMap}
                                onSpcTogglesChange={handleSpcTogglesChange}
                                numLatest={numLatestRecords}
                            />
                        }

                        <button
                            style={{ marginTop: '20px' }}
                            onClick={() => window.history.back()}
                        >
                            Close
                        </button>
                    </div>
                </div>

                <ProtocolPdfDialog
                    protocol={protocol}
                    selectedPositionIdx={activePositionIdx}
                    show={showPdfDialog}
                    onClose={() => setShowPdfDialog(false)}
                />
            </div>
        </>
    );
}
