import { ReportTypeKey, ReportTypes } from './reportTypes';


interface Props {
    selectedReportType: ReportTypeKey;
    onReportTypeSelected: (reportType: ReportTypeKey) => void;
}

export default function ReportTypeSelection({
    selectedReportType,
    onReportTypeSelected,
}: Props) {

    // radio buttons
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <div style={{ fontSize: '24px', marginLeft: '12px', marginBottom: '10px', fontWeight: 'bold' }}>
                Report type
            </div>

            {ReportTypes.map(({ key, label }) => (
                <label key={key} style={{ margin: '0 10px' }}>
                    <input
                        type='radio'
                        name='reportType'
                        value={key}
                        checked={selectedReportType === key}
                        onChange={() => onReportTypeSelected(key)}
                    />
                    {label}
                </label>
            ))}

        </div>
    );
}
