

export function convertToCsv(
    data: any[][],
    options?: {
        delimiter?: string,
        commaDecimals?: boolean,
    }
): string {
    return data.map(
        row => row.join(options?.delimiter ?? ',')
    ).map(
        row => options?.commaDecimals ? replacePeriodsOutsideQuotes(row) : row
    ).join('\n');
}

function replacePeriodsOutsideQuotes(input: string): string {
    let insideQuotes = false;
    let result = '';

    for (const char of input) {
        if (char === '"' || char === '\'') {
            insideQuotes = !insideQuotes;
            result += char;
        } else if (char === '.' && !insideQuotes) {
            result += ',';
        } else {
            result += char;
        }
    }

    return result;
}
