import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useState } from 'react';
import { sortByDesc } from '../../../shared/utils/arrayUtils';
import { fetchOperations } from '../../api/requests';
import { SelectedEquipment } from '../../pages/machine-log/types';
import { Operation, Session } from '../../types/sharedTypeImpl';
import { getOperationsWithAlarms } from '../../utils/toolChangeAlarms';
import { ClearFiltersButton } from '../clearFiltersButton';
import OperationDialog from '../operation-dialog/operationDialog';
import OperationsTable from './operationsTable';


interface Props {
    session: Session | null;
    selectedEquipment: SelectedEquipment | null;
    onOperationSaved: (operation: Operation) => void;
}

export default function FilterableOperationTable({
    session,
    selectedEquipment,
    onOperationSaved,
}: Props) {

    const [searchQuery, setSearchQuery] = useState('');
    const [isOperationDialogOpen, setIsOperationDialogOpen] = useState(false);
    const [operationUnderEdit, setOperationUnderEdit] = useState<Operation | null>(null);

    const [operationTypeButtons, setOperationTypeButtons] = useState([
        { name: 'Adjustments', opType: 'Adjustments', className: 'toggle-button--adjustments', enabled: true },
        { name: 'Offset', opType: 'Offset', className: 'toggle-button--offset', enabled: true },
        { name: 'Tool change', opType: 'Tool change', className: 'toggle-button--tool-change', enabled: true },
        { name: 'Program edit', opType: 'Program edit', className: 'toggle-button--program-edit', enabled: true },
        { name: 'Fix tool change', opType: 'Fix unscheduled tool change', className: 'toggle-button--fix-tool-change', enabled: true },
        { name: 'Measurement', opType: 'Measurement', className: 'toggle-button--measurement', enabled: false },
    ]);


    const { data: operations, isLoading, refetch } = useQuery({
        queryKey: ['operations', session?.equipment, session?.product, session?.start.toISOString()],
        queryFn: () => fetchOperations({ equipment: session!.equipment, product: session!.product, start: session!.start, end: session!.end }),
        enabled: session !== null,
        select: (operations) => sortByDesc(getOperationsWithAlarms(operations), it => it.time),
        refetchInterval: 5 * 60_000,
    });

    const filteredOperations = operations?.filter(operation =>
        operationTypeButtons.find(button => button.opType === operation.operationType)?.enabled
    )?.filter(operation => {
        const query = searchQuery.trim().toLowerCase();
        if (query === '') return true;
        return operation.comment?.toLowerCase().includes(query)
            || operation.operator?.toLowerCase().includes(query)
            || operation.details?.some(detail => detail?.toLowerCase().includes(query))
            || operation.collateralDamage?.some(tool => tool.toolNumber.toLowerCase().includes(query))
            || operation.collateralDamage?.some(tool => tool.toolVariant.toLowerCase().includes(query));
    });

    const handleClearFilters = () => {
        setSearchQuery('');
    }

    const onOperationTypeClick = (operationType: string) => {
        const updatedButtons = operationTypeButtons.map(it => it.name === operationType ? { ...it, enabled: !it.enabled } : it);
        setOperationTypeButtons(updatedButtons);
    }


    const addingOperationEnabled = session !== null && session.end == null && session.equipment === selectedEquipment?.id;

    if (session == null) return null;
    if (isLoading) return <p>Loading...</p>;
    return (
        <div style={{ marginTop: '16px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button
                    className='button--large'
                    disabled={!addingOperationEnabled}
                    onClick={() => setIsOperationDialogOpen(true)}
                >
                    Add operation
                </button>

                <div style={{ flexGrow: 1 }} />

                <input
                    id='operationFilterSearch'
                    type='text'
                    placeholder='Search'
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <ClearFiltersButton
                    style={{ marginRight: '16px' }}
                    onClick={() => handleClearFilters()}
                />

                {operationTypeButtons.map(button => (
                    <button
                        key={button.name}
                        className={classNames('toggle-button', button.className, { 'toggle-button--active': button.enabled })}
                        onClick={() => onOperationTypeClick(button.name)}
                    >
                        {button.name}
                    </button>
                ))}
            </div>

            {session && <OperationsTable
                narrow={true}
                operations={filteredOperations ?? []}
                showSession={false}
                operationTypeIndicators={true}
                highlightPotentialDiscards={true}
                showScrapLabels={false}
                enableLabelRelease={false}
                showOrderNumbers={false}
                isEditableIfScrapClosed={false}
                enableScrapClose={false}
                showScrapCloseValues={false}
                onEditClick={(operation) => { setOperationUnderEdit(operation); setIsOperationDialogOpen(true) }}
            />}

            <OperationDialog
                isOpen={isOperationDialogOpen}
                session={session}
                otherOperations={operations}
                operationUnderEdit={operationUnderEdit}
                onClose={() => { setIsOperationDialogOpen(false); setOperationUnderEdit(null) }}
                onOperationSaved={(operation) => { refetch(); onOperationSaved(operation) }}
            />
        </div >
    );
}
