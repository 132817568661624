import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebouncedState } from './useDebouncedState';


export function useSearchParamsSearch() {
    const [searchParams, setSearchParams] = useSearchParams();
    const searchTextInUrl = searchParams.get('search') ?? '';

    const {
        runningValue: searchTextInput,
        debouncedValue: searchText,
        setValueDebounced: setSearchTextDebounced,
        setValueImmediate: setSearchTextImmediate,
    } = useDebouncedState<string>(searchTextInUrl, 200, handleSearchTextChanged);

    useEffect(() => {
        setSearchTextImmediate(searchTextInUrl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTextInUrl]);

    function handleSearchTextChanged(text: string) {
        setSearchParams(prevParams => {
            const newParams = new URLSearchParams(prevParams);

            if (text) {
                newParams.set('search', text);
            } else {
                newParams.delete('search');
            }

            return newParams;
        }, { replace: true });
    }

    return {
        searchTextInput,
        searchText,
        setSearchTextDebounced,
        setSearchTextImmediate,
    }
}
