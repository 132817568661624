import { useState } from 'react';
import { useFetchPositionImages, useFetchProtocolOnce, useFetchProtocolPdfOnce } from '../../hooks/fetchHooks';
import { Protocol, ProtocolFinal, RecordDraft } from '../../types/sharedTypeImpl';


export default function useLoadProtocol(
    record: RecordDraft | null,
) {
    const [protocol, setProtocol] = useState<ProtocolFinal>();
    const [pdfContent, setPdfContent] = useState<Uint8Array | null>(null);


    const protocolFetch = useFetchProtocolOnce(record?.protocolId, (protocol: Protocol) => {
        setProtocol(protocol as ProtocolFinal);
    });

    const pdfFetch = useFetchProtocolPdfOnce(record?.protocolId, (protocolPdf: Uint8Array) => {
        setPdfContent(protocolPdf);
    });

    const inspectionQueries = useFetchPositionImages(protocolFetch.data);
    const positionImages = inspectionQueries.map(it => it.data);


    return {
        protocol,
        protocolFetch,
        pdfContent,
        pdfFetch,
        positionImages,
    }
}
