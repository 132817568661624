import { skipToken } from '@tanstack/react-query';
import { useEffect } from 'react';
import { RecordDraft, RecordFinal } from '../../types/sharedTypeImpl';
import { recordToDraft } from '../../utils/conversion';
import { trpc } from '../../utils/trpc';


export default function useSetupInitialRecord(
    initialRecordId: string | null,
    record: RecordDraft | null,
    setRecord: (record: RecordDraft) => void,
    setIsSavedRecord: (isSavedRecord: boolean) => void,
) {
    const recordFetch = trpc.records.getById.useQuery(
        initialRecordId ?? skipToken,
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: false,
        }
    );

    useEffect(() => {
        if (recordFetch.data && record?._id !== recordFetch.data._id) {
            if (recordFetch.data.isDraft) {
                setIsSavedRecord(false);
                setRecord(recordFetch.data as RecordDraft);
            } else {
                setIsSavedRecord(true);
                setRecord(recordToDraft(recordFetch.data as RecordFinal));
            }
        }
    }, [recordFetch.data, record, setIsSavedRecord, setRecord]);

    return recordFetch;
}
