import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClearFiltersButton } from '../../components/clearFiltersButton';
import NavBar from '../../components/navBar';
import { PageTitle } from '../../components/pageTitle';
import RecordsExportDialog from '../../components/records-export/recordsExportDialog';
import { useSearchParamsSearch } from '../../hooks/useSearchParamsSearch';
import { Record } from '../../types/sharedTypeImpl';
import { trpc, trpcClient } from '../../utils/trpc';
import { IoqcListTable } from './ioqcListTable';
import ProtocolSelectDialog from './protocolSelectDialog';


export function IoqcList() {

    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [pendingExportRecord, setPendingExportRecord] = useState<Record | null>(null);

    const { searchTextInput, searchText, setSearchTextDebounced, setSearchTextImmediate } = useSearchParamsSearch();


    const navigate = useNavigate();

    const { data: records, error, isLoading, refetch } = trpc.records.getRecords.useQuery({
        measurementType: 'ioqc',
        search: searchText,
    }, {
        placeholderData: (prev) => prev,//Prevents flickering when editing filters
    });


    const handleRecordDelete = async (record: Record) => {
        const description = `${record.equipmentDisplayName} - ${record.protocolPartDisplayName}`;
        if (window.confirm(`Are you sure you want to delete the following record?\n${description}`)) {
            try {
                await trpcClient.records.deleteRecord.mutate({ recordId: record._id });
                refetch();
            } catch (error) {
                alert('Failed to delete record');
            }
        }
    }


    return <>
        <PageTitle title='IOQC' />
        <NavBar />
        <div className='content' style={{ width: '1480px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <button onClick={() => setShowCreateDialog(true)}>Create measurement</button>

                <div className='flex__spacer' />

                <input
                    style={{ marginLeft: '18px' }}
                    type='text'
                    placeholder='Search'
                    value={searchTextInput}
                    onChange={e => setSearchTextDebounced(e.target.value)}
                />
                <ClearFiltersButton onClick={() => setSearchTextImmediate('')} />
            </div >

            <IoqcListTable
                isLoading={isLoading}
                error={error}
                records={records}
                onRecordDelete={handleRecordDelete}
                onRecordExport={(record) => setPendingExportRecord(record)}
                onSearchTextChange={setSearchTextImmediate}
            />
        </div >

        <ProtocolSelectDialog
            isOpen={showCreateDialog}
            onRecordCreated={(record) => navigate(`/record-view?id=${record._id}&mode=edit`)}
            onClose={() => setShowCreateDialog(false)}
        />

        {pendingExportRecord && <RecordsExportDialog
            record={pendingExportRecord}
            onClose={() => setPendingExportRecord(null)}
        />}
    </>
}
