import { useState } from 'react';
import { Equipment } from '../../../shared/types/equipment';
import { patchCustomEquipment, postCustomEquipment } from '../../api/requests';
import ModalSubmitDialog from '../../components/modalSubmitDialog';


interface Props {
    equipmentUnderEdit?: Equipment;
    equipments: Equipment[];
    onEquipmentSaved: (equipment: Equipment) => void;
    onClose: () => void;
}

export default function CustomEquipmentDialog({
    equipmentUnderEdit,
    equipments,
    onEquipmentSaved,
    onClose,
}: Props) {

    const [equipment, setEquipment] = useState<Equipment>(
        equipmentUnderEdit ?? { id: '', displayName: '', source: 'custom', isActive: true }
    );


    function handleKeyDown(e: React.KeyboardEvent) {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    }

    async function handleSubmit() {
        const isNew = !equipmentUnderEdit;
        if (isNew && equipments.some(it => it.id.toUpperCase() === equipment.id.toUpperCase())) {
            alert('Id must be unique');
            return;
        }

        const success = isNew
            ? await postCustomEquipment(equipment)
            : await patchCustomEquipment(equipment);
        if (success) {
            onEquipmentSaved(equipment);
            onClose();
        } else {
            alert('Failed to save equipment');
        }
    }

    return (
        <ModalSubmitDialog
            frameStyle={{ width: '360px' }}
            isOpen={true}
            onCancel={onClose}
            onSubmit={handleSubmit}
        >
            <div>
                <div>
                    <div>Id (cannot be modified)</div>
                    <input
                        className='dialog-form__input'
                        type='text'
                        value={equipment.id}
                        onChange={e => setEquipment({ ...equipment, id: e.target.value })}
                        onKeyDown={handleKeyDown}
                        disabled={!!equipmentUnderEdit}
                    />
                </div>
                <div className='dialog-form__field--with-margins' >
                    <div>Display name</div>
                    <input
                        className='dialog-form__input'
                        type='text'
                        value={equipment.displayName}
                        onChange={e => setEquipment({ ...equipment, displayName: e.target.value })}
                        onKeyDown={handleKeyDown}
                    />
                </div>
            </div>
        </ModalSubmitDialog>
    );
}
