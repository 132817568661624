import { useQuery } from '@tanstack/react-query';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { MaintenanceOperationTypeLabels } from '../../../shared/types/maintenanceOperation';
import { sortByDesc } from '../../../shared/utils/arrayUtils';
import { fetchMaintenceOperations } from '../../api/requests';
import LinkButton from '../../components/linkButton';
import { MaintenanceOperation } from '../../types/sharedTypeImpl';
import { formatDatetime } from '../../utils/fieldFormats';
import MaintenanceOperationDialog from './maintenanceOperationDialog';

interface DialogState {
    operationUnderEdit: MaintenanceOperation | null;
}


export interface MaintenanceLogTableRef {
    onAddOperationClicked: () => void;
}

interface Props {
    equipment: string,
    style?: React.CSSProperties,
}

const MaintenanceLogTable = forwardRef<MaintenanceLogTableRef, Props>(({
    equipment,
    style,
}, ref) => {
    const [dialogState, setDialogState] = useState<DialogState | null>(null);//If not null, then dialog is open

    const { data: maintenanceOperations, isLoading, error, refetch } = useQuery({
        queryKey: ['maintenance', equipment],
        queryFn: async () => fetchMaintenceOperations(equipment),
        select: maintenanceOperations => sortByDesc([...maintenanceOperations], it => it.time),
    });


    useImperativeHandle(ref, () => ({
        onAddOperationClicked: () => setDialogState({ operationUnderEdit: null }),
    }));


    return <>
        {isLoading && <p>Loading...</p>}
        {error && <p>Error retrieving data</p>}
        {maintenanceOperations &&
            <table style={style}>
                <thead>
                    <tr>
                        <th>Operation type</th>
                        <th>Comment</th>
                        <th>Date / time</th>
                        <th>Operator</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {maintenanceOperations.map(operation => (
                        <tr key={operation._id}>
                            <td>{
                                operation.operationType
                                    ? MaintenanceOperationTypeLabels[operation.operationType]
                                    : ''
                            }</td>
                            <td>{operation.comment}</td>
                            <td>{formatDatetime(operation.time)}</td>
                            <td>{operation.operator?.toUpperCase()}</td>
                            <td>
                                <LinkButton
                                    onClick={() => setDialogState({ operationUnderEdit: operation })}
                                    content='Edit'
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        }

        {dialogState &&
            <MaintenanceOperationDialog
                equipment={equipment}
                operationUnderEdit={dialogState.operationUnderEdit ?? undefined}
                onOperationSaved={refetch}
                onClose={() => setDialogState(null)}
            />
        }
    </>
});
export default MaintenanceLogTable;
