import { getMeasurementDelayBucketColor, getMeasurementDelayBucketLabel, NUM_MEASUREMENT_DELAY_BUCKETS } from '../../../shared/services/measurementDelayBuckets';


export function DelaysGraphLegend() {

    const entries = Array.from({ length: NUM_MEASUREMENT_DELAY_BUCKETS }, (_, i) => ({
        color: getMeasurementDelayBucketColor(i),
        label: getMeasurementDelayBucketLabel(i),
    }));

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', justifyContent: 'center', height: '100%' }}>
            {entries.map(({ color, label }) => (
                <div key={label} style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: '20px', height: '20px', backgroundColor: color, marginRight: '10px' }} />
                    <span style={{ fontSize: '24px' }}>{label}</span>
                </div>
            ))}
        </div>
    );
}
