

interface Props extends React.LabelHTMLAttributes<HTMLLabelElement> {
    borderBottomColor: string;
    borderBottomDashArray?: string;
}

export default function LabelWithBorder({
    borderBottomColor,
    borderBottomDashArray = 'none', // Default to no dashes (solid line)
    children,
    style,
    ...props
}: Props) {
    return (
        <label
            {...props}
            style={{
                ...style,
                position: 'relative',
                display: 'inline-block',
            }}
        >
            {children}

            <svg
                style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: '2px',
                }}
                xmlns="http://www.w3.org/2000/svg"
            >
                <line
                    x1="0"
                    y1="1"
                    x2="100%"
                    y2="1"
                    stroke={borderBottomColor}
                    strokeWidth="2"
                    strokeDasharray={borderBottomDashArray}
                />
            </svg>
        </label>
    );
}

