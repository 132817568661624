import { forwardRef } from 'react';


type Props = React.InputHTMLAttributes<HTMLInputElement>;

export const Input = forwardRef<HTMLInputElement, Props>((
    props,
    ref
) => {

    const preventChangeOnScroll = (event: React.WheelEvent<HTMLInputElement>) => {
        if (event.currentTarget.type === 'number') {
            event.currentTarget.blur(); // Keep values from being changed, but allow scrolling
        }
        if (props.onWheel) {
            props.onWheel(event);
        }
    };

    const preventChangeOnArrowKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.currentTarget.type === 'number' && (event.key === 'ArrowUp' || event.key === 'ArrowDown')) {
            event.preventDefault();
        }
    };

    return (
        <input
            {...props}
            ref={ref}
            onWheel={preventChangeOnScroll}
            onKeyDown={preventChangeOnArrowKey}
        />
    );
});
export default Input;
