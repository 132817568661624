import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '../../components/pageTitle';
import ProtocolForm from '../../components/protocol-form/protocolForm';
import { useUpdateProtocol } from '../../components/protocol-form/useUpdateProtocol';
import ProtocolPdf from '../../components/protocol-pdf/protocolPdf';
import VisualInspectionImage from '../../components/visualInspectionImage';
import useConfirmOnLeave from '../../hooks/useConfirmOnLeave';
import PositionSelection from '../../types/positionSelection';
import { ProtocolDraft } from '../../types/sharedTypeImpl';
import useProtocolSave from './useProtocolSave';
import useProtocolInit from './useProtocolnit';


export default function ProtocolView() {

    const [protocol, setProtocol] = useState<ProtocolDraft | null>(null);
    const [pdfData, setPdfData] = useState<Uint8Array | null>(null);
    const [positionSelection, setPositionSelection] = useState<PositionSelection | null>(null);
    const [showAllMarkers, setShowAllMarkers] = useState(false);
    const [showAllPosNrs, setShowAllPosNrs] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const isFinalSavedRef = useRef(false);
    const navigate = useNavigate();


    const {
        isProtocolLoading,
        isPdfLoading,
        protocolError,
        mode,
        isViewMode,
        savedPositionImages,
    } = useProtocolInit(setProtocol, setPdfData);

    const {
        addPosition,
        addMarker,
        setPositionImage,
        setMarkerLocation,
    } = useUpdateProtocol(protocol, setProtocol, setPositionSelection, setIsDirty);

    const {
        isSaving,
        handleDraftSave,
        handleFinalSave,
        handleReviewSave,
    } = useProtocolSave({
        protocol,
        setProtocol,
        pdfData,
        isDirty,
        setIsDirty,
        isViewMode,
        isFinalSavedRef,
        onFinalSaved: () => handleClose(),
        onReviewSaved: () => handleClose(),
    });

    useConfirmOnLeave(() => isDirty && !isFinalSavedRef.current, !isViewMode);


    function handleClose() {
        navigate('/protocol-list');
    }


    const activePosition = positionSelection ? protocol?.positions[positionSelection.positionIdx] : null;
    const shouldShowImagePane = activePosition?.type === 'visualInspection';
    const positionImage = shouldShowImagePane ? (activePosition?.imageFile ?? savedPositionImages?.[positionSelection!.positionIdx]) : null;

    if (isProtocolLoading) return <div>Loading...</div>;
    if (protocolError) return <div>An error has occurred: {protocolError.message}</div>;
    return (
        <>
            <PageTitle title='Protocol' />
            <div className='measurement-container'>
                <ProtocolPdf
                    className='measurement-main-pane measurement-pdf-container'
                    isViewMode={isViewMode}
                    positions={protocol?.positions}
                    positionSelection={positionSelection}
                    showAllMarkers={showAllMarkers}
                    showAllPosNrs={showAllPosNrs}
                    pdfContent={pdfData}
                    isPdfLoading={isPdfLoading}
                    onAddPosition={addPosition}
                    onUpdateLocation={setMarkerLocation}
                    onAddMarker={addMarker}
                />

                <div className='measurement-side-pane measurement-form-pane'>
                    {protocol && <ProtocolForm
                        className='measurement-form-top'
                        mode={mode}
                        protocol={protocol}
                        setProtocol={setProtocol}
                        pdfData={pdfData}
                        setPdfData={setPdfData}
                        positionSelection={positionSelection}
                        setPositionSelection={setPositionSelection}
                        showAllMarkers={showAllMarkers}
                        setShowAllMarkers={setShowAllMarkers}
                        showAllPosNrs={showAllPosNrs}
                        setShowAllPosNrs={setShowAllPosNrs}
                        isDirty={isDirty}
                        setIsDirty={setIsDirty}
                        isSaving={isSaving}
                        onDraftSave={handleDraftSave}
                        onFinalSave={handleFinalSave}
                        onReviewSave={handleReviewSave}
                        onCloseClicked={handleClose}
                    />}

                    {shouldShowImagePane && <VisualInspectionImage
                        className='measurement-form-bottom'
                        imageData={positionImage}
                        isViewMode={isViewMode}
                        onImageChange={(file) => setPositionImage(positionSelection!.positionIdx, file)}
                    />}
                </div>
            </div>
        </>
    );
}
