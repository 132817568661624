import { useState } from 'react';
import { saveFileWithPicker } from '../../utils/saveFile';
import { tableToXlsx } from '../../utils/tableToXlsx';
import ModalDialog from '../modalDialog';
import OperationsTable from '../operation-table/operationsTable';
import { createExportTable } from './createExportTable';
import { HandledScrapsFilter, HandledSrapsFilters } from './handledScrapsFilter';
import { useFetchOperations } from './useFetchOperations';
import { useScrapTotal } from './useScrapTotal';


interface Props {
    onClose: () => void;
}

export function HandledScrapsDialog({
    onClose,
}: Props) {
    const [handledScrapsFilters, setHandledScrapsFilters] = useState<HandledSrapsFilters | null>(null);

    const someFiltersSet =
        !!handledScrapsFilters?.partDisplayName?.trim() ||
        !!handledScrapsFilters?.orderNumber?.trim() ||
        !!handledScrapsFilters?.startDate ||
        !!handledScrapsFilters?.endDate;

    const operations = useFetchOperations(handledScrapsFilters, someFiltersSet);
    const scrapTotals = useScrapTotal(operations);

    async function handleExportXlsx() {
        if (handledScrapsFilters == null || operations == null || scrapTotals == null) return;

        const table = createExportTable(handledScrapsFilters!, operations!, scrapTotals!);
        const xlsx = await tableToXlsx(table);
        const result = await saveFileWithPicker('handledScraps.xlsx', xlsx);
        if (result.isSuccess) {
            onClose();
        }
    }


    const isTableVisible = someFiltersSet && operations;

    return (
        <ModalDialog isOpen={true} onCancel={onClose}>
            <HandledScrapsFilter
                filters={handledScrapsFilters}
                setFilters={setHandledScrapsFilters}
            />

            <h3>Totals</h3>
            <div style={{ display: 'flex', flexDirection: 'column', 'gap': '4px' }}>
                <div>Cases: {scrapTotals?.numCases ?? 0}</div>
                <div>Initial scraps: {scrapTotals?.initialScraps ?? 0}</div>
                <div>Potential scraps: {scrapTotals?.potentialScraps ?? 0}</div>
                <div>Released scraps: {scrapTotals?.releasedScraps ?? 0}</div>
                <div>Confirmed scraps: {scrapTotals?.confirmedScraps ?? 0}</div>
            </div>

            <h3>Operations</h3>
            {!someFiltersSet && <div style={{ marginTop: '12px' }}>No filters set</div>}
            <OperationsTable
                style={{ marginTop: '12px', visibility: isTableVisible ? 'visible' : 'hidden' }}
                narrow={false}
                operations={isTableVisible ? operations : []}
                showSession={true}
                operationTypeIndicators={false}
                highlightPotentialDiscards={false}
                showScrapLabels={false}
                enableLabelRelease={false}
                showOrderNumbers={true}
                isEditableIfScrapClosed={false}
                enableScrapClose={false}
                showScrapCloseValues={true}
                showEditColumn={false}
            />

            <div className='dialog--button-bar'>
                <button onClick={onClose}>Close</button>
                <button onClick={handleExportXlsx}>Xlsx</button>
            </div>
        </ModalDialog>
    )
}
