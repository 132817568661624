import { RecordPosition } from '../../shared/types/record';


export function getValueSourceString(positionVal: RecordPosition): string {
    const valueSourceString =
        positionVal.source === 'cmm' && positionVal.sourceSubType === 'zeiss' ? 'Z OI-322'
            : positionVal.source === 'cmm' && positionVal.sourceSubType === 'mitutoyo' ? 'M V574'
                : positionVal.source === 'vmm' && positionVal.sourceSubType === 'keyence' ? 'K 7030'
                    : '';
    return valueSourceString;
}