import { useState } from 'react';
import { toast } from 'react-toastify';
import { CmmReportHeader } from '../../../shared/types/cmmReport';
import { archiveCmmReports, fetchCmmReportFile } from '../../api/requests';
import { CMM_FILE_EXTENSIONS, importCmmFiles } from '../../services/cmm-import/importCmmFiles';
import { CmmFileImport, ImportValue } from '../../services/cmm-import/types';
import { ProtocolFinal, RecordDraft } from '../../types/sharedTypeImpl';
import { countRecordValuesFilled } from '../../utils/countRecordValuesFilled';
import shouldArchiveCmmReports from '../../utils/shouldArchiveCmmReports';
import Button from '../button';
import FileInputButton from '../fileInputButton';
import CmmFilesDialog from './cmmFilesDialog';
import CmmPositionsDialog from './cmmPositionsDialog';
import useCmmImport from './useCmmImport';


interface Props {
    style?: React.CSSProperties;
    protocol: ProtocolFinal;
    record: RecordDraft;
    partIndex: number;
    setRecord: (record: RecordDraft) => void;
    setIsDirty: (isDirty: boolean) => void;
}

export default function CmmImportButtons({
    style,
    protocol,
    record,
    partIndex,
    setRecord,
    setIsDirty,
}: Props) {
    const [filesDialogState, setFilesDialogState] = useState<CmmReportHeader[] | null>(null);
    const [positionsDialogState, setPositionsDialogState] = useState<CmmFileImport[] | null>(null);

    const {
        onAutoImportClick,
        onManualImportClick,
        isAutoImportClicked,
        isManualImportClicked,
    } = useCmmImport({
        protocol,
        record,
        partIndex,
        setFilesDialogState,
        setPositionsDialogState,
        applyImports,
    });


    const handleLocalFilesSelected = async (files: File[]) => {
        const importResults = await importCmmFiles(protocol, record, partIndex, files, 'local');
        setPositionsDialogState(importResults);
    };

    const handleFilesDialogSubmit = async (names: string[]) => {
        try {
            const files = await Promise.all(names.map(fetchCmmReportFile));
            const importResults = await importCmmFiles(protocol, record, partIndex, files, 'sharepoint');
            setPositionsDialogState(importResults);
            setFilesDialogState(null);
        } catch (error) {
            alert('Failed to fetch CMM reports');
        }
    };

    function applyImports(importValues: ImportValue[], sharePointFiles: string[], showToast: boolean = false) {
        setImportedValues(importValues);
        archiveImportedFiles(sharePointFiles);
        setPositionsDialogState(null);
        if (showToast) {
            toast.success('CMM report imported successfully');
        }
    }

    const setImportedValues = (importValues: ImportValue[]) => {
        const newRecord = structuredClone(record);
        for (const importValue of importValues) {
            const positionIdx = protocol.positions.findIndex(it => it.positionNumber === importValue.positionNumber);
            newRecord.parts[partIndex].positionValues[positionIdx] = {
                value: importValue.value,
                source: importValue.source,
                sourceSubType: importValue.sourceSubType,
            };
        }
        newRecord.numValuesFilled = countRecordValuesFilled(newRecord);

        setRecord(newRecord);
        setIsDirty(true);
    };

    const archiveImportedFiles = (sharePointFiles: string[]) => {
        if (sharePointFiles == null || sharePointFiles.length === 0) return;
        if (!shouldArchiveCmmReports()) {
            console.log('Skipped archiving CMM reports: ', sharePointFiles);
            return;
        }

        try {
            archiveCmmReports(sharePointFiles);
        } catch (error) {
            alert('Failed to archive some imported files');
        }
    };


    const isMintMeasurement = record.measurementType === 'production';

    return (
        <>
            <div className='cmm-import-button-bar' style={{ ...style }}>
                {isMintMeasurement &&
                    <Button
                        type='button'
                        onClick={onAutoImportClick}
                        disabled={isAutoImportClicked}
                        content='Auto-import'
                    />
                }
                <Button
                    type='button'
                    onClick={onManualImportClick}
                    disabled={isManualImportClicked}
                    content='Manual import'
                />
                <FileInputButton
                    allowedExtensions={CMM_FILE_EXTENSIONS}
                    multiple
                    onFilesSelected={handleLocalFilesSelected}
                    content='Local file import'
                />
            </div>

            {filesDialogState && (
                <CmmFilesDialog
                    isOpen={true}
                    cmmReports={filesDialogState}
                    initialFilter={protocol.partName}
                    onCancel={() => setFilesDialogState(null)}
                    onSubmit={handleFilesDialogSubmit}
                />
            )}
            {positionsDialogState && (
                <CmmPositionsDialog
                    isOpen={true}
                    importResult={positionsDialogState}
                    onCancel={() => setPositionsDialogState(null)}
                    onSubmit={applyImports}
                />
            )}
        </>
    );
}
