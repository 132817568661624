

interface Props {
    style?: React.CSSProperties;
    onClick: () => void;
}

export function ClearFiltersButton({
    style,
    onClick,
}: Props) {

    return (
        <button
            className='button--clear-filters'
            style={{ ...style }}
            onClick={(e) => { e.stopPropagation(); onClick(); }}
        >
            <img src='/clear-filter-icon.svg' alt='Clear filters' />
        </button>
    );
}
