import { OeeGraphOptions } from './oeeGraphOptions';


type TimeFrameOption = OeeGraphOptions['timeFrame'] & {
    key: number;
    label: string;
}
const timeFrameOptions: TimeFrameOption[] = [
    { key: 0, label: '8 hours', hours: 8 },
    { key: 1, label: '1 days', days: 1 },
    { key: 2, label: '2 days', days: 2 },
    { key: 3, label: '3 days', days: 3 },
    { key: 4, label: '4 days', days: 4 },
    { key: 5, label: '5 days', days: 5 },
    { key: 6, label: '6 days', days: 6 },
    { key: 7, label: '7 days', days: 7 },
]


interface Props {
    style?: React.CSSProperties;
    shiftOptionsOnly?: boolean;
    showHeader?: boolean;
    oeeGraphOptions: OeeGraphOptions;
    setOeeGraphOptions: (oeeGraphOptions: OeeGraphOptions) => void;
}

export default function OeeGraphControls({
    style,
    showHeader,
    shiftOptionsOnly,
    oeeGraphOptions,
    setOeeGraphOptions,
}: Props) {

    const handleShiftSelectionChange = (shift: string, isEnabled: boolean) => {
        const visibleShifts = isEnabled
            ? [...oeeGraphOptions.visibleShifts, shift]
            : oeeGraphOptions.visibleShifts.filter(s => s !== shift);

        setOeeGraphOptions({ ...oeeGraphOptions, visibleShifts });
    }

    const handleTimeRangeChange = (timeFrame: TimeFrameOption) => {
        setOeeGraphOptions({
            ...oeeGraphOptions,
            timeFrame: { days: timeFrame.days, hours: timeFrame.hours }
        });
    }


    const isTimeFrameVisible = !shiftOptionsOnly;
    const isSmoothingVisible = !shiftOptionsOnly;
    const isResolutionVisible = !shiftOptionsOnly;
    const isValueTypeVisible = !shiftOptionsOnly;
    if (isTimeFrameVisible && oeeGraphOptions.timeFrame === undefined
        || isSmoothingVisible && oeeGraphOptions.smoothingAmount === undefined
        || isResolutionVisible && oeeGraphOptions.resolution === undefined
        || isValueTypeVisible && oeeGraphOptions.valueType === undefined
    ) {
        throw new Error('OeeGraphControls: Missing required options');
    }

    const timeFrameOption = !shiftOptionsOnly
        ? timeFrameOptions.find(it => oeeGraphOptions.timeFrame!.days === it.days && oeeGraphOptions.timeFrame!.hours === it.hours)
        : undefined;
    if (!shiftOptionsOnly && !timeFrameOption) {
        throw new Error('OeeGraphControls: Invalid time frame option');
    }

    return (
        <div style={{ ...style, }}>
            {showHeader &&
                <div style={{ fontSize: '24px', marginLeft: '12px', marginBottom: '12px', fontWeight: 'bold' }}>
                    Options
                </div>
            }

            <div className='flex--vert' style={{ gap: '12px', }}>
                <div className='flex--horz' style={{ gap: '20px' }}>
                    <label>
                        <input
                            style={{ marginRight: '8px' }}
                            type='checkbox'
                            checked={oeeGraphOptions.visibleShifts.includes('morning')}
                            onChange={e => handleShiftSelectionChange('morning', e.target.checked)}
                        />
                        Morning
                    </label>
                    <label>
                        <input
                            style={{ marginRight: '8px' }}
                            type='checkbox'
                            checked={oeeGraphOptions.visibleShifts.includes('evening')}
                            onChange={e => handleShiftSelectionChange('evening', e.target.checked)}
                        />
                        Evening
                    </label>
                    <label>
                        <input
                            style={{ marginRight: '8px' }}
                            type='checkbox'
                            checked={oeeGraphOptions.visibleShifts.includes('night')}
                            onChange={e => handleShiftSelectionChange('night', e.target.checked)}
                        />
                        Night
                    </label>
                </div>

                <div className='flex--horz' style={{ gap: '12px' }}>
                    <label>
                        <input
                            style={{ marginRight: '8px' }}
                            type='checkbox'
                            checked={oeeGraphOptions.excludeWeekends}
                            onChange={e => setOeeGraphOptions({ ...oeeGraphOptions, excludeWeekends: e.target.checked })}
                        />
                        Exclude weekends
                    </label>
                </div>

                {isTimeFrameVisible &&
                    <div className='flex--horz' style={{ gap: '12px' }}>
                        <label htmlFor='slider'>Time frame length</label>
                        <input
                            id='slider'
                            type='range'
                            min={0}
                            max={7}
                            step={1}
                            value={timeFrameOption!.key}
                            onChange={(e) => handleTimeRangeChange(timeFrameOptions[+e.target.value])}
                        />

                        <span style={{ position: 'relative' }}>
                            {/* Invisible label to make sure element size stays consistent */}
                            <span aria-hidden='true' style={{ visibility: 'hidden' }}>
                                {timeFrameOptions[1].label}
                            </span>

                            {/* Visible label */}
                            <span style={{ position: 'absolute', left: 0, width: '60px' }}>
                                {timeFrameOption!.label}
                            </span>
                        </span>
                    </div>
                }

                {isSmoothingVisible &&
                    <div className='flex--horz' style={{ gap: '12px' }}>
                        <label htmlFor='smoothing'>Smoothing amount</label>
                        <input
                            id='smoothing'
                            type='range'
                            min={0}
                            max={2}
                            step={1}
                            value={oeeGraphOptions.smoothingAmount}
                            onChange={(e) => setOeeGraphOptions({ ...oeeGraphOptions, smoothingAmount: +e.target.value })}
                        />
                        <span>{oeeGraphOptions.smoothingAmount} bars</span>
                    </div>
                }

                {isResolutionVisible &&
                    <div className='flex--horz' style={{ gap: '12px' }}>
                        <label htmlFor='resolution'>Resolution</label>
                        <select
                            id='resolution'
                            value={oeeGraphOptions.resolution}
                            onChange={(e) => setOeeGraphOptions({ ...oeeGraphOptions, resolution: e.target.value as any })}
                        >
                            <option value='halfHour'>Half hour</option>
                            <option value='hour'>Hour</option>
                        </select>
                    </div>
                }

                {isValueTypeVisible &&
                    <div className='flex--horz' style={{ gap: '12px' }}>
                        <label htmlFor='valueType'>Value type</label>
                        <select
                            id='valueType'
                            value={oeeGraphOptions.valueType}
                            onChange={(e) => setOeeGraphOptions({ ...oeeGraphOptions, valueType: e.target.value as any })}
                        >
                            <option value='availability'>Availability</option>
                            <option value='quality'>Quality</option>
                            <option value='scraps'>Scraps</option>
                            <option value='performance'>Performance</option>
                            <option value='utilization'>Utilization</option>
                            <option value='oee'>OEE</option>
                            <option value='oeeWithoutSetup'>OEE without setup</option>
                            <option value='teep'>TEEP</option>
                        </select>
                    </div>
                }
            </div>
        </div>
    )
}
