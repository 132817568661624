import { ProtocolFinal, RecordDraft } from '../../types/sharedTypeImpl';
import { evalImportPositions } from './evalImportPositions';
import readCmmFile from './readCmmFile';
import { CmmFileImport, CmmFileRead, FileSource } from './types';


export const CMM_FILE_EXTENSIONS = ['.csv', 'xlsx', '.xlsm', '.xls'];


export async function importCmmFiles(
    protocol: ProtocolFinal,
    record: RecordDraft,
    partIndex: number,
    files: File[],
    fileSource: FileSource,
): Promise<CmmFileImport[]> {
    // Read all files
    const readResults: CmmFileRead[] = [];
    for (const file of files) {
        const readResult = await readCmmFile(file);
        readResults.push(readResult);
    }

    // Create the result object
    const finalResults: CmmFileImport[] = [];
    for (const [index, readResult] of readResults.entries()) {
        const positionImports = readResult.success
            ? evalImportPositions(protocol, record, partIndex, readResults[index].positions!)
            : undefined;

        finalResults.push({
            ...readResult,
            filename: files[index].name,
            fileSource,
            isImportable: readResult.success,
            isIncluded: readResult.success,
            positions: positionImports,
        });
    }

    return finalResults;
}
