import { useQuery } from '@tanstack/react-query';
import { fetchProtocols } from '../api/requests';
import { Protocol } from '../types/sharedTypeImpl';
import { formatDate } from '../utils/fieldFormats';
import ModalDialog from './modalDialog';


interface Props {
    isOpen: boolean;
    protocol: Protocol;
    onClose: () => void;
}

export function ProtocolChangeDialog({
    isOpen,
    protocol,
    onClose,
}: Props) {

    const { data: prevProtocols, isLoading } = useQuery({
        queryKey: ['protocolChanges', protocol.partName, protocol.partRevision, protocol.createTime],
        queryFn: async () => await fetchProtocols({
            partName: protocol.partName,
            partRevision: protocol.partRevision,
            status: 'approved',
            ...(protocol.createTime && { createTimeEnd: protocol.createTime }),
        }),
        select: protocols => protocols.filter(it => it._id != protocol._id).sort((a, b) => +b.createTime! - +a.createTime!),
        enabled: !!protocol.partName && !!protocol.partRevision,
    });


    const protocolsFinal = [protocol, ...(prevProtocols || [])];

    return (
        <ModalDialog
            isOpen={isOpen}
            onCancel={onClose}
        >
            <table className='table__small-padding' style={{ width: '800px' }}>
                <thead>
                    <tr>
                        <td>Version</td>
                        <td>Changes</td>
                        <td>Date</td>
                        <td>Creator</td>
                    </tr>
                </thead>
                <tbody>
                    {isLoading && <tr><td colSpan={4}>Loading...</td></tr>}

                    {!isLoading && protocolsFinal.map((prevProtocol, i) => (
                        <tr key={i}>
                            <td>{prevProtocol.version}</td>
                            <td>{prevProtocol.changes}</td>
                            <td>{formatDate(prevProtocol.createTime)}</td>
                            <td>{prevProtocol.user}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </ModalDialog>
    );
}
