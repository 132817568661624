import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { ManagementViewKpis } from '../../../shared/types/managementViewKpi';
import { putPrice } from '../../api/requests';
import ModalDialog from '../../components/modalDialog';


interface Props {
    managementViewKpis: ManagementViewKpis;
    onPriceSubmitted: (partName: string, price: number) => void;
    onClose: () => void;
}

export default function PriceDialog({
    managementViewKpis,
    onPriceSubmitted,
    onClose,
}: Props) {

    const [price, setPrice] = useState<string>('');


    useEffect(() => {
        setPrice((managementViewKpis.pricePerItem ?? '').toString())
    }, [managementViewKpis]);

    const priceUpdate = useMutation({
        mutationFn: async (price: number) => await putPrice(
            managementViewKpis.partName!, managementViewKpis.product!, price
        ),
        onSuccess: (success, price) => {
            if (success) {
                onPriceSubmitted(managementViewKpis.partName!, price);
                onClose();
            } else {
                alert('Failed to update price');
            }
        }
    });


    function handleKeyDown(e: React.KeyboardEvent) {
        if (e.key === 'Enter') {
            // handleSubmit();
        }
    }

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (!managementViewKpis) return;

        const priceParsed = price ? parseFloat(price) : null;
        if (!priceParsed) {
            alert('Invalid price');
            return;
        }

        await priceUpdate.mutateAsync(priceParsed);
    }


    if (!managementViewKpis) return null;
    return (
        <ModalDialog
            frameStyle={{ minWidth: '360px' }}
            isOpen={true}
            onCancel={onClose}
        >
            <div>Set new price for {managementViewKpis.partName}</div>

            <form onSubmit={handleSubmit}>
                <div className='dialog-form__field--with-margins' >
                    <input
                        className='dialog-form__input'
                        name='price'
                        type={'number'}
                        step={0.01}
                        min={0}
                        value={price}
                        onChange={e => setPrice(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                </div>

                <div className='dialog--button-bar'>
                    <button type='button' onClick={onClose}>Cancel</button>
                    <button type='submit' disabled={priceUpdate.isPending}>
                        {priceUpdate.isPending ? 'Submitting...' : 'Submit'}
                    </button>
                </div>
            </form>
        </ModalDialog >
    )
}
