import TriStateToggle, { TriStateToggleProps } from './triStateToggle';


interface Props extends TriStateToggleProps {
    selection: boolean;
}

export default function ToggleButton(options: Props) {
    return (
        <TriStateToggle {...options} />
    );
}
