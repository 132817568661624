import classNames from 'classnames';
import { Position } from '../../../shared/types/protocol';
import { RecordPosition } from '../../../shared/types/record';
import { getPositionSymbolFinal } from '../../../shared/utils/getPositionSymbolFinal';
import { getValueSourceString } from '../../services/getValueSourceString';
import { evalOrNormalize } from '../../utils/positionInputParse';
import { isOutsideTolerance } from '../../utils/positionSpec';


interface MeasurementPositionProps {
    index: number,
    position: Position,
    positionVal: RecordPosition,
    onUpdatePositionValue: (index: number, value: string) => void,
    isActive: boolean,
    isViewMode: boolean,
    onPositionFocus: (index: number) => void,
}

export function MeasurementPosition({
    index,
    position,
    positionVal,
    onUpdatePositionValue,
    isActive,
    isViewMode,
    onPositionFocus,
}: MeasurementPositionProps) {

    const isAuto = position.toolType === 'CMM' || position.toolType === 'VMM';
    const rowClass = classNames({
        'highlight--selected': isActive && !isAuto,
        'automatic': !isActive && isAuto,
        'automatic--selected': isActive && isAuto,
    });

    const isOutOfSpec = isOutsideTolerance(position, positionVal.value);
    const hasFocus = isActive && !isViewMode;
    const highlightError = isOutOfSpec && !hasFocus;


    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (/^[0-9.,*/+\-()]*$/.test(e.target.value)) {
            const valueNoCommas = e.target.value.replace(/,/g, '.');
            onUpdatePositionValue(index, valueNoCommas)
        }
    }

    const reformatOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const newValue = evalOrNormalize(value);
        onUpdatePositionValue(index, newValue);
    }


    return (
        <tr
            className={rowClass}
            onClick={() => onPositionFocus(index)}
        >
            <td>#<b>{`${position.positionNumber}`}</b></td>
            <td>
                <input
                    className={highlightError ? 'position-field--out-of-spec' : ''}
                    style={{ pointerEvents: isViewMode ? 'none' : 'auto', }}//Let clicks pass through when disabled
                    type='text'
                    value={(positionVal.value as string) ?? ''}
                    onFocus={() => onPositionFocus(index)}
                    onBlur={reformatOnBlur}
                    onChange={onInputChange}
                    disabled={isViewMode}
                    {...(isAuto && { tabIndex: -1 })}
                />
            </td>
            <td>{position.nominal} {getPositionSymbolFinal(position)}</td>
            <td>{position.upperTolerance}</td>
            <td>{position.lowerTolerance}</td>
            <td>{isViewMode ? getValueSourceString(positionVal) : position.toolType}</td>
        </tr>
    );
}
