import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { Point } from '../../../shared/types/mathTypes';


export interface CircleMarkerRef {
    updatePosition: () => void;
}

interface Props {
    drawMarker: boolean;
    drawPositionNumber: boolean;
    positionNumber: string;
    pdfPosition: Point;
    pdfToScreenCoords: (pdfCoords: Point) => Point;
    sizeFactor?: number;
    description?: string;
}

const CircleMarker = forwardRef<CircleMarkerRef, Props>(({
    drawMarker,
    drawPositionNumber,
    pdfPosition,
    positionNumber,
    pdfToScreenCoords,
    sizeFactor,
    description,
}, ref) => {
    const markerContainer = useRef<HTMLDivElement>(null);


    useImperativeHandle(ref, () => ({
        updatePosition,
    }));

    const updatePosition = useCallback(() => {
        if (pdfPosition?.x == null || pdfPosition?.y == null) return;
        const screenPositionFinal = pdfToScreenCoords({ x: pdfPosition.x, y: pdfPosition.y });
        markerContainer.current!.style.left = `${screenPositionFinal.x}px`;
        markerContainer.current!.style.top = `${screenPositionFinal.y}px`;
    }, [pdfPosition?.x, pdfPosition?.y, pdfToScreenCoords]);

    useEffect(() => {
        updatePosition();
    }, [pdfPosition, sizeFactor, description, updatePosition]);


    if (!pdfPosition) {
        return null;
    }

    const circleSize = 50 * (1.5 ** (sizeFactor ?? 0));
    return (
        <div
            ref={markerContainer}
            className='marker-container'
            style={{
                position: 'absolute',
                transform: 'translate(-50%, -50%)',
            }}
        >
            {drawMarker && <div
                style={{
                    width: `${circleSize}px`,
                    height: `${circleSize}px`,
                    border: '2px solid red',
                    borderRadius: '50%',
                    boxSizing: 'border-box',
                    backgroundColor: 'transparent',
                    pointerEvents: 'none',
                }}
            />}

            {drawPositionNumber && <span
                style={{
                    color: 'red',
                    fontSize: '1.4em',
                    fontWeight: 'bold',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    background: '#fffa',
                }}
            >
                {positionNumber ?? 'X'}
            </span>}

            {(description && drawMarker) &&
                <div style={{
                    width: 'max-content',
                    maxWidth: '400px',
                    position: 'absolute',
                    left: '125%',
                    top: '-50%',
                    transform: 'translateY(-50%)',
                    background: '#fff',
                    border: '2px solid #f00',
                    borderRadius: '5px',
                    padding: '8px'
                }}>
                    {description}
                </div>
            }
        </div>
    );
});
export default CircleMarker;
