import { Position } from '../../types/protocol.js';
import { getToleranceRange } from '../../utils/getToleranceRange.js';
import { calcMean, calcStddev, roundToPrecision } from '../../utils/mathUtils.js';


export interface ControlValues {
    mean: number,
    stddev: number,
    tolerances: [number, number],
    sigma1: [number, number],
    sigma2: [number, number],
    sigma3: [number, number],
}

export function getControlValues(
    values: string[],
    position: Position,
): ControlValues | null {
    const valuesAsNumbers = values
        .filter(it => it !== '')
        .map(it => parseFloat(it));
    const mean = calcMean(valuesAsNumbers);
    const stddev = calcStddev(valuesAsNumbers);
    const { min, max } = getToleranceRange(position);

    return {
        mean: round(mean),
        stddev: round(stddev),
        tolerances: [min, max],
        sigma1: [round(mean - stddev), round(mean + stddev)],
        sigma2: [round(mean - 2 * stddev), round(mean + 2 * stddev)],
        sigma3: [round(mean - 3 * stddev), round(mean + 3 * stddev)],
    }
}

function round(value: number): number {
    return roundToPrecision(value, 3);
}
