import { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MeasurementsGraph from '../../components/measurement-graph/measurementsGraph';
import { PageTitle } from '../../components/pageTitle';
import ProtocolPdf from '../../components/protocol-pdf/protocolPdf';
import VisualInspectionImage from '../../components/visualInspectionImage';
import useConfirmOnLeave from '../../hooks/useConfirmOnLeave';
import { Record, RecordDraft } from '../../types/sharedTypeImpl';
import { recordToDraft } from '../../utils/conversion';
import RecordForm from './recordForm';
import { useFetchPrevRecords } from './useFetchPrevRecords';
import useLoadProtocol from './useLoadProtocol';
import useNewOrderWarning from './useNewOrderWarning';
import { usePageSettings } from './usePageSettings';
import UseRecordSave from './useRecordSave';
import useSetupInitialRecord from './useSetupInitialRecord';


export default function RecordView() {
    const maxGraphDataPoints = 15;

    const [record, setRecord] = useState<RecordDraft | null>(null);
    const [activePositionIdx, setActivePositionIdx] = useState<number | null>(null);
    const [showSelectedPosNr, setShowSelectedPosNr] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [isSavedRecord, setIsSavedRecord] = useState(false);
    const isFinalSavedRef = useRef(false);

    const location = useLocation();
    const navigate = useNavigate();

    const {
        isViewMode,
        initialRecordId,
        onNavigateRecord,
    } = usePageSettings();

    const {
        protocol,
        pdfContent,
        pdfFetch,
        positionImages,
    } = useLoadProtocol(record);

    useSetupInitialRecord(initialRecordId, record, setRecord, setIsSavedRecord);
    const activePosition = activePositionIdx != null ? protocol!.positions[activePositionIdx] : null;

    const { recentRecords, prevRecord, nextRecord } = useFetchPrevRecords({
        record: record ?? undefined,
        protocol,
        limit: maxGraphDataPoints,
    });

    const {
        isSaving,
        draftSaveError,
        handleDraftSave,
        handleFinalSave,
    } = UseRecordSave({
        record,
        protocol,
        isViewMode,
        isSavedRecord,
        isDirty,
        setIsDirty,
        isFinalSavedRef,
        onFinalSaved: () => handleClose(),
    });

    useNewOrderWarning({ record, prevRecords: recentRecords, isViewMode });

    useConfirmOnLeave(() => isDirty && !isFinalSavedRef.current, !isViewMode);


    const handleClose = () => {
        const returnPath = location.state?.returnTo;
        const fallbackPath = record?.measurementType === 'ioqc' ? '/ioqc-list' : '/record-list'
        navigate(returnPath ?? fallbackPath);
    }

    const handleNavigateRecord = (record: Record) => {
        if (isSaving || isDirty) {
            const confirmLeave = window.confirm('You have unsaved changes. Do you want to leave?');
            if (!confirmLeave) {
                return;
            }
        }
        setRecord(recordToDraft(record));
        onNavigateRecord(record._id!);
        setIsDirty(false);
    }


    return (
        <>
            <PageTitle title='Record' />
            <div className='measurement-container'>
                <ProtocolPdf
                    className='measurement-main-pane measurement-pdf-container'
                    isViewMode={true}
                    pdfContent={pdfContent}
                    isPdfLoading={pdfFetch.isLoading}
                    positions={protocol?.positions}
                    positionSelection={activePositionIdx != null ? { positionIdx: activePositionIdx } : null} // Creating a new object on render is probably bad for performance
                    showSelectedPosNr={showSelectedPosNr}
                />
                <div className='measurement-side-pane measurement-form-pane'>
                    {protocol && record &&
                        <RecordForm
                            className='measurement-form-top'
                            protocol={protocol}
                            record={record}
                            setRecord={setRecord}
                            activePositionIdx={activePositionIdx}
                            setActivePositionIdx={setActivePositionIdx}
                            showSelectedPosNr={showSelectedPosNr}
                            setShowSelectedPosNr={setShowSelectedPosNr}
                            isDirty={isDirty}
                            setIsDirty={setIsDirty}
                            isSavedRecord={isSavedRecord}
                            isViewMode={isViewMode}
                            isSaving={isSaving}
                            draftSaveError={draftSaveError}
                            prevRecord={prevRecord}
                            nextRecord={nextRecord}
                            onDraftSave={handleDraftSave}
                            onFormSubmit={handleFinalSave}
                            onCloseClicked={handleClose}
                            onNavigateRecord={handleNavigateRecord}
                        />
                    }

                    {activePosition?.type === 'measurement' &&
                        <MeasurementsGraph
                            className='measurement-form-bottom'
                            protocol={protocol!}
                            currentRecord={record}
                            prevRecords={recentRecords}
                            selectedPositionIdx={activePositionIdx!}
                            openDialogOnClick={true}
                            maxDataPoints={maxGraphDataPoints}
                        />
                    }

                    {activePosition?.type === 'visualInspection' &&
                        <VisualInspectionImage
                            className='measurement-form-bottom'
                            imageData={activePosition ? positionImages?.[activePositionIdx!] : null}
                            isViewMode={true}
                        />
                    }
                </div>
            </div>
        </>
    );
}
