import { Position } from '../../../shared/types/protocol';
import { getToleranceRange, getToleranceRangeStrings } from '../../../shared/utils/getToleranceRange';
import { ProtocolFinal, RecordDraft } from '../../types/sharedTypeImpl';
import { CmmPositionImport, CmmPositionRead } from './types';


export function evalImportPositions(
    protocol: ProtocolFinal,
    record: RecordDraft,
    partIndex: number,
    importedValues: CmmPositionRead[]
): CmmPositionImport[] {
    const importIssues = importedValues.map(importedValue => {
        const positionIdx = protocol.positions.findIndex(it => it.positionNumber === importedValue.positionNumber);
        const position = protocol.positions[positionIdx];
        const currentValue = record.parts[partIndex].positionValues[positionIdx]?.value;

        return getIssues(importedValue.positionNumber, importedValue.value, currentValue, position);
    });

    return importIssues;
}

function getIssues(
    positionNumber: string,
    importedValue: string,
    currentValue: string | boolean | undefined,
    position: Position | undefined,
): CmmPositionImport {
    let isImportable = true;
    let includeByDefault = true;

    const issues = [];
    const parsedImportedValue = parseFloat(importedValue);
    if (position == undefined) {
        issues.push('Position not found');
        isImportable = false;
        includeByDefault = false;
    } else if (position.type === 'visualInspection') {
        issues.push('Invalid position type');
        isImportable = false;
        includeByDefault = false;
    } else if (Number.isNaN(parsedImportedValue) || importedValue.length === 0) {
        issues.push('Invalid value');
        isImportable = false;
        includeByDefault = false;
    } else {
        const { min, max } = getToleranceRange(position);
        if (parsedImportedValue < min || parsedImportedValue > max) {
            const { min: minString, max: maxString } = getToleranceRangeStrings(position);
            issues.push(`Out of tolerance: ${minString}...${maxString}`);
        }

        if (currentValue != undefined && currentValue !== '' && currentValue !== importedValue) {
            issues.push(`Value already exists: ${currentValue}`);
            includeByDefault = false;
        }
    }

    return {
        positionNumber,
        value: importedValue,
        isImportable,
        issues,
        isIncluded: includeByDefault,
    };
}
