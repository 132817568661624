import { Dispatch, SetStateAction } from 'react';
import PositionSelection from '../../types/positionSelection';
import { ProtocolDraft } from '../../types/sharedTypeImpl';
import Input from '../input';
import { useUpdateProtocol } from './useUpdateProtocol';


interface Props {
    index: number,
    protocol: ProtocolDraft,
    setProtocol: Dispatch<SetStateAction<ProtocolDraft | null>>,
    positionSelection: PositionSelection | null,
    setPositionSelection: (positionSelection: PositionSelection | null) => void,
    setIsDirty: (isDirty: boolean) => void
    isReadOnly: boolean;
    onMenuOpenClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
}

export function VisualInspectionPosition({
    index,
    protocol,
    setProtocol,
    positionSelection,
    setPositionSelection,
    setIsDirty,
    isReadOnly,
    onMenuOpenClick,
}: Props) {
    const position = protocol.positions[index];
    const isActive = index === positionSelection?.positionIdx;


    const {
        setPositionField,
        // setMarkerField,
    } = useUpdateProtocol(protocol, setProtocol, setPositionSelection, setIsDirty);

    function handleSelect() {
        setPositionSelection({ positionIdx: index });
    }


    return (
        <tr
            className={`${isActive && 'highlight--selected'}`}
            onClick={handleSelect}
        >
            <td>
                <Input
                    type='number'
                    value={position.positionNumber}
                    onChange={(e) => { setPositionField(index, 'positionNumber', e.target.value) }}
                    onFocus={handleSelect}
                    readOnly={isReadOnly}
                    style={{ pointerEvents: isReadOnly ? 'none' : 'auto' }} //Let clicks pass through when readonly
                />
            </td>
            <td colSpan={8}>
                <input
                    type='text'
                    value={position.description ?? ''}
                    onChange={(e) => { setPositionField(index, 'description', e.target.value) }}
                    onFocus={handleSelect}
                    readOnly={isReadOnly}
                    style={{ pointerEvents: isReadOnly ? 'none' : 'auto' }} //Let clicks pass through when readonly
                />
            </td>
            <td>
                <button
                    className='td__menu-button'
                    onClick={onMenuOpenClick}
                    onFocus={handleSelect}>
                    &#8942;
                </button>
            </td>
        </tr>
    )
}
