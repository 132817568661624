import * as React from 'react';
import { useState } from 'react';
import { IoqcType } from '../../../shared/types/record';
import Button from '../../components/button';
import CalibratedToolDialog from '../../components/calibratedToolDialog';
import { IntegerInput } from '../../components/integerInput';
import OperatorSelect from '../../components/operatorSelect';
import ProtocolMetaFields from '../../components/protocol-form/protocolMetaFields';
import useFormKeyboardNavigation from '../../components/protocol-form/useFormKeyboardNavigation';
import { ProtocolFinal, Record, RecordDraft } from '../../types/sharedTypeImpl';
import { formatDatetime } from '../../utils/fieldFormats';
import { openLinkInNewTab } from '../../utils/navigation';
import IoqcTypeSelect from './ioqcTypeSelect';
import RecordFormPositions from './recordFormPositions';
import { validateRecordForm } from './validateRecordForm';


interface Props {
    className?: string;
    protocol: ProtocolFinal;
    record: RecordDraft;
    setRecord: (record: RecordDraft) => void;
    activePositionIdx: number | null;
    setActivePositionIdx: (index: number) => void;
    showSelectedPosNr: boolean;
    setShowSelectedPosNr: (showSelectedPosNr: boolean) => void;
    isDirty: boolean;
    setIsDirty: (isDirty: boolean) => void;
    isSavedRecord: boolean;
    isViewMode: boolean;
    isSaving: boolean;
    draftSaveError: string | null;
    prevRecord?: Record;
    nextRecord?: Record;
    onDraftSave: () => void;
    onFormSubmit: () => void;
    onCloseClicked: () => void;
    onNavigateRecord: (record: Record) => void;
}

export default function RecordForm({
    className,
    protocol,
    record,
    setRecord,
    activePositionIdx,
    setActivePositionIdx,
    showSelectedPosNr,
    setShowSelectedPosNr,
    isDirty,
    setIsDirty,
    isSavedRecord,
    isViewMode,
    isSaving,
    draftSaveError,
    prevRecord,
    nextRecord,
    onDraftSave,
    onFormSubmit,
    onCloseClicked,
    onNavigateRecord,
}: Props) {

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [hasTargetQuantityChanged, setHasTargetQuantityChanged] = useState(false);
    const [isPartCountEditEnabled, setIsPartCountEditEnabled] = useState(false);

    const formRef = useFormKeyboardNavigation();


    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRecord({
            ...record,
            [event.target.name]: event.target.value
        });
        setIsDirty(true);
    }
    const handleOrderNumberChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRecord({
            ...record,
            [event.target.name]: event.target.value,
            ...(hasTargetQuantityChanged ? null : { targetQuantity: null })
        });
        setIsDirty(true);
    }
    const handleIntegerChange = (field: keyof Record, value: number | null) => {
        setRecord({
            ...record,
            [field]: value,
        });
        setIsDirty(true);
    }
    const handleTargetQuantityChange = (value: number | null) => {
        setRecord({
            ...record,
            targetQuantity: value,
        });
        setIsDirty(true);
        setHasTargetQuantityChanged(true);
    }
    const handleIoqcTypeChange = (ioqcType: IoqcType) => {
        setRecord({
            ...record,
            ioqcType,
        });
        setIsDirty(true);
    }
    const handleOperatorChange = (operatorInitials: string) => {
        setRecord({
            ...record,
            'user': operatorInitials,
        });
        setIsDirty(true);
    }

    const handleCalibratedToolsSubmit = (selectedTools: string[]) => {
        setRecord({
            ...record,
            usedTools: selectedTools,
        });
        setIsDialogOpen(false);
        setIsDirty(true);
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (validateRecordForm(record, protocol)) {
            onFormSubmit();
        }
    }

    const handleDraftSave = () => {
        onDraftSave();
    }

    const handleCloseClicked = (e: React.MouseEvent) => {
        e.preventDefault();
        onCloseClicked();
    }

    return (
        <div className={`${className} measurement-form`}>
            <button
                onClick={() => openLinkInNewTab('https://berryglade.sharepoint.com/:w:/s/BGNET/EQqUpUnfYEtHkUFOiuILpRMBhOc9JOqz4pizmpFhLq1B5w?e=ECJOby')}
                children='Help'
            />
            <br />

            <form
                ref={formRef}
                onSubmit={handleSubmit}
            >
                <table className='record-form--table' >
                    <tbody>
                        <ProtocolMetaFields
                            protocol={protocol}
                            disabled={true}
                        />

                        <tr>
                            <td>Measurement start</td>
                            <td>
                                <input
                                    type='text'
                                    value={formatDatetime(record.measurementStart)}
                                    disabled={true}
                                />
                            </td>
                        </tr>
                        {(isViewMode && record.measurementEnd) &&
                            <tr>
                                <td>Measurement end</td>
                                <td>
                                    <input
                                        type='text'
                                        value={formatDatetime(record.measurementEnd)}
                                        disabled={true}
                                    />
                                </td>
                            </tr>
                        }
                        {record.measurementType === 'production' &&
                            <tr>
                                <td>Equipment</td>
                                <td>
                                    <input
                                        type='text'
                                        value={record.equipmentDisplayName}
                                        disabled={true}
                                    /><br />
                                </td>
                            </tr>
                        }
                        {record.measurementType === 'ioqc' &&
                            <tr>
                                <td>Measurement type</td>
                                <td>
                                    <IoqcTypeSelect
                                        value={record.ioqcType}
                                        onChange={handleIoqcTypeChange}
                                        disabled={isViewMode}
                                    />
                                </td>
                            </tr>
                        }
                        <tr>
                            <td>Order number</td>
                            <td>
                                <input
                                    type='text'
                                    name='orderNumber'
                                    value={record.orderNumber}
                                    onChange={handleOrderNumberChange}
                                    disabled={isViewMode}
                                /><br />
                            </td>
                        </tr>
                        {record.measurementType === 'ioqc' &&
                            <tr>
                                <td>Purchase order</td>
                                <td>
                                    <input
                                        type='text'
                                        name='purchaseOrder'
                                        value={record.purchaseOrder ?? ''}
                                        onChange={handleTextChange}
                                        disabled={isViewMode}
                                    /><br />
                                </td>
                            </tr>
                        }
                        {record.measurementType === 'production' &&
                            <tr>
                                <td>Target quantity</td>
                                <td>
                                    <IntegerInput
                                        value={record.targetQuantity ?? null}
                                        onChange={handleTargetQuantityChange}
                                        disabled={isViewMode}
                                    /><br />
                                </td>
                            </tr>
                        }
                        {record.measurementType === 'ioqc' &&
                            <tr>
                                <td>Quantity</td>
                                <td>
                                    <IntegerInput
                                        value={record.quantity ?? null}
                                        onChange={handleIntegerChange.bind(null, 'quantity')}
                                        disabled={isViewMode}
                                    /><br />
                                </td>
                            </tr>
                        }
                        {record.measurementType === 'production' &&
                            <tr>
                                <td>Part count</td>
                                <td>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <IntegerInput
                                            style={{ flexGrow: 1 }}
                                            value={record.partCount ?? null}
                                            onChange={handleIntegerChange.bind(null, 'partCount')}
                                            disabled={isViewMode || !isPartCountEditEnabled}
                                        />
                                        {(!isViewMode && !isPartCountEditEnabled) &&
                                            <Button
                                                type='button'
                                                onClick={() => setIsPartCountEditEnabled(true)}
                                                content='Edit'
                                            />
                                        }
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>

                <RecordFormPositions
                    protocol={protocol}
                    record={record}
                    setRecord={setRecord}
                    activePositionIdx={activePositionIdx}
                    setActivePositionIdx={setActivePositionIdx}
                    showSelectedPosNr={showSelectedPosNr}
                    setShowSelectedPosNr={setShowSelectedPosNr}
                    isViewMode={isViewMode}
                    setIsDirty={setIsDirty}
                />

                <table className='record-form--table' style={{ marginTop: '12px' }}>
                    <tbody>
                        <tr>
                            <td>Used tools</td>
                            <td>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <textarea
                                        style={{ flexGrow: 1 }}
                                        value={record.usedTools.join('\n')}
                                        rows={record.usedTools.length || 1}
                                        disabled={true} />
                                    {!isViewMode &&
                                        <button type='button' onClick={() => setIsDialogOpen(true)}>Update</button>
                                    }
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>User</td>
                            <td>
                                <OperatorSelect
                                    operatorInitials={record.user ?? ''}
                                    disabled={isViewMode}
                                    onChange={handleOperatorChange}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Comment</td>
                            <td>
                                <textarea
                                    rows={3}
                                    name='comment'
                                    value={record.comment}
                                    onChange={handleTextChange}
                                    disabled={isViewMode}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px' }}>
                    {!isViewMode && <button type='submit'>Submit</button>}
                    {(!isViewMode && !isSavedRecord) && <button type='button' onClick={handleDraftSave}>Save draft</button>}
                    <button onClick={handleCloseClicked}>Close</button>
                    {
                        isSaving ? <span> Saving...</span>
                            : draftSaveError ? <span className='error'> Error: {draftSaveError}</span>
                                : (!isDirty && !isViewMode) && <span> Changes saved</span>
                    }
                    {record.measurementType === 'production' && <>
                        <div style={{ flexGrow: 1 }} />
                        <button
                            type='button'
                            onClick={() => onNavigateRecord(prevRecord!)}
                            disabled={!prevRecord}
                            children='Previous record'
                        />
                        <button
                            type='button'
                            onClick={() => onNavigateRecord(nextRecord!)}
                            disabled={!nextRecord}
                            children='Next record'
                        />
                    </>}
                </div>
            </form>

            <CalibratedToolDialog
                isOpen={isDialogOpen}
                initialSelection={record.usedTools}
                onCancel={() => { setIsDialogOpen(false); }}
                onSubmit={handleCalibratedToolsSubmit} />
        </div>
    );
}
