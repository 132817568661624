import { useEffect } from 'react';
import { useFetchPositionImages, useFetchProtocolOnce, useFetchProtocolPdfOnce } from '../../hooks/fetchHooks';
import { Protocol, ProtocolDraft, ProtocolFinal } from '../../types/sharedTypeImpl';
import { protocolToDraft } from '../../utils/conversion';


export default function useProtocolInit(
    setProtocol: (protocol: ProtocolDraft) => void,
    setPdfContent: (pdfContent: Uint8Array) => void,
) {
    const searchParams = new URLSearchParams(window.location.search);
    const protocolId = searchParams.get('id');
    const baseProtocolId = searchParams.get('baseProtocolId');
    const isCopy = searchParams.get('isCopy') === 'true';
    const mode = searchParams.get('mode') as 'edit' | 'view' | 'review' ?? 'edit';


    useEffect(() => {
        if (protocolId == null) {
            setProtocol(newDraft());
        }
    }, [protocolId, setProtocol]);

    const protocolFetch = useFetchProtocolOnce(protocolId ?? baseProtocolId, (fetchedProtocol: Protocol) => {
        let protocol: ProtocolDraft;
        if (protocolId == null && baseProtocolId != null) {
            protocol = draftFromBase(fetchedProtocol as ProtocolFinal, isCopy);
        } else {
            protocol = protocolToDraft(fetchedProtocol as ProtocolFinal);
        }
        setProtocol(protocol);
    });

    const newDraft = (): ProtocolDraft => {
        return {
            status: 'draft',
            partName: '',
            partRevision: '',
            partDisplayName: '',
            programs: [],
            positions: [],
        }
    }

    const draftFromBase = (protocol: ProtocolFinal, isCopy: boolean): ProtocolDraft => {
        const newDraft = {
            ...protocolToDraft(protocol),
            _id: undefined,
            baseProtocol: protocol._id,
            isCopy,
        };
        newDraft.status = 'draft';
        delete newDraft.changes;
        delete newDraft.createTime;
        delete newDraft.version;
        delete newDraft.isDisabled;
        delete newDraft.reviews;
        return newDraft;
    }

    const pdfFetch = useFetchProtocolPdfOnce(protocolId ?? baseProtocolId, (protocolPdf: Uint8Array | undefined) => {
        if (protocolPdf) {
            setPdfContent(protocolPdf);
        }
    });

    const inspectionQueries = useFetchPositionImages(protocolFetch.data);
    const savedPositionImages = inspectionQueries.map(it => it.data);


    return {
        isProtocolLoading: protocolFetch.isLoading,
        isPdfLoading: pdfFetch.isLoading,
        protocolError: protocolFetch.error,
        mode,
        isViewMode: mode === 'view',
        savedPositionImages,
    }
}
