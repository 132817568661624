import { useRef, useState } from 'react';
import useAutoSave from '../../hooks/useAutoSave';
import { getCriticalValuesMissing } from '../../services/getCriticalValuesMissing';
import { ProtocolFinal, RecordDraft } from '../../types/sharedTypeImpl';
import { draftToRecord } from '../../utils/conversion';
import { getNumOutOfTolerance } from '../../utils/positionSpec';
import { trpc } from '../../utils/trpc';


interface Props {
    record: RecordDraft | null;
    protocol: ProtocolFinal | undefined;
    isViewMode: boolean;
    isSavedRecord: boolean;
    isDirty: boolean;
    setIsDirty: (isDirty: boolean) => void;
    isFinalSavedRef: React.MutableRefObject<boolean>;
    onFinalSaved: () => void;
}

export default function UseRecordSave({
    record,
    protocol,
    isViewMode,
    isSavedRecord,
    isDirty,
    setIsDirty,
    isFinalSavedRef,
    onFinalSaved,
}: Props
) {
    const [isAutoSaving, setIsAutoSaving] = useState(false);
    const [isFinalSaving, setIsFinalSaving] = useState(false);
    const [draftSaveError, setDraftSaveError] = useState<string | null>(null);
    const isFinalSavePendingRef = useRef(false);//Set true, if trying to start final save, while auto save is in progress

    const trpcUtils = trpc.useUtils();
    const upsertRecord = trpc.records.upsertRecord.useMutation({
        onSuccess: () => trpcUtils.records.invalidate(),
    });

    useAutoSave({
        isViewMode,
        isFinalized: isSavedRecord,
        isDirty,
        isFinalSaving,
        autoSaveCallback: async () => await handleDraftSave()
    });


    const handleDraftSave = async () => {
        if (!record || isAutoSaving || isFinalSavedRef.current) return;
        try {
            setIsAutoSaving(true);
            setDraftSaveError(null);
            console.log('Draft save started');

            await upsertRecord.mutateAsync(record);

            setIsDirty(false);
            console.log('Draft save success');
        } catch (e) {
            console.log('Draft save failed');
            setDraftSaveError('Draft save failed');
        } finally {
            setIsAutoSaving(false);
            if (isFinalSavePendingRef.current) {
                isFinalSavePendingRef.current = false;
                handleFinalSave();
            }
        }
    }

    const handleFinalSave = async () => {
        if (!record || !protocol || isFinalSaving || isFinalSavePendingRef.current || isFinalSavedRef.current) return;
        if (isAutoSaving) {
            isFinalSavePendingRef.current = true;
            return;
        }
        console.log('Final save started');

        setIsFinalSaving(true);
        const numOutOfSpec = getNumOutOfTolerance(record, protocol);
        const criticalValuesMissing = getCriticalValuesMissing(record, protocol);
        const recordFinal = draftToRecord(record, numOutOfSpec, criticalValuesMissing);
        try {
            await upsertRecord.mutateAsync(recordFinal);

            setIsDirty(false);
            isFinalSavedRef.current = true;
            console.log('Final save successful');
            onFinalSaved();
        } catch (e) {
            alert(`Failed to save record.`);
        } finally {
            setIsFinalSaving(false);
        }
    }


    return {
        isSaving: isAutoSaving || isFinalSaving,
        draftSaveError,
        handleDraftSave,
        handleFinalSave,
    }
}
