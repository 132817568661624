

//Six points or more in a row steadily increasing or decreasing
export function checkConsecutiveChange(values: Array<number | null>): Array<boolean | null> {
    const NUM_CONSECUTIVE = 6;

    const flags = values.map(it => it != null ? false as boolean : null);
    let prevValue = undefined;
    let prevIndex = 0;
    let sequenceDir = 0;
    let sequenceStart = 0;
    let sequenceCount = 0;
    for (let i = 0; i < values.length; i++) {
        if (values[i] === null) {
            continue;
        }

        const dir = values[i] == null || prevValue == null || values[i] === prevValue ? 0
            : values[i]! > prevValue ? 1 : -1;
        if (dir !== 0 && dir === sequenceDir) {
            sequenceCount++;
        } else {
            sequenceCount = 2;
            sequenceStart = prevIndex;
            sequenceDir = dir;
        }

        if (sequenceCount >= NUM_CONSECUTIVE) {
            for (let j = sequenceStart; j <= i; j++) if (flags[j] != null) flags[j] = true;
        }
        prevValue = values[i];
        prevIndex = i;
    }
    return flags;
}
