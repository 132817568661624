import { TestImportFile } from '../services/cmm-import/types';
import ModalDialog from './modalDialog';
import ToggleButton from './toggleButton';


interface Props {
    importResult: TestImportFile;
    onClose: () => void;
}

export function TestImportDialog({
    importResult,
    onClose,
}: Props) {

    return (
        <ModalDialog
            isOpen={true}
            onCancel={onClose}
        >
            {importResult.success
                ? <table className='position-import-table'>
                    <thead>
                        <tr>
                            <th>Position</th>
                            <th>Value</th>
                            <th>Type</th>
                            <th>Issues</th>
                            <th>Is OK</th>
                        </tr>
                    </thead>
                    <tbody>
                        {importResult.positions!.map((it, index) => (
                            <tr key={index}>
                                <td>{it.positionNumber}</td>
                                <td>{it.value}</td>
                                <td>{it.positionType === 'visualInspection' ? 'Visual' : it.positionType}</td>
                                <td style={{ whiteSpace: 'pre-wrap' }}>{it.issues.join('\n')}</td>
                                <td>
                                    <ToggleButton
                                        selection={it.isOk}
                                        trueLabel='OK'
                                        falseLabel='NOK'
                                        readonly={true}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                : <p style={{minWidth: '400px'}}>{importResult.errorMessage}</p>
            }

            <div className='dialog--button-bar'>
                <button onClick={onClose}>Close</button>
            </div>
        </ModalDialog >
    );
}
