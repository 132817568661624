

interface Props {
    className?: string;
    style?: React.CSSProperties;
    content: React.ReactNode;
    onClick: () => void | Promise<void>;
    onFocus?: () => void;
    disabled?: boolean;
    type?: 'button' | 'submit' | 'reset';
}

export default function Button({
    className,
    style,
    content,
    onClick,
    onFocus,
    disabled,
    type,
}: Props) {

    function handleOnClick(e: React.MouseEvent) {
        e.preventDefault();
        e.stopPropagation();
        onClick();
    }


    return (
        <button
            className={className}
            style={style}
            onClick={handleOnClick}
            onFocus={onFocus}
            disabled={disabled}
            type={type}
        >
            {content}
        </button>
    )
}