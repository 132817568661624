import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { sortBy } from '../../../shared/utils/arrayUtils';
import { fetchCncEquipments, fetchCurrentSession, fetchCustomEquipments, fetchPartNames, fetchProducts } from '../../api/requests';
import ComboBox from '../../components/comboBox';
import useFetchOnce from '../../hooks/useFetchOnce';
import MaintenanceLogDialog from './maintenanceLogDialog';
import { SelectedEquipment, SelectedPart } from './types';


interface Props {
    selectedEquipment: SelectedEquipment | null;
    setSelectedEquipment: (equipment: SelectedEquipment | null) => void;
    selectedPart: SelectedPart | null;
    setSelectedPart: (partName: SelectedPart | null) => void;
}

export default function MainControlBar({
    selectedEquipment,
    setSelectedEquipment,
    selectedPart,
    setSelectedPart,
}: Props) {

    const [isMaintenanceLogDialogOpen, setIsMaintenanceLogDialogOpen] = useState(false);


    const { data: cncEquipments } = useFetchOnce({
        queryKey: ['equipments'],
        queryFn: () => fetchCncEquipments(),
        select: (equipments) => sortBy([...equipments], it => it.displayName),
        refetchOnWindowFocus: false,
        refetchInterval: 30 * 60_000,
    });

    const { data: customEquipments } = useQuery({
        queryKey: ['custom-equipments'],
        queryFn: () => fetchCustomEquipments({ isActive: true }),
        select: (equipments) => sortBy([...equipments], it => it.displayName),
    });

    const { data: partNames } = useQuery({
        queryKey: ['part-names'],
        queryFn: () => fetchPartNames(),
        select: (partNames) => [...partNames.sort()],
        refetchInterval: 10 * 60_000,
    });

    const { data: products } = useQuery({
        queryKey: ['products'],
        queryFn: () => fetchProducts(),
        select: (products) => [...products.sort()],
        refetchInterval: 10 * 60_000,
    });

    const { data: currentEquipmentSession } = useQuery({
        queryKey: ['currentSession', selectedEquipment],
        queryFn: () => fetchCurrentSession(selectedEquipment!.id),
        enabled: selectedEquipment != null && selectedEquipment.source === 'mtlinki',
        refetchInterval: 10 * 60_000,
    });

    const equipments = useMemo(() => {
        if (!cncEquipments || !customEquipments) return null;
        else return [...cncEquipments, ...customEquipments];
    }, [cncEquipments, customEquipments]);


    //Set selected equipment or product from url hash
    useEffect(() => {
        if (!equipments) return;

        const hashParams = new URLSearchParams(window.location.hash.substring(1));
        const equipment = hashParams.get('equipment');
        const partName = hashParams.get('partName');
        const product = hashParams.get('product');
        if (equipment) {
            const equipmentObj = equipments.find(it => it.id === equipment);
            setSelectedEquipment(equipmentObj ?
                { id: equipmentObj.id, displayName: equipmentObj.displayName, source: equipmentObj.source }
                : null
            );
        } else if (partName || product) {
            setSelectedEquipment(null);
            setSelectedPart({ partName, product });
        }
    }, [equipments, setSelectedEquipment, setSelectedPart]);

    // On session change
    useEffect(() => {
        if (selectedEquipment == null) return;// should only be null, when partName or product is selected or when first loading the page
        const partName = currentEquipmentSession?.partName ?? null;
        const product = currentEquipmentSession?.product ?? null;
        setSelectedPart(partName || product ? { partName, product } : null);
    }, [selectedEquipment, currentEquipmentSession?.partName, currentEquipmentSession?.product, setSelectedPart]);


    const handleEquipmentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const equipment = equipments?.find(it => it.id === e.target.value);
        if (!equipment) return;
        setSelectedEquipment({ id: equipment.id, displayName: equipment.displayName, source: equipment.source });
        setUrlHash('equipment=' + e.target.value);
    }

    const handlePartNameSubmit = (partName: string) => {
        setSelectedPart({ partName, product: null });
        setSelectedEquipment(null);
        setUrlHash('partName=' + partName);
    }

    const handleProductSubmit = (product: string) => {
        setSelectedPart({ partName: null, product });
        setSelectedEquipment(null);
        setUrlHash('product=' + product);
    }

    const handleMaintenanceLogClick = () => {
        setIsMaintenanceLogDialogOpen(true);
    }

    const handleGuideClick = () => {
        if (!selectedEquipment) return;
        const baseUrl = 'https://berryglade.sharepoint.com/sites/BGNET/Sorvikohtaiset%20ohjeet/';
        const url = baseUrl + encodeURIComponent(selectedEquipment.id);
        window.open(url, '_blank');
    }

    const setUrlHash = (hash: string) => {
        history.replaceState(null, '', document.location.pathname + '#' + hash);
    }


    const showCncViews = selectedEquipment == null || selectedEquipment.source === 'mtlinki';
    return (
        <>
            <div style={{ display: 'flex', gap: '8px' }}>
                <select
                    style={{ width: showCncViews ? '220px' : '280px', textOverflow: 'ellipsis' }}
                    value={selectedEquipment?.id || ''}
                    onChange={handleEquipmentChange}
                >
                    <option value={''} disabled hidden />
                    {equipments?.map(equipment => (
                        <option key={equipment.id} value={equipment.id}>{equipment.displayName}</option>
                    ))}
                </select>

                {showCncViews && <>
                    <ComboBox
                        dataId='partNameForm'
                        label='Part number'
                        defaultValue={selectedPart?.partName ?? ''}
                        suggestions={partNames ?? []}
                        onSubmit={handlePartNameSubmit}
                    />

                    <ComboBox
                        dataId='productForm'
                        label='Program name'
                        defaultValue={selectedPart?.product ?? ''}
                        suggestions={products ?? []}
                        onSubmit={handleProductSubmit}
                    />

                    <div className='flex__spacer' />
                    <button onClick={handleMaintenanceLogClick} disabled={selectedEquipment == null}>Maintenance log</button>
                    <button onClick={handleGuideClick} disabled={selectedEquipment == null}>Guide</button>
                </>}
                {!showCncViews && <>
                    <div className='flex__spacer' />
                    <button onClick={handleGuideClick} disabled={selectedEquipment == null}>Guide</button>
                </>}
            </div>
            {(selectedEquipment && isMaintenanceLogDialogOpen) &&
                <MaintenanceLogDialog
                    equipment={selectedEquipment.id}
                    onClose={() => setIsMaintenanceLogDialogOpen(false)}
                />
            }
        </>
    )
}
