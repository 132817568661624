import { useQuery } from '@tanstack/react-query';
import { fetchOperators } from '../api/requests';
import { Operator } from '../types/sharedTypeImpl';


interface Props {
    className?: string;
    operatorInitials?: string;
    required?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    onChange?: (operatorInitials: string) => void;
}

export default function OperatorSelect({
    className,
    operatorInitials,
    required,
    disabled,
    readOnly,
    onChange,
}: Props) {

    const { data: operators } = useQuery({
        queryKey: ['operators'],
        queryFn: async () => await fetchOperators(),
        enabled: !disabled
    });

    const usingUnlistedOperator = operatorInitials && !operators?.find(
        (it) => it.initials.toUpperCase() === operatorInitials.toUpperCase()//Back in the day, people didn't use consistent casing
    );
    const options = [
        ...operators?.filter(it => it.isActive || it.initials === operatorInitials) ?? [],
        ...(usingUnlistedOperator ? [{ initials: operatorInitials.toUpperCase() } as Operator] : [])
    ];


    return (
        <select
            className={className}
            style={{ pointerEvents: readOnly ? 'none' : 'auto' }}
            disabled={disabled ?? false}
            required={required}
            value={operatorInitials ?? ''}
            onChange={(event) => onChange?.(event.target.value)}
        >
            <option value='' disabled hidden />
            {options?.map((it) => (
                <option key={it.initials} value={it.initials}>
                    {it.initials}{it.fullName && ` - ${it.fullName}`}
                </option>
            ))}
        </select>
    )
}
