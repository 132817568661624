import { useMemo } from 'react';
import { sumOf } from '../../../shared/utils/arrayUtils';
import { Operation } from '../../types/sharedTypeImpl';

export interface ScrapTotals {
    numCases: number;
    potentialScraps: number;
    initialScraps: number;
    confirmedScraps: number;
    releasedScraps: number;
}


export function useScrapTotal(operations: Operation[] | undefined): ScrapTotals | null {
    const scrapTotals = useMemo(() => {
        if (!operations) return null;

        const numCases = operations.length;
        const potentialScraps = sumOf(operations, it => it.potentialDiscards ?? 0);
        const initialScraps = sumOf(operations, it => it.discardCount ?? 0);
        const addedConfirmedScraps = sumOf(operations, it => it.scrapCloseForm?.addedConfirmedScrap ?? 0);
        const finalConfirmedScraps = sumOf(operations, it => it.scrapCloseForm?.finalConfirmedScrap ?? 0);
        const releasedScraps = potentialScraps - addedConfirmedScraps;
        return { numCases, potentialScraps, initialScraps, confirmedScraps: finalConfirmedScraps, releasedScraps };
    }, [operations]);

    return scrapTotals;
}
