import { CmmFileRead } from './types';


export function readMitutoyoCmmFile(cmmFileText: string): CmmFileRead {
    try {
        const table = parseTable(cmmFileText);

        const isValidated = isMitutoyo(table) && table.slice(7).every(row => row.length === 9);
        if (!isValidated) {
            return { success: false, errorMessage: 'Invalid file format' };
        }

        const positions = [];
        for (let i = 6; i < table.length; i++) {
            const row = table[i];
            const positionNumber = row[0].match(/Pos (\d+(\.\d+)?)( TP2)?/)?.[1];
            const actualAsFloat = Number.parseFloat(row[5]);
            if (!positionNumber || isNaN(actualAsFloat)) continue;
            positions.push({ positionNumber, value: actualAsFloat.toFixed(3) });
        }

        return {
            success: true,
            valueSource: 'cmm',
            valueSourceSubType: 'mitutoyo',
            positions,
        }
    } catch (error) {
        console.error('Error reading file:', error);
        return { success: false, errorMessage: 'Error reading file' };
    }
}

export function isMitutoyoCmmFile(cmmFileText: string): boolean {
    const table = parseTable(cmmFileText);
    return isMitutoyo(table);
}

function parseTable(cmmFileText: string): string[][] {
    const table = cmmFileText.trim().split('\n').map(row => row.split(';').map(cell => cell.trim()));
    return table;
}

function isMitutoyo(table: string[][]) {
    return table.length >= 7
        && table[3][0] === 'Tilausnumero'
        && table[4][0] === 'Kappalemäärä';
}
