import { Point } from '../../../shared/types/mathTypes';
import { ContextMenu } from '../../components/contextMenu';


export interface SpcTogglesContextMenuState {
    checkIdx: number;
    screenCoords: Point;
}

interface Props {
    screenCoords: Point;
    onEnableAllSelected: () => void;
    onDisableAllSelected: () => void;
    onClose: () => void;
}

export default function SpcTogglesContextMenu({
    screenCoords,
    onEnableAllSelected,
    onDisableAllSelected,
    onClose,
}: Props) {

    const menuItems = [
        {
            text: 'Enable all',
            callback: onEnableAllSelected,
        },
        {
            text: 'Disable all',
            callback: onDisableAllSelected,
        },
    ];

    return (
        <ContextMenu
            isOpen={true}
            position={screenCoords}
            items={menuItems}
            close={onClose}
        />
    );
}
