import * as d3 from 'd3';
import { DelaysGraphData } from './delaysGraphData';


type SVGElement = d3.Selection<SVGSVGElement, unknown, null, undefined>;
// type GElement = d3.Selection<SVGGElement, unknown, null, undefined>;

type GraphDimens = {
    width: number,
    height: number,
    textSize: number,
    margins: { top: number, right: number, bottom: number, left: number },
}
type Graph = d3.Selection<SVGGElement, GraphDimens, null, undefined>;


export function drawDelaysGraph(
    container: HTMLDivElement,
    data: DelaysGraphData[],
) {
    const root = createRootElement(container);
    const graph = createGraphElement(root);
    const axes = createAxes(root, graph, data);
    drawData(graph, axes, data);
}

function createRootElement(container: HTMLDivElement) {
    const rect = container.getBoundingClientRect();
    const containerWidth = rect.width;
    const containerHeight = rect.height;

    let root = d3.select(container).select('svg') as SVGElement;
    if (root.empty()) {
        root = d3.select(container)
            .append('svg')
            .attr('width', containerWidth)
            .attr('height', containerHeight);
    } else {
        root.html('');
    }

    return root;
}


function createGraphElement(root: SVGElement): Graph {
    const graphMargins = { top: 30, right: 30, bottom: 40, left: 90 };
    const graphWidth = +root.attr('width') - graphMargins.left - graphMargins.right;
    const graphHeight = +root.attr('height') - graphMargins.top - graphMargins.bottom;

    let graph = root.select('g') as Graph;
    if (graph.empty()) {
        graph = root.append('g').attr('transform', `translate(${graphMargins.left}, ${graphMargins.top})`) as Graph;
    }

    const textSize = graphWidth > 1000 ? 40 : 30;
    graph.datum({
        width: graphWidth,
        height: graphHeight,
        margins: graphMargins,
        textSize,
    });

    return graph;
}

function createAxes(
    root: SVGElement,
    graph: Graph,
    data: DelaysGraphData[],
    maxValue?: number,
) {
    const { width: graphWidth, height: graphHeight, margins: graphMargins, textSize } = graph.datum();

    const x = d3
        .scaleBand()
        .range([0, graphWidth])
        .domain(data.map(d => d.label))
        .padding(0.4);
    root.append('g')
        .attr('transform', `translate(${graphMargins.left}, ${graphHeight + graphMargins.top})`)
        .call(d3.axisBottom(x))
        .selectAll('text')
        .style('font-size', textSize + 'px');

    const y = d3
        .scaleLinear()
        .range([graphHeight, 0])
        .domain([0, 1]);
    root.append('g')
        .attr('transform', `translate(${graphMargins.left}, ${graphMargins.top})`)
        .call(d3.axisLeft(y))
        .selectAll('text')
        .style('font-size', textSize + 'px');
    return { x, y };
}

function drawData(
    graph: Graph,
    { x, y }: ReturnType<typeof createAxes>,
    data: DelaysGraphData[],
) {
    const { height: graphHeight, textSize } = graph.datum();

    // Append the bar graph
    data.forEach(bar => {
        let prevPercentageSum = 0;
        for (const section of bar.sections) {
            // Append bar graph section
            graph.append('rect')
                .attr('x', x(bar.label)!)
                .attr('y', y(section.percentage + prevPercentageSum))
                .attr('width', x.bandwidth())
                .attr('height', graphHeight - y(section.percentage))
                .attr('fill', section.color);

            // Append percentage text
            if (section.percentage > 0.04) {
                const sectionMiddle = prevPercentageSum + section.percentage / 2;
                graph.append('text')
                    .attr('x', x(bar.label)! + x.bandwidth() / 2)
                    .attr('y', y(sectionMiddle) + 0.5 * textSize)
                    .attr('text-anchor', 'middle')
                    .attr('font-family', 'Arial')
                    .attr('font-size', textSize + 'px')
                    .text(section.label);
            }

            prevPercentageSum += section.percentage;
        }
    });
}
