import { Protocol, RecordDraft } from '../types/sharedTypeImpl';


export function getCriticalValuesMissing(record: RecordDraft, protocol: Protocol): boolean {
    const criticalValuesMissing = record.parts.some(part => {
        return part.positionValues.some((positionValue, index) => {
            const isCritical = protocol.positions[index].isCritical ?? false;
            const isValueMissing = positionValue.value == null || positionValue.value === '';
            return isCritical && isValueMissing;
        });
    });
    return criticalValuesMissing;
}
