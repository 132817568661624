import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { forwardRef, useImperativeHandle } from 'react';
import { NavLink } from 'react-router-dom';
import { fetchIsAdmin, fetchPageIssues, logout } from '../api/requests';
import useFetchOnce from '../hooks/useFetchOnce';


export interface NavBarRef {
    refetchPageIssues: () => void;
    refetchIfMeasurementsMismatch: (measurementsIssues: number) => void;
}

const NavBar = forwardRef<NavBarRef>((_, ref) => {

    const { data: isAdmin } = useFetchOnce<boolean>({
        queryKey: ['isAdmin'],
        queryFn: async () => await fetchIsAdmin(),
    });

    const { data: pageIssues, refetch: refetchPageIssues } = useQuery({
        queryKey: ['pageIssues'],
        queryFn: async () => await fetchPageIssues(),
        refetchInterval: 30_000,
    });

    useImperativeHandle(ref, () => ({
        refetchPageIssues: () => {
            setTimeout(() => refetchPageIssues(), 500);
        },
        refetchIfMeasurementsMismatch: (measurementsIssues: number) => {
            if (pageIssues?.measurementsIssues !== measurementsIssues) {
                refetchPageIssues();
            }
        },
    }));


    return (
        <nav>
            <ul className="nav">
                <Internal href='/' text='Home' />
                <Internal href='/measurement-interval' text='Measurement' numIssues={pageIssues?.measurementsIssues} />
                <Internal href='/machine-log' text='Machine Log' />
                <Internal href='/overlook' text='Overlook' />
                <Internal href='/potential-scraps' text='Scraps' numIssues={pageIssues?.potentialScrapsIssues} />
                <Internal href='/management-view' text='Management' />
                <Internal href='/live-graphs' text='Live Graphs' />
                <Internal href='/broken-tools' text='Broken Tools' numIssues={pageIssues?.brokenToolsIssues} />
                {isAdmin && <Internal href='/protocol-list' text='Protocols' numIssues={pageIssues?.protocolIssues} />}
                <Internal href='/record-list' text='Records' />
                <Internal href='/ioqc-list' text='IOQC' />
                {isAdmin && <Internal href='/resources' text='Resources' />}
                {isAdmin && <Internal href='/spc-list' text='Spc' />}
                {isAdmin && <Internal href='/reporting-tool' text='Reporting' />}
                <External href={'http://' + import.meta.env.MTLINK_URL} text='MT-LINKi' />
                <External href='https://berryglade.sharepoint.com/sites/BGNET' text='BGNET' />
                <li className='flex__spacer' />
                <External href='https://berryglade.sharepoint.com/:r:/s/BGNET/EQqUpUnfYEtHkUFOiuILpRMB6anZRrzgtYlsfgLW8i4xtg?e=5Xycay' text='Help' openInNewTab={true} />
                <Logout />
            </ul>
        </nav>
    );
});

export default NavBar;


interface InternalProps {
    href: string;
    text: string;
    numIssues?: number;
}

function Internal({
    href,
    text,
    numIssues,
}: InternalProps) {

    return (
        <li className='nav__item'>
            <NavLink
                to={href}
                className={({ isActive }) => classNames('nav__link', { 'nav__link--active': isActive })}
            >
                {text}
                {(numIssues != null && numIssues > 0) && ` (${numIssues})`}
            </NavLink>
        </li>
    );
}


interface ExternalProps {
    href: string;
    text: string;
    openInNewTab?: boolean;
}
function External({
    href,
    text,
    openInNewTab = false,
}: ExternalProps) {

    return (
        <li className='nav__item'>
            <a
                className={'nav__link'}
                href={href}
                target={openInNewTab ? '_blank' : '_self'}
            >
                {text}
            </a>
        </li>
    );
}


function Logout() {
    const handleLogout = async (event: React.MouseEvent) => {
        event.preventDefault();
        const redirectUrl = await logout();
        window.location.href = redirectUrl;
    };

    return (
        <li className='nav__item'>
            <a
                href='#'
                className={'nav__link'}
                onClick={handleLogout}
            >
                Sign out
            </a>
        </li>
    );
}
