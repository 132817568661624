import { useRef } from 'react';
import Button from '../../components/button';
import ModalDialog from '../../components/modalDialog';
import MaintenanceLogTable, { MaintenanceLogTableRef } from './maintenanceLogTable';


interface Props {
    equipment: string;
    onClose: () => void;
}

export default function MaintenanceLogDialog({
    equipment,
    onClose,
}: Props) {

    const maintenanceLogTableRef = useRef<MaintenanceLogTableRef>(null);

    return (
        <ModalDialog
            isOpen={true}
            frameClassName='modal-frame--fixed_width'
            cancelOnOutsideClick={true}
            onCancel={onClose}
        >
            <MaintenanceLogTable
                ref={maintenanceLogTableRef}
                style={{ marginTop: '0.5rem', marginBottom: '2rem' }}
                equipment={equipment}
            />
            <div className='dialog--button-bar'>
                <Button
                    className='button--large'
                    onClick={() => maintenanceLogTableRef.current?.onAddOperationClicked()}
                    content='Add operation'
                />
                <Button
                    className='button--large'
                    onClick={onClose}
                    content='Close'
                />
            </div>
        </ModalDialog>
    )
}
