import { useEffect, useRef, useState } from 'react';
import { Point, Size } from '../../../shared/types/mathTypes';
import { Position, PositionType } from '../../../shared/types/protocol';
import PdfContextMenu from '../../pages/protocol-view/pdfContextMenu';
import PositionSelection from '../../types/positionSelection';
import ArrowMarker, { ArrowMarkerRef } from './arrowMarker';
import ButtonBar from './buttonBar';
import PdfDisplay from './pdfDisplay';
import PositionMarkers, { PositionMarkersRef } from './positionMarkers';
import useArrowCreate, { ArrowCoords } from './useArrowCreate';
import useZoomPan from './useZoomPan';
import { defaultZoomPanValues, pdfToContainerCoords, pdfToScreenCoords, screenToPdfCoords } from './zoomPanUtils';


interface Props {
    className?: string;
    style?: React.CSSProperties;
    isViewMode: boolean;
    positions?: Position[];
    positionSelection: PositionSelection | null;
    showSelectedPosNr?: boolean;
    showAllMarkers?: boolean;
    showAllPosNrs?: boolean;
    pdfContent: Uint8Array | null;
    isPdfLoading?: boolean;
    onAddPosition?: (type: PositionType, page: number, pdfCoords: Point, pdfCoords2?: Point) => void;
    onAddMarker?: (positionSelection: PositionSelection, pdfCoords: Point, pdfCoords2?: Point) => void;
    onUpdateLocation?: (positionSelection: PositionSelection, page: number, pdfCoords: Point, pdfCoords2?: Point) => void;
}

export default function ProtocolPdf({
    className,
    style,
    isViewMode,
    positions,
    positionSelection,
    showSelectedPosNr,
    showAllMarkers,
    showAllPosNrs,
    pdfContent,
    isPdfLoading,
    onAddPosition,
    onAddMarker,
    onUpdateLocation,
}: Props) {
    //Pdf and zoom/pan state
    const [pageCount, setPageCount] = useState<number | null>(null);
    const [pdfSize, setPdfSize] = useState<Size | null>(null);
    const [page, setPage] = useState<number>(1);
    const [scale, setScale] = useState<number>(1);
    const offset = useRef<Point>({ x: 0, y: 0 });

    //Div and marker refs
    const [isDrawingArrow, setIsDrawingArrow] = useState(false);
    const mouse2DragRef = useRef<ArrowCoords | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const markersRef = useRef<PositionMarkersRef | null>(null);
    const tempArrowMarkerRef = useRef<ArrowMarkerRef | null>(null);

    const [menuCoords, setMenuCoords] = useState<Point | null>(null);

    const activePosition = positionSelection != null ? positions?.[positionSelection.positionIdx] : null;


    useEffect(() => {
        if (activePosition?.page != null) {
            setPage(activePosition.page);
        }
    }, [activePosition, positionSelection?.positionIdx, positionSelection?.markerIdx]);

    const { fitToHeight, onMouseWheel, onMouseDown, onMouseMove } = useZoomPan({
        containerRef,
        contentRef: canvasRef,
        pdfSize,
        scale,
        setScale,
        offset,
        onZoomPan,
    });

    useArrowCreate({
        isEnabled: !isViewMode,
        pdfSize,
        arrowCoordsRef: mouse2DragRef,
        screenToPdfCoords: (screenCoords) => screenToPdfCoords(screenCoords, offset.current, scale),
        onMinDistanceMoved: () => setIsDrawingArrow(true),
        onMove: () => tempArrowMarkerRef.current?.updatePosition(),
        onEnd: (start, end) => { setMenuCoords(pdfToScreenCoords(end, offset.current, scale)); },
        onCancel: () => setIsDrawingArrow(false),
    });

    useEffect(() => {
        setPage(1);
    }, [pdfContent]);

    //Reset zoom and pan values, when pdf size becomes available or either the page or whole pdf changes
    useEffect(() => {
        if (!pdfSize) return
        const containerSize = { width: containerRef.current!.clientWidth, height: containerRef.current!.clientHeight };
        const [newOffset, newScale] = defaultZoomPanValues(containerSize, pdfSize);
        offset.current = newOffset;
        setScale(newScale);
    }, [pdfSize, page, pdfContent]);

    //Set on wheel listener with effect (required for using e.preventDefault)
    useEffect(() => {
        const current = containerRef.current;
        if (current == null) return undefined;

        current.addEventListener('wheel', onMouseWheel, { passive: false })
        return () => current.removeEventListener('wheel', onMouseWheel)
    }, [onMouseWheel])


    async function onZoomPan() {
        canvasRef.current!.style.transform = `translate(${offset.current.x}px, ${offset.current.y}px)`;
        markersRef.current?.updatePositions();
        tempArrowMarkerRef.current?.updatePosition();
    }

    function handleUpdateLocation(page: number, pdfCoords: Point, pdfCoords2?: Point) {
        if (positionSelection == null) return;
        onUpdateLocation?.(positionSelection, page, pdfCoords, pdfCoords2);
    }

    function handleAddMarker(pdfCoords: Point, pdfCoords2?: Point) {
        if (positionSelection == null) return;
        onAddMarker?.(positionSelection, pdfCoords, pdfCoords2);
    }

    const handleMenuClose = () => {
        setMenuCoords(null);
        setIsDrawingArrow(false);
        mouse2DragRef.current = null;
    };


    const showMenu = menuCoords && mouse2DragRef.current != null;

    if (pdfContent == null) {
        return <div className={className}>{isPdfLoading ? 'Loading pdf...' : 'No pdf set'}</div>
    }
    return (
        <>
            <div
                ref={containerRef}
                className={className}
                style={{ ...style, overflow: 'hidden', position: 'relative' }}
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
                onContextMenu={(e) => !isViewMode ? e.preventDefault() : undefined}
            >
                <PdfDisplay
                    canvasRef={canvasRef}
                    pdfContent={pdfContent}
                    page={page}
                    scale={scale}
                    onPdfSizeChange={setPdfSize}
                    setPageCount={setPageCount}
                    onPageRendered={onZoomPan}
                />

                <PositionMarkers
                    ref={markersRef}
                    positions={positions}
                    positionSelection={positionSelection}
                    showSelectedPosNr={showSelectedPosNr}
                    showAllMarkers={showAllMarkers}
                    showAllPosNrs={showAllPosNrs}
                    page={page}
                    pdfToScreenCoords={(pdfCoords) => pdfToContainerCoords(pdfCoords, offset.current, scale)}
                />

                {isDrawingArrow &&
                    <ArrowMarker
                        positionNumber={activePosition?.positionNumber ?? ''}
                        ref={tempArrowMarkerRef}
                        coordsRef={mouse2DragRef}
                        drawMarker={true}
                        drawPositionNumber={false}
                        pdfToScreenCoords={(pdfCoords) => pdfToContainerCoords(pdfCoords, offset.current, scale)}
                    />
                }

                <ButtonBar
                    pageCount={pageCount}
                    page={page}
                    setPage={setPage}
                    onFitToHeight={fitToHeight}
                />
            </div>

            {showMenu && <PdfContextMenu
                page={page}
                screenCoords={menuCoords}
                pdfCoords={mouse2DragRef.current!.start}
                pdfCoords2={isDrawingArrow ? mouse2DragRef.current?.end : undefined}
                activePosition={activePosition}
                onAddPosition={onAddPosition}
                onUpdateLocation={handleUpdateLocation}
                onAddMarker={handleAddMarker}
                onClose={handleMenuClose}
            />}
        </>
    );
}
