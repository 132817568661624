import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { SpcTogglesMap } from '../../../shared/types/spc';
import { defaultSpcToggles } from '../../../shared/utils/defaultSpcToggles';
import { fetchSpcTogglesMap, setSpcToggles } from '../../api/requests';
import { ProtocolFinal } from '../../types/sharedTypeImpl';


interface Props {
    protocol: ProtocolFinal | undefined,
}

export default function useSpcToggles({
    protocol,
}: Props) {

    const { data: loadedSpcToggles, refetch } = useQuery({
        queryKey: ['enabledSpcChecks', protocol?._id],
        queryFn: async () => await fetchSpcTogglesMap(protocol!._id!),
        enabled: !!protocol,
        refetchOnWindowFocus: false,
    });

    const defaultSpcTogglesMap = useMemo(() => {
        if (!protocol) return;
        return Object.fromEntries(
            protocol.positions.map(({ positionNumber }) => [positionNumber, defaultSpcToggles()])
        ) as SpcTogglesMap;
    }, [protocol]);

    const spcTogglesMap = loadedSpcToggles ?? defaultSpcTogglesMap;


    const handleSpcTogglesChange = async (isEnabled: boolean, colIdx: number, positionNumber?: string) => {
        if (!protocol) return;

        await setSpcToggles(protocol._id!, isEnabled, colIdx, positionNumber);
        refetch();
    }

    return { spcTogglesMap, handleSpcTogglesChange };
}
