import { useState } from 'react';
import CmmImportButtons from '../../components/cmm-import/cmmImportButtons';
import TriStateToggle from '../../components/triStateToggle';
import { ProtocolFinal, RecordDraft } from '../../types/sharedTypeImpl';
import { countRecordValuesFilled } from '../../utils/countRecordValuesFilled';
import { evalOrNormalize } from '../../utils/positionInputParse';
import { MeasurementPosition } from './measurementPosition';
import { usePartSelectHotkeys } from './usePartSelectHotkeys';
import { VisualInspection } from './visualInspection';


interface RecordFormPositionsProps {
    protocol: ProtocolFinal;
    record: RecordDraft;
    setRecord: (record: RecordDraft) => void;
    activePositionIdx: number | null;
    setActivePositionIdx: (index: number) => void;
    showSelectedPosNr: boolean;
    setShowSelectedPosNr: (showSelectedPosNr: boolean) => void
    isViewMode: boolean;
    setIsDirty: (isDirty: boolean) => void;
}

export default function RecordFormPositions({
    protocol,
    record,
    setRecord,
    activePositionIdx,
    setActivePositionIdx,
    showSelectedPosNr,
    setShowSelectedPosNr,
    isViewMode,
    setIsDirty,
}: RecordFormPositionsProps) {

    const [partIndex, setPartIndex] = useState(0);

    const listenerDivRef = usePartSelectHotkeys({
        numParts: record.parts.length,
        partIndex,
        setPartIndex: handleSetPartIndexHotkey,
    });


    const handlePositionValueChange = (index: number, value: string | boolean) => {
        const newRecord = structuredClone(record);
        newRecord.parts[partIndex].positionValues[index] = {
            value,
            source: 'manual',
        };
        newRecord.numValuesFilled = countRecordValuesFilled(newRecord);
        setRecord(newRecord);
        setIsDirty(true);
    }

    const handleGeneralInspectionSelect = (selection: boolean) => {
        const newRecord = structuredClone(record);
        newRecord.parts[partIndex].generalVisualInspections = selection;
        newRecord.numValuesFilled = countRecordValuesFilled(newRecord);
        setRecord(newRecord);
        setIsDirty(true);
    }

    const handleAddPart = () => {
        const newRecord = structuredClone(record);
        newRecord.parts.splice(partIndex + 1, 0, {
            positionValues: protocol.positions.map(() => ({ value: '' })),
        });
        setRecord(newRecord);
        setIsDirty(true);
        setPartIndex(partIndex + 1);
    }

    const handleRemovePartClicked = () => {
        if (!window.confirm(`Remove part ${partIndex + 1}?`)) {
            return;
        }

        const newRecord = structuredClone(record);
        newRecord.parts.splice(partIndex, 1);
        newRecord.numValuesFilled = countRecordValuesFilled(newRecord);
        setRecord(newRecord);
        setIsDirty(true);
        if (partIndex === newRecord.parts.length) {
            setPartIndex(partIndex - 1);
        }
    }

    // Eval and normalize the position value before selecting the next part
    function handleSetPartIndexHotkey(newPartIndex: number) {
        if (activePositionIdx == null) return;

        const currentValue = record.parts[partIndex].positionValues[activePositionIdx].value as string;
        const newValue = evalOrNormalize(currentValue);
        handlePositionValueChange(activePositionIdx, newValue);
        setPartIndex(newPartIndex);
    }


    return (
        <div ref={listenerDivRef}>
            <div style={{ border: '1px solid #ccc', borderBottom: 'none', marginTop: '12px', paddingBottom: '2px' }}>
                <div style={{ padding: '4px 2px 0px 2px' }}>
                    {record.measurementType === 'ioqc' &&
                        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>

                            <button
                                style={{ minWidth: '40px' }}
                                type='button'
                                onClick={() => setPartIndex(0)}
                                disabled={partIndex === 0}
                                children='<<'
                            />
                            <button
                                style={{ minWidth: '40px' }}
                                type='button'
                                onClick={() => setPartIndex(partIndex - 1)}
                                disabled={partIndex === 0}
                                children='<'
                            />
                            <span
                                style={{ padding: '0 12px', minWidth: '118px', textAlign: 'center' }}
                                children={`Part ${partIndex + 1} of ${record.parts.length}`}
                            />
                            <button
                                style={{ minWidth: '40px' }}
                                type='button'
                                onClick={() => setPartIndex(partIndex + 1)}
                                disabled={partIndex === record.parts.length - 1}
                                children='>'
                            />
                            <button
                                style={{ minWidth: '40px' }}
                                type='button'
                                onClick={() => setPartIndex(record.parts.length - 1)}
                                disabled={partIndex === record.parts.length - 1}
                                children='>>'
                            />

                            {!isViewMode && <>
                                <button
                                    style={{ marginLeft: '12px' }}
                                    type='button'
                                    onClick={() => handleAddPart()}
                                    children='Add part'
                                />
                                <button
                                    type='button'
                                    onClick={() => handleRemovePartClicked()}
                                    disabled={record.parts.length === 1}
                                    children='Remove part'
                                />
                            </>}
                        </div>
                    }

                    {!isViewMode &&
                        <CmmImportButtons
                            style={{ marginTop: '4px' }}
                            protocol={protocol}
                            record={record}
                            partIndex={partIndex}
                            setRecord={setRecord}
                            setIsDirty={setIsDirty}
                        />
                    }
                </div>
            </div>

            <table className='measurement-form__positions'>
                <thead>
                    <tr>
                        <th style={{ width: '65px' }} />
                        <th>Actual</th>
                        <th>Nominal</th>
                        <th>Upper</th>
                        <th>Lower</th>
                        <th>Tool</th>
                    </tr>
                </thead>
                <tbody>
                    {protocol.positions.map((it, index) =>
                        it.type === 'measurement'
                            ? <MeasurementPosition
                                key={it.positionNumber}
                                index={index}
                                position={it}
                                positionVal={record.parts[partIndex].positionValues[index]}
                                onUpdatePositionValue={handlePositionValueChange}
                                isActive={index == activePositionIdx}
                                isViewMode={isViewMode}
                                onPositionFocus={setActivePositionIdx}
                            />
                            : <VisualInspection
                                key={it.positionNumber}
                                index={index}
                                position={it}
                                positionVal={record.parts[partIndex].positionValues[index]?.value as boolean | undefined}
                                onUpdatePositionValue={handlePositionValueChange}
                                isActive={index == activePositionIdx}
                                isViewMode={isViewMode}
                                onPositionFocus={setActivePositionIdx}
                            />
                    )}
                    <tr>
                        <td />
                        <td>
                            <TriStateToggle
                                selection={record.parts[partIndex].generalVisualInspections}
                                onSelectionChange={handleGeneralInspectionSelect}
                                disabled={isViewMode}
                                trueLabel='OK'
                                falseLabel='NOK'
                            />
                        </td>
                        <td colSpan={4}>
                            General visual inspections
                        </td>
                    </tr>
                </tbody >
            </table >

            <label className='with-checkbox' style={{ marginTop: '8px', marginLeft: '4px' }}>
                Show selected position number
                <input
                    type='checkbox'
                    checked={showSelectedPosNr}
                    onChange={(e) => setShowSelectedPosNr(e.target.checked)}
                />
            </label>
        </div>
    );
}
