import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { sortByDesc } from '../../../shared/utils/arrayUtils';
import { fetchOperations } from '../../api/requests';
import { HandledSrapsFilters } from './handledScrapsFilter';


export function useFetchOperations(
    handledScrapsFilters: HandledSrapsFilters | null,
    someFiltersSet: boolean,
) {
    const endDayPlusOne = useMemo(() => {
        if (!handledScrapsFilters?.endDate) return null;
        const date = new Date(handledScrapsFilters.endDate);
        date.setDate(date.getDate() + 1);
        return date;
    }, [handledScrapsFilters?.endDate]);

    const { data: operations } = useQuery({
        queryKey: ['operations', 'handled', handledScrapsFilters],
        queryFn: () => fetchOperations({
            hasScrapCloseForm: true,
            ...(handledScrapsFilters!.partDisplayName && { partDisplayNameSearch: handledScrapsFilters!.partDisplayName.trim() }),
            ...(handledScrapsFilters!.orderNumber && { orderNumberSearch: handledScrapsFilters!.orderNumber.trim() }),
            ...(handledScrapsFilters!.startDate && { minScrapCloseTime: handledScrapsFilters!.startDate }),
            ...(endDayPlusOne && { maxScrapCloseTime: endDayPlusOne }),
        }),
        enabled: someFiltersSet,
        placeholderData: (prev) => prev,
        select: (operations) => sortByDesc([...operations], it => it.scrapCloseForm!.time),
    });

    return operations;
}
