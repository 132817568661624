import { useEffect, useState } from 'react';
import { TargetType } from '../../../shared/types/comment';
import Button from '../../components/button';
import ModalDialog from '../../components/modalDialog';
import { trpc } from '../../utils/trpc';


interface Props {
    target: string;
    targetType: TargetType;
    displayName: string;
    initialText?: string;
    onSubmited: () => void;
    onClose: () => void;
}

export default function CommentDialog({
    target,
    targetType,
    displayName,
    initialText,
    onSubmited,
    onClose,
}: Props) {

    const [input, setInput] = useState('');

    const setComment = trpc.comments.set.useMutation();


    useEffect(() => {
        setInput(initialText ?? '');
    }, [initialText]);


    const handleSubmit = async () => {
        await setComment.mutateAsync({ target, targetType, text: input });
        onSubmited();
        onClose();
    }


    const targetTypeLabel = targetType === 'equipment' ? 'equipment' : 'part number';
    return (
        <ModalDialog
            isOpen={true}
            frameClassName='modal-frame--fixed_width'
            contentClassName='modal-content--tablet'
            onCancel={onClose}
        >
            <div>Comment for {targetTypeLabel}: {displayName}</div>
            <textarea
                className='product-comment'
                style={{ marginTop: '8px', width: '100%' }}
                value={input}
                rows={3}
                onChange={(e) => setInput(e.target.value)} />

            <div className='dialog--button-bar'>
                <Button
                    className='button--large'
                    onClick={handleSubmit}
                    content='Submit'
                />
            </div>
        </ModalDialog>
    )
}
