import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';


export function usePageSettings() {
    const searchParams = new URLSearchParams(window.location.search);
    const recordIdParam = searchParams.get('id');
    const modeParam = searchParams.get('mode');
    const isViewMode = recordIdParam != null && modeParam !== 'edit';

    const [initialRecordId, setInitialRecordId] = useState<string | null>(null);
    const [initialMode, setInitialMode] = useState<string | null>(null);


    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (initialRecordId === null && recordIdParam) {
            setInitialRecordId(recordIdParam);
        }
        if (initialMode === null && modeParam) {
            setInitialMode(modeParam);
        }
    }, [recordIdParam, modeParam, initialRecordId, initialMode]);

    function handleNavigateRecord(recordId: string) {
        const mode = recordId === initialRecordId ? initialMode : 'view';
        const returnTo = location.state.returnTo;
        navigate(`/record-view?id=${recordId}&mode=${mode}`, { state: { returnTo } });
    }


    return {
        isViewMode,
        initialRecordId: recordIdParam,
        mode: modeParam,
        onNavigateRecord: handleNavigateRecord,
    };
}