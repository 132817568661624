import { sumOf } from '../../shared/utils/arrayUtils';
import { RecordDraft } from '../types/sharedTypeImpl';


export function countRecordValuesFilled(record: RecordDraft): number {
    const numValuesFilled = sumOf(record.parts, part => {
        const positionValuesFilled = sumOf(part.positionValues, positionValue => {
            return positionValue.value != null && positionValue.value !== '' ? 1 : 0;
        });
        return positionValuesFilled + (part.generalVisualInspections != null ? 1 : 0);
    });
    return numValuesFilled;
}
