 
import './polyfillPromiseWithResolvers';

import { Suspense, lazy, useEffect, useMemo, useRef, useState } from 'react';
import { DocumentCallback, PageCallback } from 'react-pdf/dist/cjs/shared/types';
import { Size } from '../../../shared/types/mathTypes';

const Document = lazy(() => import('react-pdf').then(module => ({ default: module.Document })));
const Page = lazy(() => import('react-pdf').then(module => ({ default: module.Page })));


interface Props {
    canvasRef: React.RefObject<HTMLCanvasElement>;
    pdfContent: Uint8Array | null;
    page: number;
    scale: number;
    setPageCount?: (pageCount: number) => void;
    onPdfSizeChange?: (size: Size) => void;
    onPageRendered?: () => void;
}

export default function PdfDisplay({
    canvasRef: onscreenCanvasRef,
    pdfContent,
    page,
    scale,
    setPageCount,
    onPdfSizeChange,
    onPageRendered,
}: Props) {
    const [pdfSize, setPdfSize] = useState<Size | null>(null);
    const offscreenCanvasRef = useRef<HTMLCanvasElement>(null);

    const pdfContentData = useMemo(() => {
        return pdfContent
            ? { data: new Uint8Array(pdfContent) }
            : null;
    }, [pdfContent]);


    // Set web worker dynamically, to reduce bundle size
    useEffect(() => {
        import('react-pdf').then(module => {
            const pdfjs = module.pdfjs;
            pdfjs.GlobalWorkerOptions.workerSrc = new URL(
                'react-pdf/node_modules/pdfjs-dist/legacy/build/pdf.worker.min.mjs',// Use legacy worker becaues of https://github.com/wojtekmaj/react-pdf/issues/1811
                import.meta.url,
            ).toString();
        });
    }, []);

    const onDocumentLoadSuccess = (document: DocumentCallback) => {
        setPageCount?.(document.numPages);
    };

    const onPageLoadSuccess = (pageCallback: PageCallback) => {
        const rotate = pageCallback.rotate === 90 || pageCallback.rotate === 270;
        const newSize: Size = {
            width: rotate ? pageCallback.originalHeight : pageCallback.originalWidth,
            height: rotate ? pageCallback.originalWidth : pageCallback.originalHeight
        };
        if (pdfSize === null || pdfSize.width !== newSize.width || pdfSize.height !== newSize.height) {
            setPdfSize(newSize);
            onPdfSizeChange?.(newSize);
        }
    };

    const onRenderSuccess = (pageCallback: PageCallback) => {
        const rotate = pageCallback.rotate === 90 || pageCallback.rotate === 270;

        // Draw the offscreen canvas to the onscreen canvas
        const onscreenContext = onscreenCanvasRef.current!.getContext('2d')!;
        onscreenCanvasRef.current!.width = rotate ? pageCallback.height : pageCallback.width;
        onscreenCanvasRef.current!.height = rotate ? pageCallback.width : pageCallback.height;
        onscreenContext.drawImage(offscreenCanvasRef.current!, 0, 0);

        onPageRendered?.();
    };

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <div style={{ display: 'none' }} >
                <Document
                    file={pdfContentData}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <Page
                        canvasRef={offscreenCanvasRef}
                        pageNumber={page}
                        scale={scale}
                        devicePixelRatio={1}
                        onLoadSuccess={onPageLoadSuccess}
                        onRenderSuccess={onRenderSuccess}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                    />
                </Document>
            </div>

            <canvas
                ref={onscreenCanvasRef}
                style={{ display: 'inline-block' }}
            />
        </Suspense>
    )
}
