import Button from './button';


export interface TriStateToggleProps {
    style?: React.CSSProperties;
    selection: boolean | undefined;
    trueLabel?: string;
    falseLabel?: string;
    onSelectionChange?: (selection: boolean) => void;
    onFocus?: () => void;
    disabled?: boolean;
    readonly?: boolean;
}

export default function TriStateToggle({
    style,
    selection,
    trueLabel = 'Yes',
    falseLabel = 'No',
    onSelectionChange,
    onFocus,
    disabled,
    readonly,
}: TriStateToggleProps) {

    function handleSelectYes() {
        if (selection === true || readonly) return;
        onSelectionChange?.(true);
    }

    function handleSelectNo() {
        if (selection === false || readonly) return;
        onSelectionChange?.(false);
    }

    return (
        <span className='tristate-toggle' style={style}>
            <Button
                className={`toggle-option--yes ${selection === true ? 'selected' : ''}`}
                onClick={handleSelectYes}
                onFocus={onFocus}
                disabled={disabled}
                content={trueLabel}
            />
            <Button
                className={`toggle-option--no ${selection === false ? 'selected' : ''}`}
                onClick={handleSelectNo}
                onFocus={onFocus}
                disabled={disabled}
                content={falseLabel}
            />
        </span>
    );
}
