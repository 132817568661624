import { useEffect, useRef, useState } from 'react';


export function useDebouncedState<T>(
    initialValue: T,
    delay: number,
    onValueChanged?: (value: T) => void
) {
    const [runningValue, setRunningValue] = useState<T>(initialValue);
    const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        // Clear previous timeout
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        if (runningValue === debouncedValue) {
            return;
        }

        // Set new timeout
        timeoutRef.current = setTimeout(() => {
            setDebouncedValue(runningValue);
            if (onValueChanged) {
                onValueChanged(runningValue);
            }
        }, delay);

        // Cleanup on unmount or when dependencies change
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [runningValue, debouncedValue, delay, onValueChanged]);

    const setValueImmediate = (value: T) => {
        setRunningValue(value);
        setDebouncedValue(value);

        if (onValueChanged) {
            onValueChanged(value);
        }
    };

    return {
        runningValue,
        debouncedValue,
        setValueDebounced: setRunningValue,
        setValueImmediate,
    }
}
