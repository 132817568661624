import { sortBy } from '../../../shared/utils/arrayUtils';
import { RecordFinal, RecordWithProtocol } from '../../types/sharedTypeImpl';
import { isOutsideTolerance } from '../../utils/positionSpec';
import { PreviewTable, RecordRow } from './types';


export default function createPreviewTable(_records: RecordWithProtocol[]): PreviewTable {
    if (_records.length === 0) throw new Error('No records to preview');
    const records = sortBy([..._records], record => record.measurementStart);
    const latestProtocol = records.at(-1)!.protocol;

    const positionNumColIndices = new Map<string, number>();
    let columnIdx = 0;
    latestProtocol.positions.forEach(position => {
        positionNumColIndices.set(position.positionNumber, columnIdx++);
    });

    //Get position data from protocol
    const positionNumbers: string[] = [];
    const nominalValues: string[] = [];
    const lowerTolerances: string[] = [];
    const upperTolerances: string[] = [];
    latestProtocol.positions.forEach(position => {
        positionNumbers.push(position.positionNumber);
        nominalValues.push(position.nominal ?? '');
        lowerTolerances.push(position.lowerTolerance ?? '');
        upperTolerances.push(position.upperTolerance ?? '');
    });

    //Get record rows
    const recordRows = records.flatMap(record => {
        return record.parts.map((part, partIndex) => {
            const generalVisualInspections = part.generalVisualInspections;
            const row: RecordRow = {
                includeInReport: true,
                partCount: record.measurementType === 'production'
                    ? (record as RecordFinal).partCount!
                    : partIndex + 1,
                isPartNumberHighlighted: false,
                values: Array.from({ length: positionNumbers.length }, () => ({ value: '', isHighlighted: false })),
                generalVisualInspection: generalVisualInspections == null ? ''
                    : generalVisualInspections === true ? 'OK' : 'NOK',
                isGeneralVisualInspectionHighlighted: generalVisualInspections === false,
                measurementStart: record.measurementStart!,
                comment: record.comment,
            };
            if (generalVisualInspections === false) row.includeInReport = false;

            //Get actual values and highlight if they are out of tolerance; update includeInReport flag
            for (const [idx, position] of record.protocol.positions.entries()) {
                const columnIdx = positionNumColIndices.get(position.positionNumber);
                const value = part.positionValues[idx]?.value;

                if (columnIdx == null || value === '' || value == null) continue;
                if (position.type === 'measurement') {
                    const isOutOfSpec = isOutsideTolerance(position, value);

                    row.values[columnIdx].value = value as string;
                    row.values[columnIdx].isHighlighted = isOutOfSpec;
                    if (isOutOfSpec) row.includeInReport = false;
                } else {
                    row.values[columnIdx].value = value ? 'OK' : 'NOK';
                    row.values[columnIdx].isHighlighted = !value;
                    if (!value) row.includeInReport = false;
                }
            }

            return row;
        });
    });

    //Highlight part numbers, if they are not in ascending order
    for (let i = 1; i < recordRows.length; i++) {
        const prevRow = recordRows[i - 1];
        const currRow = recordRows[i];
        if (prevRow.partCount > currRow.partCount) {
            currRow.isPartNumberHighlighted = true;
        }
    }

    return {
        positionNumbers,
        nominalValues,
        lowerTolerances,
        upperTolerances,
        recordRows,
    };
}
