
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { OpSubFormProps, OpSubFormRef } from './operationSubForm';


export const Comment = forwardRef<OpSubFormRef, OpSubFormProps>(({
    operation,
    onOperationChange,
}: OpSubFormProps, ref) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);

    useImperativeHandle(ref, () => ({
        validateWarnAndGet: (result) => {
            result.comment = operation.comment;
            return true;
        }
    }));

    return (
        <div className='dialog-form__field--with-margins'>
            <div>Comment</div>
            <textarea
                ref={inputRef}
                className='dialog-form__input'
                rows={3}
                required={false}
                value={operation.comment}
                onChange={(e) => onOperationChange({ ...operation, comment: e.target.value })}
            />
        </div>
    );
});
