import { Dispatch, SetStateAction } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ClearFiltersButton } from '../clearFiltersButton';

export interface HandledSrapsFilters {
    partDisplayName?: string;
    orderNumber?: string;
    startDate?: Date | null;
    endDate?: Date | null;
}


interface Props {
    filters: HandledSrapsFilters | null;
    setFilters: Dispatch<SetStateAction<HandledSrapsFilters | null>>;
}

export function HandledScrapsFilter({
    filters,
    setFilters,
}: Props) {

    function handlePartNumberChange(e: React.ChangeEvent<HTMLInputElement>) {
        setFilters((prevFilters) => ({
            ...prevFilters,
            partDisplayName: e.target.value,
        }));
    }

    function handleOrderNumberChange(e: React.ChangeEvent<HTMLInputElement>) {
        setFilters((prevFilters) => ({
            ...prevFilters!,
            orderNumber: e.target.value,
        }));
    }

    function handleStartDateChange(date: Date | null) {
        setFilters((prevFilters) => ({
            ...prevFilters,
            startDate: date,
        }));
    }

    function handleEndDateChange(date: Date | null) {
        setFilters((prevFilters) => ({
            ...prevFilters!,
            endDate: date,
        }));
    }

    function resetFields() {
        setFilters({
            partDisplayName: '',
            orderNumber: '',
            startDate: null,
            endDate: null,
        });
    }


    return (
        <div className='flex--horz' style={{ gap: '16px', }}>
            <label>
                <span style={{ marginRight: '0.4em' }}>Part number:</span>
                <input
                    style={{ marginLeft: '0.4em' }}
                    type='text'
                    value={filters?.partDisplayName || ''}
                    onChange={handlePartNumberChange}
                />
            </label>

            <label>
                <span style={{ marginRight: '0.4em' }}>Order number:</span>
                <input
                    style={{ marginLeft: '0.4em' }}
                    type='text'
                    value={filters?.orderNumber || ''}
                    onChange={handleOrderNumberChange}
                />
            </label>

            <label>
                <span style={{ marginRight: '0.4em' }}>Start date:</span>
                <DatePicker
                    selected={filters?.startDate || null}
                    onChange={handleStartDateChange}
                    dateFormat='dd.MM.yyyy'
                    className='date-picker'
                />
            </label>

            <label>
                <span style={{ marginRight: '0.4em' }}>End date:</span>
                <DatePicker
                    selected={filters?.endDate || null}
                    onChange={handleEndDateChange}
                    dateFormat='dd.MM.yyyy'
                    className='date-picker'
                />
            </label>

            <ClearFiltersButton onClick={() => resetFields()} />
        </div >
    )
}
