

export const NUM_MEASUREMENT_DELAY_BUCKETS = 4;

export function getMeasurementDelayBucket(delayMillis: number): number {
    if (delayMillis <= 15 * 60_000) {
        return 0;
    } else if (delayMillis <= 30 * 60_000) {
        return 1;
    } else if (delayMillis <= 60 * 60_000) {
        return 2;
    } else {
        return 3;
    }
}

export function getMeasurementDelayBucketLabel(bucket: number): string {
    switch (bucket) {
        case 0:
            return '< 15 min';
        case 1:
            return '15-30 min';
        case 2:
            return '30-60 min';
        case 3:
            return '> 60 min';
        default:
            throw new Error('Invalid bucket');
    }
}

export function getMeasurementDelayBucketColor(bucket: number): string {
    switch (bucket) {
        case 0:
            return '#36c22f';
        case 1:
            return '#facc15';
        case 2:
            return '#fb8520';
        case 3:
            return '#D64550';
        default:
            throw new Error('Invalid bucket');
    }
}