import { useState } from 'react';
import { CmmReportHeader } from '../../shared/types/cmmReport';
import { sortByDesc } from '../../shared/utils/arrayUtils';
import { fetchCmmReportFile, fetchCmmReportHeaders } from '../api/requests';
import { testImportCmmFiles } from '../services/cmm-import/testImportCmmFiles';
import { TestImportFile } from '../services/cmm-import/types';
import { ProtocolDraft } from '../types/sharedTypeImpl';

interface FilesDialogState {
    files: CmmReportHeader[];
    initialFilter: string;
}


interface Props {
    protocol: ProtocolDraft;
}

export function useTestCmmImport({
    protocol,
}: Props) {
    const [isManualImportClicked, setIsManualImportClicked] = useState(false);
    const [filesDialogState, setFilesDialogState] = useState<FilesDialogState | null>(null);
    const [positionsDialogState, setPositionsDialogState] = useState<TestImportFile | null>(null);


    async function handleManualImportClicked() {
        try {
            setIsManualImportClicked(true);
            const cmmReports = await fetchCmmReportHeaders();
            sortByDesc(cmmReports, it => it.lastModified);
            setFilesDialogState({
                files: cmmReports,
                initialFilter: protocol.partName
            });
        } catch (error) {
            alert('Failed to fetch CMM reports');
        } finally {
            setIsManualImportClicked(false);
        }
    }

    async function handleFilesDialogSubmit(names: string[]) {
        if (names.length === 0) {
            setFilesDialogState(null);
            return;
        }

        try {
            const files = await Promise.all(names.map(fetchCmmReportFile));
            const result = await testImportCmmFiles(protocol, files);
            setPositionsDialogState(result);
            setFilesDialogState(null);
        } catch (error) {
            alert('Failed to fetch CMM reports');
        }
    }

    async function handleLocalFilesSelected(files: File[]) {
        const result = await testImportCmmFiles(protocol, files);
        setPositionsDialogState(result);
    }


    return {
        isManualImportDisabled: isManualImportClicked,
        filesDialogState,
        positionsDialogState,
        handleManualImportClicked,
        handleFilesDialogSubmit,
        handleLocalFilesSelected,
        handleFilesDialogClose: () => setFilesDialogState(null),
        handlePositionsDialogClose: () => setPositionsDialogState(null),
    }
}
