import { useState } from 'react';
import { ClearFiltersButton } from '../../components/clearFiltersButton';
import LinkButton from '../../components/linkButton';
import NavBar from '../../components/navBar';
import { PageTitle } from '../../components/pageTitle';
import RecordsExportDialog from '../../components/records-export/recordsExportDialog';
import { useSearchParamsSearch } from '../../hooks/useSearchParamsSearch';
import { Record } from '../../types/sharedTypeImpl';
import { trpc, trpcClient } from '../../utils/trpc';
import RecordListTable from './recordListTable';


export default function RecordList() {
    const defaultLimit = 200;
    const [maxRecords, setMaxRecords] = useState(defaultLimit);
    const [onlyWithIssues, setOnlyWithIssues] = useState(false);
    const [pendingExportRecord, setPendingExportRecord] = useState<Record | null>(null);

    const { searchTextInput, searchText, setSearchTextDebounced, setSearchTextImmediate } = useSearchParamsSearch();

    const { data: records, error, isLoading, refetch } = trpc.records.getRecords.useQuery({
        measurementType: 'production',
        search: searchText,
        limit: maxRecords,
        onlyWithIssues,
    }, {
        placeholderData: (prev) => prev,
    });


    const handleClearFilters = () => {
        setSearchTextImmediate('');
        setOnlyWithIssues(false);
    }

    const handleRecordDelete = async (record: Record) => {
        const description = `${record.equipmentDisplayName} - ${record.protocolPartDisplayName}`;
        if (window.confirm(`Are you sure you want to delete the following record?\n${description}`)) {
            try {
                await trpcClient.records.deleteRecord.mutate({ recordId: record._id, deleteMeasurement: true });
                refetch();
            } catch (error) {
                alert('Failed to delete record');
            }
        }
    }

    const handleRecordsExport = async function (record: Record) {
        if (!record.orderNumber) {
            alert('Cannot export records without an order number');
        } else {
            setPendingExportRecord(record);
        }
    };

    const handleLoadMore = () => {
        setMaxRecords(maxRecords + defaultLimit);
    }


    const showLoadMore = records != null && records?.length >= maxRecords;

    return (
        <>
            <PageTitle title='Records' />
            <NavBar />
            <div className='content' style={{ width: '1480px' }}>
                <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                    <label className='with-checkbox'>
                        Show issues only
                        <input
                            style={{ marginLeft: '8px' }}
                            type='checkbox'
                            checked={onlyWithIssues}
                            onChange={e => setOnlyWithIssues(e.target.checked)}
                        />
                    </label>
                    <input
                        style={{ marginLeft: '18px' }}
                        type='text'
                        placeholder='Search'
                        value={searchTextInput}
                        onChange={e => setSearchTextDebounced(e.target.value)}
                    />
                    <ClearFiltersButton onClick={handleClearFilters} />
                </div >

                <RecordListTable
                    isLoading={isLoading}
                    error={error}
                    records={records}
                    onRecordDelete={handleRecordDelete}
                    onRecordsExport={handleRecordsExport}
                    onSearchTextChange={setSearchTextImmediate}
                />

                <LinkButton
                    style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: '20px', visibility: showLoadMore ? 'visible' : 'hidden' }}
                    onClick={handleLoadMore}
                    children='Load more'
                />
            </div>

            {pendingExportRecord && <RecordsExportDialog
                record={pendingExportRecord}
                onClose={() => setPendingExportRecord(null)}
            />}
        </>
    );
}
