import { useMemo } from 'react';
import { fetchProtocol } from '../../api/requests';
import useFetchOnce from '../../hooks/useFetchOnce';
import { Record, RecordWithProtocol } from '../../types/sharedTypeImpl';
import { trpc } from '../../utils/trpc';


interface Props {
    record: Record,
}

export function useRecordsSelect({
    record,
}: Props) {

    if (record.measurementType === 'production' && !record.orderNumber) {
        throw new Error('orderNumber must be set if recordType is production');
    }

    const recordsFetch = trpc.records.getRecordsWithProtocols.useQuery(
        { orderNumber: record.orderNumber, isDraft: false, }, {
        enabled: record.measurementType === 'production',
        refetchOnWindowFocus: false,
    });

    const protocolFetch = useFetchOnce({
        queryKey: ['protocol', record.protocolId],
        queryFn: async () => await fetchProtocol(record.protocolId!),
        enabled: record.measurementType === 'ioqc'
    });

    const ioqcRecordsArray = useMemo(() => {
        if (record.measurementType !== 'ioqc') return undefined;
        if (!protocolFetch.data || protocolFetch.data._id !== record.protocolId) return undefined;
        const recordWithProtocol = { ...record, protocol: protocolFetch.data } as RecordWithProtocol;
        return [recordWithProtocol];
    }, [record, protocolFetch.data]);

    const records = record.measurementType === 'production' ? recordsFetch.data : ioqcRecordsArray;
    const isLoading = record.measurementType === 'production' ? recordsFetch.isLoading : protocolFetch.isLoading;

    return { records, isLoading };
}
