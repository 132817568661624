

const DelaysPeriodOptions = [
    { key: 'weeks', label: 'Weeks' },
    { key: 'months', label: 'Months' },
] as const;
export type DelaysPeriod = (typeof DelaysPeriodOptions)[number]['key'];


interface Props {
    delaysPeriod: DelaysPeriod;
    setDelaysPeriod: (delaysPeriod: DelaysPeriod) => void;
}

export function DelaysGraphControls({
    delaysPeriod,
    setDelaysPeriod,
}: Props) {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <div style={{ fontSize: '24px', marginLeft: '12px', marginBottom: '10px', fontWeight: 'bold' }}>
                Time period
            </div>
            {DelaysPeriodOptions.map(({ key, label }) => (
                <label key={key} style={{ margin: '0 10px' }}>
                    <input
                        type='radio'
                        name='delaysPeriod'
                        value={key}
                        checked={delaysPeriod === key}
                        onChange={() => setDelaysPeriod(key)}
                    />
                    {label}
                </label>
            ))}
        </div>
    );
}
