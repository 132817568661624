import { getMeasurementDelayBucket, getMeasurementDelayBucketColor } from '../../../shared/services/measurementDelayBuckets';
import { getWorkTimeInPeriod } from '../../../shared/services/shifts/workTimeInPeriod';


interface Props {
    target: Date | undefined;
}

export function DelayStatusIndicator({
    target,
}: Props) {
    if (!target) return null;

    const timeUntilTarget = target.getTime() - Date.now();
    const isVisible = timeUntilTarget < 15 * 60_000; // 15 minutes

    const delayDuringWorkHours = getWorkTimeInPeriod(target, new Date());
    const delayBucket = getMeasurementDelayBucket(delayDuringWorkHours);
    const backgroundColor = getMeasurementDelayBucketColor(delayBucket);

    if (!isVisible) return null;
    return (
        <div
            className={`delay-status-indicator`}
            style={{ backgroundColor }}
        />
    );
}
