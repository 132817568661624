import { skipToken, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { sortBy } from '../../../shared/utils/arrayUtils';
import { fetchProtocols, fetchSessionAt } from '../../api/requests';
import { Protocol, Record, RecordWithProtocol } from '../../types/sharedTypeImpl';
import { trpc } from '../../utils/trpc';


interface Props {
    record: Record | undefined;
    protocol: Protocol | undefined;
    limit?: number;
}

export function useFetchPrevRecords({
    record,
    protocol,
    limit,
}: Props) {

    const { data: protocols } = useQuery({
        queryKey: ['protocols', 'partName', protocol?.partName],
        queryFn: async () => await fetchProtocols({ partName: protocol?.partName }),
        enabled: protocol?.partName !== undefined,
    });

    const { data: session } = useQuery({
        queryKey: ['session-at', record?.equipment, record?.measurementStart],
        queryFn: async () => await fetchSessionAt(record!.equipment!, record!.measurementStart!),
        enabled: !!record,
    });

    const { data: recentRecordsFetch } = trpc.records.getRecords.useQuery(
        record && protocols && record.measurementType === 'production' && session ? {
            equipment: record.equipment!,
            protocolIdOptions: protocols.map(it => it._id!),
            measurementStart: { start: session.start, end: record.measurementStart },
            sort: { measurementStart: -1 },
            limit,
        } : skipToken,
    );

    const { data: nextRecordFetch } = trpc.records.getRecords.useQuery(
        record && protocols && record.measurementType === 'production' && session ? {
            equipment: record.equipment!,
            protocolIdOptions: protocols.map(it => it._id!),
            measurementStart: { start: record.measurementStart, end: session.end ?? undefined },
            sort: { measurementStart: 1 },
            limit: 2,
        } : skipToken,
    );

    const recentRecordsFinal = useMemo(() => {
        if (!protocols || !recentRecordsFetch) return undefined;
        const protocolMap = new Map(protocols.map(it => [it._id, it]));
        const sorted = sortBy([...recentRecordsFetch], it => it.measurementStart);
        return sorted.map(it => ({
            ...it,
            protocol: protocolMap.get(it.protocolId)!,
        })) as RecordWithProtocol[];
    }, [recentRecordsFetch, protocols]);


    return {
        prevRecord: recentRecordsFinal?.at(-1),
        nextRecord: nextRecordFetch?.[1],
        recentRecords: recentRecordsFinal,
    }
}
