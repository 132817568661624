import { Link } from 'react-router-dom';
import { isHelpMeStatus } from '../../../shared/services/measurement-interval/isHelpMeStatus';
import { requiresMeasuring } from '../../../shared/services/measurement-interval/requiresMeasuring';
import Button from '../../components/button';
import { MeasurementIntervalState } from '../../types/sharedTypeImpl';
import { formatBatchTimeRemaining, formatCycleTime, formatDuration } from '../../utils/fieldFormats';
import { openLinkInNewTab } from '../../utils/navigation';
import { getSessionTimeRemainingStyle } from '../../utils/styles';
import { DelayStatusIndicator } from './delayStatusIndicator';


interface Props {
    data: MeasurementIntervalState[],
    onPartCountClicked: (row: MeasurementIntervalState) => void,
    onMeasureClicked: (row: MeasurementIntervalState) => void,
}

export default function MeasurementIntervalTable({
    data,
    onPartCountClicked,
    onMeasureClicked,
}: Props) {

    const cycleTimeHelp = 'Latest stable cycle time (5 parts produced in a row with the same time)';


    return (
        <table>
            <thead>
                <tr>
                    <th>Equipment name</th>
                    <th>Part number</th>
                    <th>Order number</th>
                    <th>Parts count</th>
                    <th>Measure interval</th>
                    <th title={cycleTimeHelp}>Cycle time</th>
                    <th>Batch time</th>
                    <th>Interval time</th>
                    <th>Last measured</th>
                    <th>Status</th>
                    <th />
                </tr>
            </thead>
            <tbody>
                {data?.map(row =>
                    <tr key={row.equipment} className={(isHelpMeStatus(row)) ? 'highlight--issue' : ''}>
                        <EquipmentName row={row} />
                        <ProductName row={row} />
                        <OrderNumber row={row} />
                        <PartsCount row={row} onPartCountClicked={onPartCountClicked} />
                        <MeasureInterval row={row} />
                        <CycleTime row={row} />
                        <SessionTime row={row} />
                        <IntervalTime row={row} />
                        <LastMeasured row={row} />
                        <Status row={row} />
                        <MeasureButton row={row} onMeasureClicked={onMeasureClicked} />
                    </tr>
                )}
            </tbody>
        </table>
    )
}


function EquipmentName({ row }: { row: MeasurementIntervalState }) {
    return (
        <td className='textAlignLeft'>
            <Link className='inheritColor' to={`/machine-log#equipment=${row.equipment}`}>
                {row.displayName}
            </Link>
        </td>
    );
}

function ProductName({ row }: { row: MeasurementIntervalState }) {
    return (
        <td className='textAlignLeft' title={row.product}>
            {row.partDisplayName}
        </td>
    );
}

function OrderNumber({ row }: { row: MeasurementIntervalState }) {
    return (
        <td className='textAlignLeft'>
            {row.orderNumber}
        </td>
    );
}

function PartsCount({
    row,
    onPartCountClicked
}: {
    row: MeasurementIntervalState,
    onPartCountClicked: (row: MeasurementIntervalState) => void
}) {
    const showcurrentPartCount = row.partCount != null;
    const showTargetQuantity = showcurrentPartCount && row.targetQuantity != null;
    const isTargetReached = showTargetQuantity && (row.partCount ?? 0) >= (row.targetQuantity ?? 0);

    const tdClassName = showcurrentPartCount ? 'editable' : '';
    const currentPartCountStyle = isTargetReached ? { color: 'red' } : {};
    return (
        <td className={tdClassName} onClick={e => onPartCountClicked(row)}>
            {showcurrentPartCount && <span style={currentPartCountStyle}>{row.partCount}</span>}
            {showTargetQuantity && <span> / {row.targetQuantity}</span>}
        </td>
    );
}

function MeasureInterval({ row }: { row: MeasurementIntervalState }) {
    const showMeasureInterval = row.measureIntervalCount != null && row.measureIntervalTarget != null;

    return (
        <td>{showMeasureInterval && `${row.measureIntervalCount} / ${row.measureIntervalTarget}`}</td>
    );
}

function CycleTime({ row }: { row: MeasurementIntervalState }) {
    return (
        <td>{row.cycleTime != null && formatCycleTime(row.cycleTime)}</td>
    );
}

function SessionTime({ row }: { row: MeasurementIntervalState }) {
    const sessionTimeRemaining = row.sessionEndPrediction ? +row.sessionEndPrediction - +new Date() : null;
    const cellStyle = getSessionTimeRemainingStyle(sessionTimeRemaining, row.partCount, row.targetQuantity, row.inOfflineMode);

    return (
        <td style={cellStyle}>
            {sessionTimeRemaining != null && formatBatchTimeRemaining(sessionTimeRemaining)}
        </td>
    );
}

function IntervalTime({ row }: { row: MeasurementIntervalState }) {
    const intervalTimeRemaining = row.intervalPrediction ? +row.intervalPrediction - +new Date() : null;

    let intervalTimeStyle: React.CSSProperties = {};
    const requiresMeasuring2 = requiresMeasuring(row);
    if (requiresMeasuring2) {
        intervalTimeStyle = { color: 'red' };
    }

    return (
        <td style={intervalTimeStyle}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', padding: '0 8px' }}>
                {intervalTimeRemaining && formatDuration(intervalTimeRemaining, true, false)}
                <DelayStatusIndicator target={row.intervalPrediction} />
            </div>
        </td>
    );
}

function LastMeasured({ row }: { row: MeasurementIntervalState }) {
    return (
        <td>{
            row.lastMeasureTime?.toLocaleTimeString([], {
                weekday: 'short', hour: '2-digit', hour12: false, minute: '2-digit'
            })
        }</td>
    );
}

function Status({ row }: { row: MeasurementIntervalState }) {
    let statusText: string | null = null;
    if (row.inOfflineMode) {
        statusText = 'MT-LINKi not available';
    } else if (row.isSetupComplete == false) {
        statusText = 'In setup';
    } else if (row.hasDisruption) {
        let disruptionDurationText = '';
        if (row.disruptionStatusStart) {
            const startTime = row.disruptionStatusStart;
            const duration = (new Date().getTime() - startTime.getTime());
            const durationFormatted = formatDuration(duration, false, true);
            disruptionDurationText = `(${durationFormatted})`
        }
        const mainStatusText = row.fixInProgress ? 'Fix in progress' : 'Help me!';
        statusText = `${mainStatusText} ${disruptionDurationText}`;
    } else if (row.requiresConfirmMeasure) {
        statusText = 'Confirmation measurement';
    } else if (row.product && (!row.measureIntervalTarget || row.measureIntervalTarget <= 1)) {
        statusText = 'Interval is not set';
    }

    return (
        <td>{statusText}</td>
    );
}

function MeasureButton({
    row,
    onMeasureClicked
}: {
    row: MeasurementIntervalState,
    onMeasureClicked: (row: MeasurementIntervalState) => void
}) {

    return (
        <td>
            {row.ongoingRecordId == null
                ? <Button
                    onClick={() => onMeasureClicked(row)}
                    content='Measure'
                />
                : <Button
                    className='measurement-continue'
                    onClick={() => openLinkInNewTab(`record-view`, { id: row.ongoingRecordId, mode: 'edit' })}
                    content='Continue'
                />
            }
        </td>
    );
}
