import { Protocol, RecordDraft } from '../../types/sharedTypeImpl';
import getWayOutOfSpecValues from './wayOutOfSpec';


/* Validate the record and alert the user if there are any issues */
export function validateRecordForm(record: RecordDraft, protocol: Protocol): boolean {
    const isMeasurementInterval = record.measurementType === 'production';

    // Check if all actual values are valid numbers; fail otherwise
    for (const part of record.parts) {
        for (const [index, value] of part.positionValues.entries()) {
            const position = protocol.positions[index];
            if (position.type === 'measurement' && (value.value as string)?.length > 0) {
                const numberValue = +value.value;
                if (numberValue < 0 || isNaN(numberValue)) {
                    alert(`Actual value is invalid for position ${position.positionNumber}`);
                    return false;
                }
            }
        }
    }
    if (record.targetQuantity && isNaN(+record.targetQuantity)) {
        alert('Target quantity is not a valid number');
        return false;
    }
    if (record.partCount && isNaN(+record.partCount)) {
        alert('Part count is not a valid number');
        return false;
    }

    // Check if all required fields are filled; fail otherwise
    if (isMeasurementInterval && !record.partCount) {
        alert(`Part count must be set`);
        return false;
    }

    // Check if all expected fields are filled; ask for confirmation if not
    if (isMeasurementInterval && !record.orderNumber) {
        const result = confirm(`Order number is not set. Continue anyway?`);
        if (!result) return false;
    }
    if (isMeasurementInterval && !record.targetQuantity) {
        const result = confirm(`Target quantity is not set. Continue anyway?`);
        if (!result) return false;
    }

    // Check if all actual values are set; ask for confirmation if not
    let emptyPosition = null;
    for (const part of record.parts) {
        for (const [index, value] of part.positionValues.entries()) {
            const position = protocol.positions[index];

            if (position.toolType === 'CMM' || position.toolType === 'VMM') {
                continue;
            }
            if (position.type === 'measurement') {
                if (!value.value) {
                    emptyPosition = position.positionNumber;
                }
            } else if (position.type === 'visualInspection') {
                if (value.value == null) {
                    emptyPosition = position.positionNumber;
                }
            } else {
                throw new Error('Invalid position type');
            }
        }
    }
    if (emptyPosition != null) {
        const result = confirm(`Actual value for position ${emptyPosition} is empty. Continue anyway?`);
        if (!result) return false;
    }

    // Check if all general visual inspections are set; ask for confirmation
    let hasEmptyGeneralVisualInspection = false;
    for (const part of record.parts) {
        hasEmptyGeneralVisualInspection &&= part.generalVisualInspections == null;
    }
    if (hasEmptyGeneralVisualInspection) {
        const result = confirm(`General visual inspection has not been set. Continue anyway?`);
        if (!result) return false;
    }

    // Find and alert the user of any positions that are way out of spec
    for (const part of record.parts) {
        const wayOutOfSpecValues = getWayOutOfSpecValues(protocol, part.positionValues);
        for (const { position, positionValue } of wayOutOfSpecValues) {
            const result = confirm(`Position ${position.positionNumber} is way out of spec (value: ${positionValue.value}, nominal: ${position.nominal}). Continue anyway?`);
            if (!result) return false;
        }
    }

    return true;
}
