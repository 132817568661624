import { Position, ProtocolBase } from '../../types/protocol.js';
import { RecordWithProtocolBase } from '../../types/recordWithProtocol.js';
import { SpcResultsFlags, SpcTogglesPos } from '../../types/spc.js';
import { NUM_SPC_CHECKS } from '../../utils/spcConstants.js';
import { getPositionValues } from '../getPositionValues.js';
import { spcCheckPosition } from './spcCheckPosition.js';


export interface AggregatedSpcCheck {
    position: Position;
    checks: SpcResultsFlags;
}

/* Returns a 2D array of SPC check results per position and per SPC rule, aggregated over all measurements */
export function spcChecksAggregated<ObjectIdType>(
    protocol: ProtocolBase<ObjectIdType>,
    records: RecordWithProtocolBase<ObjectIdType>[],
    numLatest: number | null = null,
): AggregatedSpcCheck[] {
    const valuesPerPosition = protocol.positions
        .filter(position => position.type === 'measurement')
        .map(position => ({ position, values: getPositionValues(position, records, false) }));

    const allEnabled = Array.from({ length: NUM_SPC_CHECKS }, () => true) as SpcTogglesPos;
    const spcChecksTable = valuesPerPosition.map(({ position, values }, index) => {
        // Checks all measurements of a single position
        const checks = spcCheckPosition(values, position, allEnabled);
        const latestChecks = numLatest != null ? checks.slice(-numLatest) : checks;

        // Combine the checks per spc rule
        const aggregatedChecks = [...Array(NUM_SPC_CHECKS)].map(
            (_, idx) => {
                const anyTrue = latestChecks.some(check => check.checkResults[idx]);
                const allNull = !anyTrue && latestChecks.every(check => check.checkResults[idx] === null);
                return allNull ? null : anyTrue;
            }) as SpcResultsFlags;

        return { position, checks: aggregatedChecks };
    });

    return spcChecksTable;
}
