

type CellData = string | number | boolean | null | undefined;

export async function tableToXlsx(table: CellData[][]): Promise<Blob> {
    const xlsx = await import('node-xlsx');
    const buffer = xlsx.build([{ name: 'Sheet1', data: table, options: {} }]);
    return new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
}
