import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import { createRef, useEffect, useState } from 'react';
import { ScrapCloseForm } from '../../shared/types/operation';
import { updateOperation } from '../api/requests';
import { Operation } from '../types/sharedTypeImpl';
import Input from './input';
import ModalSubmitDialog from './modalSubmitDialog';
import OperatorSelect from './operatorSelect';
import ProtocolPdfDialog from './protocolPdfDialog';


interface Props {
    isOpen: boolean;
    operation: Operation;
    onClose: () => void;
    onSuccess: (operation: Operation) => void;
}

interface MutationParams {
    operation: Operation;
    releaseScrapLabel: boolean;
}

export default function ScrapCloseDialog({
    isOpen,
    operation,
    onClose,
    onSuccess,
}: Props) {

    const [confirmedScrap, setConfirmedScrap] = useState('0');
    const [releaseLabel, setReleaseLabel] = useState(false);
    const [isReleaseLabelReadonly, setIsReleaseLabelReadonly] = useState(false);
    const [scrapReason, setScrapReason] = useState<string | null>(null);
    const [comment, setComment] = useState('');
    const [timeSpentHours, setTimeSpentHours] = useState<number | null>(null);
    const [timeSpentMinutes, setTimeSpentMinutes] = useState<number | null>(null);
    const [operator, setOperator] = useState('');
    const [showPdfDialog, setShowPdfDialog] = useState(false);
    const formRef = createRef<HTMLFormElement>();


    // Set initial values from operation, if editing existing scrap close form
    useEffect(() => {
        if (operation.scrapCloseForm && isOpen) {
            setConfirmedScrap(operation.scrapCloseForm.addedConfirmedScrap.toString());
            setScrapReason(operation.scrapCloseForm.scrapReason ?? null);
            setComment(operation.scrapCloseForm.comment);
            if (operation.scrapCloseForm.timeSpent != null) {
                setTimeSpentHours(Math.floor((operation.scrapCloseForm.timeSpent) / 60_000 / 60));
                setTimeSpentMinutes(Math.floor((operation.scrapCloseForm.timeSpent) / 60_000 % 60));
            }
            setOperator(operation.scrapCloseForm.operator);
        }
    }, [operation, isOpen]);

    // Update release label state based on confirmed scrap
    useEffect(() => {
        if (operation.scrapLabel == null) {
            return;
        } else if (parseInt(confirmedScrap) == operation.potentialDiscards) {
            setReleaseLabel(true);
            setIsReleaseLabelReadonly(true);
        } else {
            setIsReleaseLabelReadonly(false);
        }
    }, [operation.scrapLabel, confirmedScrap, operation.potentialDiscards]);


    const operationUpdate = useMutation({
        mutationFn: async (params: MutationParams) => updateOperation(params.operation, { releaseScrapLabel: params.releaseScrapLabel }),
        onSuccess: () => {
            onSuccess(operation);
            onClose();
        }
    });

    const handleConfirmAll = (e: React.MouseEvent) => {
        e.preventDefault();
        setConfirmedScrap(operation.potentialDiscards!.toString());
    }

    const handleReleaseLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!isReleaseLabelReadonly) {
            setReleaseLabel(e.target.checked);
        }
    }

    const handleScrapReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setScrapReason(e.target.value);
    }

    const handleTimeSpentHoursChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setTimeSpentHours(parseInt(e.target.value));
        if (timeSpentMinutes === null) {
            setTimeSpentMinutes(0);
        }
    }

    const handleTimeSpentMinutesChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setTimeSpentMinutes(parseInt(e.target.value));
        if (timeSpentHours === null) {
            setTimeSpentHours(0);
        }
    }

    const handleSubmit = () => {
        const operationWithScrapClose = validateWarnAndGetOp();
        if (operationWithScrapClose) {
            operationUpdate.mutate({
                operation: operationWithScrapClose,
                releaseScrapLabel: releaseLabel,
            });
        }
    }

    const validateWarnAndGetOp = () => {
        const isValidAuto = formRef.current?.reportValidity();
        if (!isValidAuto) {
            return null;
        }

        const confirmedScrapInt = parseInt(confirmedScrap);

        const scrapCloseForm: ScrapCloseForm = {
            addedConfirmedScrap: confirmedScrapInt,
            finalConfirmedScrap: (operation.discardCount ?? 0) + confirmedScrapInt,
            ...(confirmedScrapInt && { scrapReason: scrapReason ?? undefined }),
            comment,
            ...((timeSpentHours != null && timeSpentMinutes != null) && { timeSpent: (timeSpentHours * 60 + timeSpentMinutes) * 60_000 }),
            operator,
            time: operation?.scrapCloseForm?.time ?? undefined,
        };
        const operationWithScrapClose = {
            ...operation,
            scrapCloseForm: scrapCloseForm as ScrapCloseForm,
        }

        return operationWithScrapClose;
    };


    const operationDetails = operation.details.join(' > ');
    const isReleaseLabelDisabled = operation.scrapLabel == null;
    const isReasonDisabled = !confirmedScrap || !parseInt(confirmedScrap);
    const isReasonRequired = !!confirmedScrap && parseInt(confirmedScrap) > 0;
    return <>
        <ModalSubmitDialog
            isOpen={isOpen}
            isSubmitting={operationUpdate.isPending}
            frameClassName='modal-frame--operation-dialog'
            contentClassName='modal-content--tablet'
            onSubmit={handleSubmit}
            onCancel={onClose}
        >
            <div className='operation-dialog__part_number'>{operation.partDisplayName}</div>
            <button style={{ float: 'right' }} onClick={() => setShowPdfDialog(true)}>
                View pdf
            </button>
            <div style={{ color: '#333' }}>
                <div>{operationDetails}</div>
                <div>Initial confirmed scraps {operation.discardCount ?? 0}</div>
            </div>

            <form ref={formRef} style={{ marginTop: '20px' }}>
                <div className='dialog-form__field--with-margins'>
                    <div>Additional confirmed scrap (potential: {operation.potentialDiscards})</div>
                    <div className='dialog-form__input' style={{ display: 'flex', flexDirection: 'row' }}>
                        <Input
                            style={{ width: '100%' }}
                            type='number'
                            step={1}
                            min={0}
                            max={operation.potentialDiscards!.toString()}
                            required
                            autoComplete='off'
                            value={confirmedScrap}
                            onChange={(e) => setConfirmedScrap(e.target.value)}
                        />
                        <button style={{ paddingLeft: '16px', paddingRight: '16px' }} onClick={handleConfirmAll}>
                            All
                        </button>
                    </div>
                </div>

                <div className='dialog-form__field--with-margins'>
                    <input
                        style={{ marginRight: '0.4em' }}
                        type='checkbox'
                        id='releaseScrapLabel'
                        checked={releaseLabel}
                        disabled={isReleaseLabelDisabled}
                        onChange={(e) => handleReleaseLabelChange(e)}
                    />
                    <label
                        htmlFor='releaseScrapLabel'
                        className={classNames({ 'text--disabled': isReleaseLabelDisabled })}
                    >
                        Release scrap label
                    </label>
                </div>

                <fieldset
                    className={classNames('dialog-form__fieldset', { 'text--disabled': isReasonDisabled },)}
                    disabled={isReasonDisabled}
                >
                    <legend>Scrap reason</legend>
                    <RadioButton value='Out of tolerance' checked={scrapReason === 'Out of tolerance'} onChange={handleScrapReasonChange} required={isReasonRequired} />
                    <RadioButton value='Thread bad' checked={scrapReason === 'Thread bad'} onChange={handleScrapReasonChange} required={isReasonRequired} />
                    <RadioButton value='Thread missing' checked={scrapReason === 'Thread missing'} onChange={handleScrapReasonChange} required={isReasonRequired} />
                    <RadioButton value='Cut-off pin' checked={scrapReason === 'Cut-off pin'} onChange={handleScrapReasonChange} required={isReasonRequired} />
                    <RadioButton value='Surface quality' checked={scrapReason === 'Surface quality'} onChange={handleScrapReasonChange} required={isReasonRequired} />
                    <RadioButton value='Other' checked={scrapReason === 'Other'} onChange={handleScrapReasonChange} required={isReasonRequired} />
                </fieldset>

                <div className='dialog-form__field--with-margins'>
                    <div>Comment</div>
                    <textarea
                        className='dialog-form__input'
                        value={comment}
                        rows={2}
                        autoComplete='off'
                        onChange={(e) => setComment(e.target.value)}
                    />
                </div>

                <div className='dialog-form__field--with-margins'>
                    <div>Time spent</div>
                    <div className='dialog-form__input' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Select
                            options={[0, 1, 2, 3, 4, 5]}
                            value={timeSpentHours}
                            onChange={handleTimeSpentHoursChange}
                        />
                        <span style={{ fontSize: 'larger', marginLeft: '4px', marginRight: '12px' }}>h</span>

                        <Select
                            options={[0, 10, 20, 30, 40, 50]}
                            value={timeSpentMinutes}
                            onChange={handleTimeSpentMinutesChange}
                        />
                        <span style={{ fontSize: 'larger', marginLeft: '4px', marginRight: '12px' }}>m</span>
                    </div>
                </div>

                <div className='dialog-form__field--with-margins'>
                    <div>Operator</div>
                    <OperatorSelect
                        className='dialog-form__input'
                        required={true}
                        operatorInitials={operator}
                        onChange={(operatorInitials) => setOperator(operatorInitials)}
                    />
                </div>
            </form>
        </ModalSubmitDialog >

        <ProtocolPdfDialog
            product={operation.product}
            selectedPositionIdx={null}
            show={showPdfDialog}
            onClose={() => setShowPdfDialog(false)}
        />
    </>
}

function RadioButton({
    value,
    checked,
    required,
    onChange,
}: {
    value: string,
    checked: boolean,
    required: boolean,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
}) {
    return (
        <label>
            <input
                style={{ marginRight: '0.4em' }}
                type='radio'
                name='scrapReason'
                required={required}
                value={value}
                checked={checked}
                onChange={onChange}
            />
            {value}
        </label>
    )
}

function Select({
    value,
    options,
    onChange,
}: {
    value: number | null,
    options: number[],
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void,
}) {
    return (
        <select
            style={{ width: '70px' }}
            value={value ?? ''}
            required
            onChange={onChange}
        >
            <option value={''} disabled hidden />
            {options.map(option =>
                <option key={option} value={option}>{option}</option>
            )}
        </select>
    )
}
