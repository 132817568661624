import { RangeKey, RangeOption } from './useRangeOptions';


interface Props {
    style?: React.CSSProperties,
    rangeOptions: RangeOption[],
    selectedRangeKey: RangeKey,
    setSelectedRangeKey: (selectedRangeKey: RangeKey) => void,
}

export default function RangeSelection({
    style,
    rangeOptions,
    selectedRangeKey,
    setSelectedRangeKey,
}: Props) {

    return (
        <div style={{ ...style, display: 'flex', flexDirection: 'column' }}>
            {rangeOptions.map((rangeOption) => (
                <label
                    key={rangeOption.key}
                    style={{ color: !rangeOption.isEnabled ? 'gray' : '' }}
                >
                    <input
                        type='radio'
                        name='range'
                        value={rangeOption.key}
                        checked={rangeOption.key === selectedRangeKey}
                        disabled={!rangeOption.isEnabled}
                        onChange={() => setSelectedRangeKey(rangeOption.key)}
                    />
                    {rangeOption.label}
                </label>
            ))}
        </div>
    );
}
