

//If a value can be parsed into a float, normalize it.
//Other try to eval as a mathematical expression.
//If it fails, return the original value
export function evalOrNormalize(value: string): string {
    if (!value) return value;

    if (isFloat(value)) {
        return normalizeFloat(value);
    } else {
        return attemptEval(value);
    }
}

//If a value has less than 2 decimals, add them. If starts in a period, add leading zero
export function normalizeFloat(value: string): string {
    if (!value) return value;

    let newValue = value.replace(/^\+/, '');//Remove leading plus
    const numDecimals = newValue.split('.')[1]?.length || 0;
    if (numDecimals < 2) {
        newValue = (+newValue).toFixed(2);
    }
    if (newValue.startsWith('.')) {
        newValue = '0' + newValue;
    }
    return newValue;
}

export function isFloat(value: string): boolean {
    if (!value) return false;
    return !isNaN(+value);
}

//Attempt to eval a mathematical expression
function attemptEval(value: string): string {
    if (value.includes('//') || value.includes('/*')) return value;//Avoid comments
    if (/[^0-9+\-*/(). ]/.test(value)) return value;//Avoid invalid characters, to prevent execution of code

    try {
        return eval(value).toFixed(2);
    } catch (e) {
        return value;
    }
}
