

type PickResult = {
    isSupported: boolean;
    isCanceled?: boolean;
    isSuccess?: boolean;
    handle?: FileSystemFileHandle;
}

/** Does a file picker dialog, if it's supported */
export async function pickFileDestination(filename: string): Promise<PickResult> {
    if (!('showSaveFilePicker' in window)) {
        return { isSupported: false };
    }
    try {
        const handle = await (window as any).showSaveFilePicker({ suggestedName: filename });
        return { isSupported: true, isCanceled: false, isSuccess: true, handle };
    } catch (error: any) {
        // Handle user cancellation or permission denial distinctly
        if (error.name === 'AbortError' || error.name === 'NotAllowedError') {
            return { isSupported: true, isCanceled: true, isSuccess: false };
        }
        throw error;
    }
}

export async function saveFileWithPicker(filename: string, blob: Blob): Promise<PickResult> {
    const pickResult = await pickFileDestination(filename);
    if (pickResult.isSuccess && pickResult.handle) {
        await saveBlobViaHandle(pickResult.handle!, blob);
    } else if (!pickResult.isSupported) {
        // File picker is unsupported, fallback to download
        fallbackDownload(filename, blob);
    }
    return pickResult;
}


/** Saves file using handle if supplied. Otherwise saves file using the filename */
export async function saveBlob(
    filename: string,
    blob: Blob,
    handle?: FileSystemFileHandle,
) {
    if (handle) {
        await saveBlobViaHandle(handle, blob);
    } else {
        fallbackDownload(filename, blob);
    }
}

export async function saveBlobViaHandle(handle: FileSystemFileHandle, blob: Blob) {
    const writable = await handle.createWritable();
    await writable.write(blob);
    await writable.close();
}

export function fallbackDownload(filename: string, blob: Blob) {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
}
