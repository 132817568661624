import { useEffect, useState } from 'react';
import { Record } from '../../types/sharedTypeImpl';
import { convertToCsv } from '../../utils/csv';
import { MIME_XLSX } from '../../utils/mimeTypes';
import { pickFileDestination, saveBlob, saveFileWithPicker } from '../../utils/saveFile';
import { stringToBlob } from '../../utils/stringToBlob';
import { trpc } from '../../utils/trpc';
import ModalDialog from '../modalDialog';
import createPreviewTable from './createPreviewTable';
import { createRecordsTable } from './createRecordsTable';
import { RecordsExportTable } from './recordsExportTable';
import { PreviewTable } from './types';
import { useRecordsSelect } from './useRecordsSelect';


interface Props {
    record: Record,
    onClose: () => void,
}

export default function RecordsExportDialog({
    record,
    onClose,
}: Props) {

    const [previewTable, setPreviewTable] = useState<PreviewTable | undefined>(undefined);

    const { records, isLoading } = useRecordsSelect({ record });

    const exportXlsxMutation = trpc.recordsExport.getXlsx.useMutation();


    useEffect(() => {
        if (records) {
            setPreviewTable(createPreviewTable(records));
        } else {
            setPreviewTable(undefined);
        }
    }, [records]);


    const handleIsIncludedChange = (recordIdx: number, isSelected: boolean) => {
        if (!previewTable) return;
        const table = structuredClone(previewTable);
        table.recordRows[recordIdx].includeInReport = isSelected;
        setPreviewTable(table);
    }

    const handleExportCsv = async () => {
        if (!previewTable) return;
        const filename = getFilename('csv');
        const table = createRecordsTable(previewTable, { quotesAroundPosNr: true });
        const csvText = convertToCsv(table, { delimiter: ';', commaDecimals: true });
        const csvBlob = stringToBlob(csvText, 'csv');

        const pickFileResult = await saveFileWithPicker(filename, csvBlob);
        if (pickFileResult.isCanceled) return;
        onClose();
    }

    const handleExportXlsx = async () => {
        if (!previewTable) return;

        const filename = getFilename('xlsx');
        const protocolId = records![0]!.protocolId;
        const table = createRecordsTable(previewTable);
        const xlsxPromise = exportXlsxMutation.mutateAsync({ protocolId, table });
        const pickFileResult = await pickFileDestination(filename);
        if (pickFileResult.isCanceled) return;

        const xlsxResponse = await xlsxPromise;
        const xlsxBlob = new Blob([xlsxResponse], { type: MIME_XLSX });
        saveBlob(filename, xlsxBlob, pickFileResult.handle);
        onClose();
    }

    const getFilename = (extension: string) => {
        if (!record) throw new Error('record is undefined');
        const nameParts = [record.purchaseOrder, record.orderNumber, record.protocolPartDisplayName];
        const filename = nameParts.filter(part => part).join(' ');
        const filenameExt = `${filename}.${extension}`;
        return filenameExt;
    }


    return (
        <ModalDialog
            isOpen={true}
            onCancel={onClose}
        >
            <div style={{ display: 'flex', flexDirection: 'column', gap:'6px', marginBottom:'16px' }}>
                {record.purchaseOrder && <div>Purchase order: {record.purchaseOrder}</div>}
                {record.orderNumber && <div>Order number: {record.orderNumber}</div>}
                {record.protocolPartDisplayName && <div>Part number: {record.protocolPartDisplayName}</div>}
                {(record.measurementType === 'ioqc' && record.comment) && <div>Comment: {record.comment}</div>}
            </div>

            {isLoading || !previewTable
                ? <p>Loading...</p>
                : <>
                    <RecordsExportTable
                        measurementType={record.measurementType}
                        previewTable={previewTable}
                        onIsIncludedChange={handleIsIncludedChange}
                    />
                    <div className='dialog--button-bar'>
                        <button onClick={onClose}>Cancel</button>
                        <button onClick={handleExportCsv}>Csv</button>
                        <button onClick={handleExportXlsx} disabled={exportXlsxMutation.isPending}>Xlsx</button>
                    </div>
                </>
            }
        </ModalDialog>
    );
}
