import { maxBy } from '../../utils/arrayUtils.js';
import { getCountMap } from '../../utils/getCountMap.js';


//Eight or more points on one side of the mean without crossing
export function checkOnOneSideOfMean(
    values: Array<number | null>,
    mean: number,
    precision: number,
): Array<boolean | null> {
    const NUM_CONSECUTIVE = 8;
    const measurementErrorDiff = 0.1 ** precision * 0.55;

    const valueCounts = getCountMap(values, it => it);
    const mostCommonEntry = maxBy(Array.from(valueCounts.entries()), it => it[1]) ?? [null, 0];
    const superCommonValue = mostCommonEntry[1] > values.length * 0.6 && mostCommonEntry[1] > 5 ? mostCommonEntry[0] : null;

    const flags = values.map(it => it != null ? false as boolean : null);
    let sequenceStart = 0;
    let countOnSameSide = 0;
    let lastDirectionAbove = null; // true if above, false if below, null if not yet determined
    for (let i = 0; i < values.length; i++) {
        if (values[i] == null) continue;

        const isOnMean = Math.abs(values[i]! - mean) < measurementErrorDiff;
        if (isOnMean || (superCommonValue != null && values[i] === superCommonValue)) {
            lastDirectionAbove = null;
            countOnSameSide = 0;
            continue;
        }

        const currentDirectionAbove = values[i]! > mean;
        if (currentDirectionAbove === lastDirectionAbove) {
            countOnSameSide++;
        } else {
            countOnSameSide = 1;
            sequenceStart = i;
            lastDirectionAbove = currentDirectionAbove;
        }

        if (countOnSameSide >= NUM_CONSECUTIVE) {
            for (let j = sequenceStart; j <= i; j++) if (flags[j] != null) flags[j] = true;
        }
    }
    return flags;
}
