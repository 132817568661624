import { DateTime } from 'luxon';
import { now } from '../../../shared/utils/dateUtils';
import { Month, Week } from '../../types/timePeriods';


export function getPreviousWeeks(numWeeks: number): Week[] {
    const nowHelsinki = DateTime.fromJSDate(now()).setZone('Europe/Helsinki');
    const startOfCurrentWeek = nowHelsinki.startOf('week');

    const weeks: Week[] = [];
    for (let i = 1; i <= numWeeks; i++) {
        const weekStart = startOfCurrentWeek.minus({ weeks: i }).startOf('week');
        const weekEnd = startOfCurrentWeek.minus({ weeks: i - 1 }).startOf('week');
        const weekNumber = weekStart.weekNumber;

        weeks.push({
            start: weekStart.toJSDate(),
            end: weekEnd.toJSDate(),
            periodNumber: weekNumber,
            label: 'Week: ' + weekNumber
        });
    }

    return weeks.reverse();
}

export function getPreviousMonths(numMonths: number): Month[] {
    const nowHelsinki = DateTime.fromJSDate(now()).setZone('Europe/Helsinki');
    const startOfCurrentMonth = nowHelsinki.startOf('month');

    const months: Month[] = [];
    for (let i = 1; i <= numMonths; i++) {
        const monthStart = startOfCurrentMonth.minus({ month: i }).startOf('month');
        const monthEnd = startOfCurrentMonth.minus({ month: i - 1 }).startOf('month');
        const monthNumber = monthStart.month;

        months.push({
            start: monthStart.toJSDate(),
            end: monthEnd.toJSDate(),
            periodNumber: monthNumber,
            label: 'Month: ' + monthNumber
        });
    }

    return months.reverse();
}
