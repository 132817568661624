import { forwardRef, useImperativeHandle, useRef } from 'react';
import Input from '../input';
import { OpSubFormProps, OpSubFormRef } from './operationSubForm';


export const ToolNumber = forwardRef<OpSubFormRef, OpSubFormProps>(({
    operation,
    onOperationChange,
}: OpSubFormProps, ref) => {
    const inputRef = useRef<TextSubFormRef>(null);

    useImperativeHandle(ref, () => ({
        validateWarnAndGet: (result) => {
            if (!inputRef.current!.validateAndWarn()) return false;
            result.toolNumber = operation.toolNumber;
            result.details = [...(result.details ?? []), operation.toolNumber!];
            return true;
        }
    }));

    return (
        <TextSubForm
            ref={inputRef}
            label='Tool number'
            type='number'
            step='1'
            min='0'
            length={4}
            required={true}
            value={operation.toolNumber?.substring(1)}
            onChange={(value) => onOperationChange({ ...operation, toolNumber: 'T' + value })}
        />
    );
});


export const Offset = forwardRef<OpSubFormRef, OpSubFormProps>(({
    operation,
    onOperationChange,
}: OpSubFormProps, ref) => {
    const inputRef = useRef<TextSubFormRef>(null);

    useImperativeHandle(ref, () => ({
        validateWarnAndGet: (result) => {
            if (!inputRef.current!.validateAndWarn() || !operation.offset || isNaN(parseFloat(operation.offset))) return false;
            result.offset = parseFloat(operation.offset);
            result.details = [...(result.details ?? []), operation.offset];
            return true;
        }
    }));

    return (
        <TextSubForm
            ref={inputRef}
            label='Offset value'
            type='number'
            step='any'
            required={true}
            value={operation.offset}
            onChange={(value) => onOperationChange({ ...operation, offset: value })}
        />
    );
});


export const AdjustmentText = forwardRef<OpSubFormRef, OpSubFormProps>(({
    operation,
    onOperationChange,
}: OpSubFormProps, ref) => {
    const inputRef = useRef<TextSubFormRef>(null);

    useImperativeHandle(ref, () => ({
        validateWarnAndGet: (result) => {
            if (!inputRef.current!.validateAndWarn()) return false;
            result.adjustmentText = operation.adjustmentText;
            result.details = [...(result.details ?? []), (operation.adjustmentText ?? '')];
            return true;
        }
    }));

    return (
        <TextSubForm
            ref={inputRef}
            label='Adjustment'
            type='text'
            required={false}
            value={operation.adjustmentText}
            onChange={(value) => onOperationChange({ ...operation, adjustmentText: value })}
        />
    );
});


export const ProgramEditText = forwardRef<OpSubFormRef, OpSubFormProps>(({
    operation,
    onOperationChange,
}: OpSubFormProps, ref) => {
    const inputRef = useRef<TextSubFormRef>(null);

    useImperativeHandle(ref, () => ({
        validateWarnAndGet: (result) => {
            if (!inputRef.current!.validateAndWarn()) return false;
            result.programEditText = operation.programEditText;
            result.details = [...(result.details ?? []), (operation.programEditText ?? '')];
            return true;
        }
    }));

    return (
        <TextSubForm
            ref={inputRef}
            label='Program edit'
            type='text'
            required={false}
            value={operation.programEditText}
            onChange={(value) => onOperationChange({ ...operation, programEditText: value })}
        />
    );
});


export const PotentialDiscards = forwardRef<OpSubFormRef, OpSubFormProps>(({
    operation,
    onOperationChange,
}: OpSubFormProps, ref) => {
    const inputRef = useRef<TextSubFormRef>(null);

    useImperativeHandle(ref, () => ({
        validateWarnAndGet: (result) => {
            if (!inputRef.current!.validateAndWarn()) return false;
            result.potentialDiscards = operation.potentialDiscards ? parseInt(operation.potentialDiscards) : null;
            return true;
        }
    }));

    return (
        <TextSubForm
            ref={inputRef}
            label='Potential scraps'
            type='number'
            step='1'
            min='0'
            required={false}
            value={operation.potentialDiscards}
            onChange={(value) => onOperationChange({ ...operation, potentialDiscards: value })}
        />
    );
});


export const ConfirmedDiscards = forwardRef<OpSubFormRef, OpSubFormProps>(({
    operation,
    onOperationChange,
}: OpSubFormProps, ref) => {
    const inputRef = useRef<TextSubFormRef>(null);

    useImperativeHandle(ref, () => ({
        validateWarnAndGet: (result) => {
            if (!inputRef.current!.validateAndWarn()) return false;
            result.discardCount = operation.discardCount ? parseInt(operation.discardCount) : null;
            return true;
        }
    }));

    return (
        <TextSubForm
            ref={inputRef}
            label='Confirmed scraps'
            type='number'
            step='1'
            min='0'
            required={false}
            value={operation.discardCount}
            onChange={(value) => onOperationChange({ ...operation, discardCount: value })}
        />
    );
});


export const OrderNumber = forwardRef<OpSubFormRef, OpSubFormProps>(({
    operation,
    onOperationChange,
}: OpSubFormProps, ref) => {
    const inputRef = useRef<TextSubFormRef>(null);

    useImperativeHandle(ref, () => ({
        validateWarnAndGet: (result) => {
            if (!inputRef.current!.validateAndWarn()) return false;
            if (operation.potentialDiscards && !operation.orderNumber?.length) {
                alert('Order number is required when potential scraps are present');
                return false;
            }
            result.orderNumber = operation.orderNumber;
            return true;
        }
    }));

    return (
        <TextSubForm
            ref={inputRef}
            label='Order number'
            type='text'
            required={false}
            value={operation.orderNumber}
            onChange={(value) => onOperationChange({ ...operation, orderNumber: value })}
        />
    );
});



interface TextSubFormRef {
    validateAndWarn: () => boolean;
}
interface TextSubFormProps {
    label: string;
    type: 'number' | 'text';
    step?: string;
    min?: string;
    length?: number;
    required: boolean;
    value?: string;
    onChange: (value: string) => void;
}

const TextSubForm = forwardRef<TextSubFormRef, TextSubFormProps>(({
    label,
    type,
    step,
    min,
    length,
    required,
    value,
    onChange,
}, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
        validateAndWarn,
    }));

    const validateAndWarn = () => {
        const errorMessage = validate();
        if (errorMessage) {
            inputRef.current?.setCustomValidity(errorMessage);
            inputRef.current?.reportValidity();
            return false;
        } else {
            return true;
        }
    }

    const validate = () => {
        if (length && (!value || value.length !== length)) {
            return `Value must have length: ${length}`;
        }
        return null;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        inputRef.current?.setCustomValidity('');
        onChange(e.target.value);
    }

    return (
        <div className='dialog-form__field--with-margins'>
            <div>{label}</div>
            <Input
                ref={inputRef}
                className='dialog-form__input'
                type={type}
                step={step}
                min={min}
                required={required}
                value={value ?? ''}
                onChange={handleChange}
            />
        </div>
    );
});
