import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { getMeasurementsIssues } from '../../../shared/services/page-issues/getMeasurementsIssues';
import { fetcMeasurementIntervalTable } from '../../api/requests';
import Button from '../../components/button';
import NavBar, { NavBarRef } from '../../components/navBar';
import { PageTitle } from '../../components/pageTitle';
import ProgramNameMappingDialog from '../../components/program-name-mapping/programNameMappingDialog';
import { Measurement, MeasurementIntervalState, Record } from '../../types/sharedTypeImpl';
import { openLinkInNewTab } from '../../utils/navigation';
import BatchDetailsDialog from './batchDetailsDialog';
import measurementIntervalSort from './measurementIntervalSort';
import MeasurementIntervalTable from './measurementIntervalTable';
import ProtocolSelectDialog from './protocolSelectDialog';


export default function MeasurementInterval() {
    const navBarRef = useRef<NavBarRef>(null);
    const [pendingMeasurementRow, setPendingMeasurementRow] = useState<MeasurementIntervalState | null>(null);
    const [pendingBatchDetailsRow, setPendingBatchDetailsRow] = useState<MeasurementIntervalState | null>(null);
    const [showMappingsDialog, setShowMappingsDialog] = useState(false);

    const isDialogOpen = pendingMeasurementRow != null || pendingBatchDetailsRow != null || showMappingsDialog;

    const { data, error, refetch } = useQuery({
        queryKey: ['measurementIntervalTable'],
        queryFn: async () => {
            const table = await fetcMeasurementIntervalTable();
            measurementIntervalSort(table);
            return table;
        },
        enabled: !isDialogOpen,
        refetchInterval: 1_000,
    });

    // Update navbar issues, if issue count changes
    useEffect(() => {
        if (data == null) return;
        const numIssues = getMeasurementsIssues(data);
        navBarRef.current?.refetchIfMeasurementsMismatch(numIssues);
    }, [data]);

    function handlePartCountClicked(row: MeasurementIntervalState) {
        if (!row.equipment || (!row.inOfflineMode && !row.product)) {
            return;
        }

        setPendingBatchDetailsRow(row);
    }

    function handleMeasureClicked(row: MeasurementIntervalState) {
        if (!row.equipment || (!row.inOfflineMode && !row.product)) {
            return;
        }

        setPendingMeasurementRow(row);
    }

    async function handleMeasurementAdded(measurement: Measurement, record: Record | null) {
        setPendingMeasurementRow(null);
        refetch();

        if (record != null) {
            openLinkInNewTab(`record-view`, { id: record._id, mode: 'edit' });
        }
    }


    return (<>
        <PageTitle title='Measurement' />
        <NavBar ref={navBarRef} />
        <div className='content' style={{ marginTop: '12px' }}>
            <div className='flex--horz'>
                <p id='error'>{error?.message}</p>
                <div className='flex__spacer' />
                <Button
                    style={{ marginBottom: '8px' }}
                    onClick={() => setShowMappingsDialog(true)}
                    content='Show mappings'
                />
            </div>

            <MeasurementIntervalTable
                data={data ?? []}
                onPartCountClicked={handlePartCountClicked}
                onMeasureClicked={handleMeasureClicked}
            />
        </div>
        <ProtocolSelectDialog
            isOpen={pendingMeasurementRow != null}
            equipment={pendingMeasurementRow?.equipment}
            equipmentDisplayName={pendingMeasurementRow?.displayName}
            product={pendingMeasurementRow?.product}
            onMeasurementAdded={handleMeasurementAdded}
            onClose={() => setPendingMeasurementRow(null)}
        />
        {pendingBatchDetailsRow && <BatchDetailsDialog
            measureIntervalRow={pendingBatchDetailsRow}
            onClose={() => setPendingBatchDetailsRow(null)}
        />}

        {showMappingsDialog &&
            <ProgramNameMappingDialog onClose={() => setShowMappingsDialog(false)} />
        }
    </>);
}
