import { Customer } from '../../../shared/types/customer';


interface Props {
    style: React.CSSProperties;
    customers: Customer[];
    onIsActiveChange: (name: string, isActive: boolean) => void;
}

export default function CustomersTable({
    style,
    customers,
    onIsActiveChange,
}: Props) {
    return (
        <table style={style}>
            <thead>
                <tr>
                    <th>Is active</th>
                    <th>Name</th>
                </tr>
            </thead>
            <tbody>
                {customers?.map(it =>
                    <tr key={it.name}>
                        <td>
                            <input
                                type='checkbox'
                                checked={it.isActive}
                                onChange={(e) => onIsActiveChange(it.name, e.target.checked)}
                            />
                        </td>
                        <td>{it.name}</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}
