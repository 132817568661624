import { clampDate } from '../../../shared/utils/minMaxUtils.js';
import { Period, PeriodInf } from '../../../shared/utils/period.js';


export function overlappingperiod(period1: Period, period2: PeriodInf): Period {
    const start = clampDate(period1.start, period2.start, period2.end);
    const end = clampDate(period1.end, period2.start, period2.end);
    return { start, end };
}

export function overlappingTime(period1: Period, period2: PeriodInf): number {
    const overlap = overlappingperiod(period1, period2);
    return (overlap.end?.getTime() ?? Infinity) - overlap.start.getTime();
}

export function overlappingTimeSum(period: Period, arr: PeriodInf[]): number {
    let sum = 0;
    for (const el of arr) {
        sum += overlappingTime(period, el);
    }
    return sum;
}


export async function forEachOverlapping<T extends PeriodInf, U extends (PeriodInf | PeriodInf[])[]>(
    arr1: T[],
    arrayOrArrayOfArrays: [...U],//We use a the spread operator to preserve the tuple type
    callback: (element: T, overlaps: [...U]) => void | Promise<void>
) {
    const argIsArrayOfArrays = arrayOrArrayOfArrays.length > 0 && Array.isArray(arrayOrArrayOfArrays[0]);
    const arrayOfArrays = (argIsArrayOfArrays ? arrayOrArrayOfArrays : [arrayOrArrayOfArrays]) as PeriodInf[][];

    const indices = new Array(arrayOfArrays.length).fill(0);
    for (const element of arr1) {

        const allOverlapping: PeriodInf[][] = [];
        for (let i = 0; i < arrayOfArrays.length; i++) {

            const { overlapping, scanEndIdx } = getOverlappingPeriods(element, arrayOfArrays[i], indices[i]);
            indices[i] = (overlapping.length > 0)
                ? scanEndIdx - 1 //Go back to last matched position
                : scanEndIdx;

            allOverlapping.push(overlapping);
        }

        const convertedOutput = (argIsArrayOfArrays ? allOverlapping : allOverlapping[0]) as U;

        // Check if the callback is asynchronous and wait for it if necessary
        const result = callback(element, convertedOutput);
        if (result instanceof Promise) {
            await result;
        }
    }
}

export function filterOverlapping<T extends PeriodInf>(
    arrayOfPeriods: T[],
    start: Date | null | undefined,
    end: Date | null | undefined
): T[] {
    const period = { start, end: end ?? null };
    const { overlapping } = getOverlappingPeriods(period, arrayOfPeriods, 0);
    return overlapping;
}

function getOverlappingPeriods<T extends PeriodInf>(
    period: {
        start: Date | null | undefined,
        end: Date | null | undefined
    },
    arrayOfPeriods: T[],
    startIndex: number
): { overlapping: T[], scanEndIdx: number } {
    const overlapping: T[] = [];
    let index = startIndex;

    // Skip irrelevant innerArray elements (ones that end before 'period' begins)
    while (period.start &&
        index < arrayOfPeriods.length &&
        arrayOfPeriods[index].end !== null &&
        arrayOfPeriods[index].end! <= period.start
    ) {
        index++;
    }

    // Find relevant innerArray elements (ones that start before 'period' ends)
    while (index < arrayOfPeriods.length &&
        (period.end == null || arrayOfPeriods[index].start < period.end)
    ) {
        overlapping.push(arrayOfPeriods[index]);
        index++;
    }

    return {
        overlapping,
        scanEndIdx: index
    };
}
