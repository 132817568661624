import { useRef } from 'react';
import Button from './button';


interface Props {
    allowedExtensions: string[];
    content: string;
    multiple?: boolean;
    onFilesSelected: (files: File[]) => void;
}

export default function FileInputButton({
    allowedExtensions,
    content,
    multiple,
    onFilesSelected,
}: Props) {
    const fileInputRef = useRef<HTMLInputElement>(null);

    function handleButtonClick() {
        fileInputRef.current!.click();
    }

    function handleFilesSelected(event: React.ChangeEvent<HTMLInputElement>) {
        if (!event.target.files || event.target.files.length === 0) return;
        const files = Array.from(event.target.files);
        onFilesSelected(files);
        fileInputRef.current!.value = '';
    }

    return <>
        <Button
            type='button'
            onClick={handleButtonClick}
            content={content}
        />
        <input
            ref={fileInputRef}
            type='file'
            accept={allowedExtensions?.join(',')}
            multiple={multiple}
            style={{ display: 'none' }}
            onChange={handleFilesSelected}
            tabIndex={-1}
        />
    </>
}
