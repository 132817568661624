import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { fetchMeasurementDelays, fetchOee, fetchOperations } from '../../api/requests';
import DelaysGraph from '../../components/delays-graph/delaysGraph';
import { DelaysGraphControls, DelaysPeriod } from '../../components/delays-graph/delaysGraphControls';
import { DelaysGraphLegend } from '../../components/delays-graph/delaysGraphLegend';
import NavBar from '../../components/navBar';
import OeeGraphControls from '../../components/oee-graph-controls/oeeGraphControls';
import { OeeGraphOptions } from '../../components/oee-graph-controls/oeeGraphOptions';
import { PageTitle } from '../../components/pageTitle';
import AvailabilityReportGraph from '../../components/reporting-tool-graph/availabilityReportGraph';
import ScrapsTimeGraph from '../../components/reporting-tool-graph/scrapsTimeGraph';
import { getPreviousMonths, getPreviousWeeks } from './getPreviousPeriods';
import ReportTypeSelection from './reportTypeSelection';
import { ReportTypeKey } from './reportTypes';


export default function ReportingTool() {

    const [reportType, setReportType] = useState<ReportTypeKey>('availability');
    const [oeeGraphOptions, setOeeGraphOptions] = useState<OeeGraphOptions>({
        visibleShifts: [
            'morning',
            'evening',
            'night',
        ],
        excludeWeekends: false,
    });
    const [delaysPeriod, setDelaysPeriod] = useState<DelaysPeriod>('weeks');

    const weeks = useMemo(() => getPreviousWeeks(5), []);
    const months = useMemo(() => getPreviousMonths(5), []);
    const weeksRange = { start: weeks[0].start, end: weeks.at(-1)!.end };
    const monthsRange = { start: months[0].start, end: months.at(-1)!.end };
    const maxEnd = new Date(Math.max(+weeksRange.end, +monthsRange.end));

    const { data: oeeData } = useQuery({
        queryKey: ['graphs-data/oee', 'day', 'All equipments', weeksRange.start],
        queryFn: async () => await fetchOee({ windowType: 'shift', equipment: 'All equipments', start: weeksRange.start, }),
        refetchOnWindowFocus: false,
    });

    const { data: measurementDelays } = useQuery({
        queryKey: ['graphs-data/measurement-delays', monthsRange.start, maxEnd],
        queryFn: async () => await fetchMeasurementDelays(monthsRange.start, maxEnd),
        refetchOnWindowFocus: false,
    });

    const { data: scrapCloseOperations } = useQuery({
        queryKey: ['operations', 'start', weeksRange.start, 'end', weeksRange.end, 'hasScrapCloseForm', true],
        queryFn: async () => await fetchOperations({ start: weeksRange.start, end: weeksRange.end, hasScrapCloseForm: true }),
        refetchOnWindowFocus: false,
    });


    return (<>
        <PageTitle title='Reporting' />
        <NavBar />
        <div className='content'>
            <div style={{ height: '90vh', display: 'flex', flexDirection: 'row' }}>
                {/* Options */}
                <div style={{ minWidth: '300px', width: '25%', fontSize: '20px', marginTop: '24px' }}>
                    <ReportTypeSelection
                        selectedReportType={reportType}
                        onReportTypeSelected={setReportType}
                    />

                    <div style={{ marginTop: '64px' }}>
                        {reportType === 'availability' &&
                            <OeeGraphControls
                                shiftOptionsOnly={true}
                                showHeader={true}
                                oeeGraphOptions={oeeGraphOptions}
                                setOeeGraphOptions={setOeeGraphOptions}
                            />
                        }

                        {reportType === 'delays' &&
                            <DelaysGraphControls
                                delaysPeriod={delaysPeriod}
                                setDelaysPeriod={setDelaysPeriod}
                            />
                        }
                    </div>
                </div>

                {/* Graphs */}
                <div style={{ width: '50%' }}>
                    {reportType === 'availability' && <>
                        {!oeeData
                            ? 'Loading...'
                            : <AvailabilityReportGraph
                                oeeData={oeeData}
                                oeeGraphOptions={oeeGraphOptions}
                                weeks={weeks}
                            />
                        }
                    </>}

                    {reportType === 'delays' && <>
                        {!measurementDelays
                            ? 'Loading...'
                            : <DelaysGraph
                                measurementDelays={measurementDelays}
                                periods={delaysPeriod === 'weeks' ? weeks : months}
                            />
                        }
                    </>}

                    {reportType === 'scraps_time_spent' && <>
                        {!scrapCloseOperations
                            ? 'Loading...'
                            : <ScrapsTimeGraph
                                operations={scrapCloseOperations}
                                weeks={weeks}
                            />
                        }
                    </>}
                </div>

                {/* Legends */}
                <div>
                    {reportType === 'delays' &&
                        <DelaysGraphLegend />
                    }
                </div>
            </div>
        </div>
    </>);
}
