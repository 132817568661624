import { PreviewTable } from './types';


export function createRecordsTable(
    previewTable: PreviewTable,
    options?: { quotesAroundPosNr?: boolean }
): string[][] {
    const headerRow = [
        'Part\\Position',
        ...(options?.quotesAroundPosNr
            ? previewTable.positionNumbers.map(it => `"${it}"`)
            : previewTable.positionNumbers
        )
    ];
    const nominalsRow = ['Nominal', ...previewTable.nominalValues];
    const upperTolerancesRow = ['Upper tolerance', ...previewTable.upperTolerances];
    const lowerTolerancesRow = ['Lower tolerance', ...previewTable.lowerTolerances];

    const valueRows = [];
    for (const recordRow of previewTable.recordRows) {
        if (!recordRow.includeInReport) continue;

        const row = [recordRow.partCount.toString()];
        for (const value of recordRow.values) {
            row.push(value.value);
        }
        valueRows.push(row);
    }

    const table = [headerRow, nominalsRow, upperTolerancesRow, lowerTolerancesRow, ...valueRows];
    return table;
}
