import { skipToken, useQuery } from '@tanstack/react-query';
import { maxBy, sortBy } from '../../../shared/utils/arrayUtils';
import { fetchProtocol, fetchSessions } from '../../api/requests';
import { trpc } from '../../utils/trpc';


interface Props {
    equipment: string;
    product: string;
}

export function useLoadRecords({
    equipment,
    product,
}: Props) {

    const { data: latestSession } = useQuery({
        queryKey: ['sessions', product],
        queryFn: async () => await fetchSessions(equipment, undefined, product),
        enabled: product != undefined,
        select: sessions => maxBy(sessions, it => +it.start),
    });

    const { data: records } = trpc.records.getRecordsWithProtocols.useQuery(
        latestSession ? {
            equipment,
            measurementStart: { start: latestSession.start, end: latestSession.end ?? undefined, },
            isDraft: false,
        } : skipToken,
        { select: it => sortBy([...it], it => it.measurementStart) }
    );
    const latestRecord = records?.at(-1);
    const orderNumber = latestRecord?.orderNumber;

    const { data: protocol } = useQuery({
        queryKey: ['protocols', latestRecord?.protocolId],
        queryFn: async () => await fetchProtocol(latestRecord!.protocolId),
        enabled: latestRecord?.protocolId != undefined,
    });


    return {
        records,
        protocol,
        orderNumber,
    };
}
