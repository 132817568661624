import { v4 as uuidv4 } from 'uuid';
import { PositionDraft, ProtocolDraft, ProtocolFinal, Record, RecordDraft, RecordFinal } from '../types/sharedTypeImpl';


export function protocolToDraft(protocol: ProtocolFinal): ProtocolDraft {
    return {
        ...protocol,
        positions: protocol.positions.map(position => ({
            ...position,
            key: uuidv4(),
        })),
    }
}

export function draftToProtocol(draft: ProtocolDraft): ProtocolFinal {
    const protocol = structuredClone(draft) as ProtocolFinal;
    protocol.positions.forEach(position => {
        delete (position as Partial<PositionDraft>).key;
        delete (position as Partial<PositionDraft>).imageFile;
    });
    return protocol;
}


export function recordToDraft(record: Record): RecordDraft {
    return {
        ...structuredClone(record),
        isDraft: true,
    }
}

export function draftToRecord(
    draft: RecordDraft,
    numIssues: number,
    criticalValuesMissing: boolean
): RecordFinal {
    return {
        ...structuredClone(draft),
        isDraft: false,
        numIssues,
        criticalValuesMissing,
    }
}
