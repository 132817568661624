import { Dispatch, SetStateAction } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Point } from '../../../shared/types/mathTypes';
import { Marker, Position, PositionType } from '../../../shared/types/protocol';
import { maxOf, removeElementByIndex, sortBy } from '../../../shared/utils/arrayUtils';
import { partDisplayName } from '../../../shared/utils/partDisplayName';
import PositionSelection from '../../types/positionSelection';
import { PositionDraft, ProtocolDraft } from '../../types/sharedTypeImpl';
import { getTolerances } from './generalTolerances';


export const useUpdateProtocol = (
    protocol: ProtocolDraft | null,
    setProtocol: Dispatch<SetStateAction<ProtocolDraft | null>>,
    setPositionSelection: (positionSelection: PositionSelection | null) => void,
    setIsDirty: (isDirty: boolean) => void
) => {

    const setProtocolField = (field: keyof ProtocolDraft, value: string | string[] | null) => {
        setProtocol((protocol: ProtocolDraft | null) => {
            if (!protocol) return null;

            const newProtocol = {
                ...protocol,
                [field]: value,
            };

            if (field === 'generalTolerance') {
                recalcAllTolerances(newProtocol, value as string);
            }
            if (field === 'partName' || field === 'partRevision') {
                newProtocol.partDisplayName = partDisplayName(newProtocol.partName, newProtocol.partRevision);
            }

            return newProtocol;
        });
        setIsDirty(true);
    };

    const setPositionField = (index: number, field: keyof Position, value: string | number | boolean) => {
        if (!protocol) return;

        const positions = [...protocol.positions];
        positions[index] = {
            ...protocol.positions[index],
            [field]: value,
        };
        if (protocol.generalTolerance) {
            positions[index] = setTolerancesIf(positions[index], field, protocol.generalTolerance);
        }

        setProtocol({
            ...protocol,
            positions,
        });
        setIsDirty(true);
    };

    const setMarkerField = (positionIdx: number, markerIdx: number, field: keyof Marker, value: string | number) => {
        if (!protocol) return;

        const positions = [...protocol.positions];
        const position = positions[positionIdx];
        const marker = position.markers[markerIdx];

        position.markers[markerIdx] = {
            ...marker,
            [field]: value,
        };

        setProtocol({
            ...protocol,
            positions,
        });
        setIsDirty(true);
    };

    const setMarkerLocation = (positionSelection: PositionSelection, page: number, pdfCoords: Point, pdfCoords2?: Point) => {
        if (!protocol) return;

        const positions = [...protocol.positions];
        const position = positions[positionSelection.positionIdx];
        positions[positionSelection.positionIdx].page = page;

        const markers = position.markers;
        const marker = markers[positionSelection.markerIdx ?? 0];
        marker.x = pdfCoords.x;
        marker.y = pdfCoords.y;
        if (pdfCoords2) {
            marker.x2 = pdfCoords2.x;
            marker.y2 = pdfCoords2.y;
        } else {
            delete marker.x2;
            delete marker.y2;
        }

        setProtocol({
            ...protocol,
            positions,
        });
        setIsDirty(true);
    };

    const addPosition = (type: PositionType, page: number, pdfCoords: Point, pdfCoords2?: Point) => {
        if (!protocol) return;

        const maxPosNum = maxOf(protocol.positions ?? [], (it: PositionDraft) => +it.positionNumber);
        const nextPosNum = maxPosNum ? Math.floor(maxPosNum) + 1 : 1;
        const newPosition: PositionDraft = {
            key: uuidv4(),
            type,
            page,
            markers: [{
                x: pdfCoords.x,
                y: pdfCoords.y,
                ...(pdfCoords2 && { x2: pdfCoords2?.x }),
                ...(pdfCoords2 && { y2: pdfCoords2?.y }),
            }],
            positionNumber: nextPosNum.toString(),
        };

        const positions = [...protocol.positions ?? [], newPosition];
        setProtocol({
            ...protocol,
            positions,
        });
        setPositionSelection({ positionIdx: positions.length - 1 });
        setIsDirty(true);
    };

    const addMarker = (positionSelection: PositionSelection, pdfCoords: Point, pdfCoords2?: Point) => {
        if (!protocol) return;

        const positions = [...protocol.positions];
        const position = positions[positionSelection.positionIdx];
        const markers = position.markers;
        const newMarker: Marker = {
            x: pdfCoords.x,
            y: pdfCoords.y,
            ...(pdfCoords2 && { x2: pdfCoords2?.x }),
            ...(pdfCoords2 && { y2: pdfCoords2?.y }),
        };
        markers.push(newMarker);

        setProtocol({
            ...protocol,
            positions,
        });
        setIsDirty(true);
    };

    const removePosition = (positionIdx: number) => {
        if (!protocol) return;

        const positions = removeElementByIndex(protocol.positions, positionIdx);
        setProtocol({
            ...protocol,
            positions,
        });
        setPositionSelection(null);
        setIsDirty(true);
    };

    const removeMarker = (positionIdx: number, markerIdx: number) => {
        if (!protocol) return;

        const positions = [...protocol.positions];
        const position = positions[positionIdx];
        const markers = position.markers;
        markers.splice(markerIdx, 1);
        if (markers.length === 0) {
            positions.splice(positionIdx, 1);
        }

        setProtocol({
            ...protocol,
            positions,
        });
        setPositionSelection({ positionIdx });
        setIsDirty(true);
    }

    const setPositionImage = (index: number, imageData: File) => {
        if (!protocol) return;

        const positions = [...protocol.positions];
        const position = positions[index];
        position.imageFile = imageData;

        setProtocol({
            ...protocol,
            positions,
        });
        setIsDirty(true);
    };

    const reorderPositions = () => {
        if (!protocol) return;
        const positions = sortBy([...protocol.positions], (it => +it.positionNumber));
        setProtocol({
            ...protocol,
            positions,
        });
        setPositionSelection(null);
        setIsDirty(true);
    };

    return {
        setProtocolField,
        setPositionField,
        setMarkerField,
        setMarkerLocation,
        addPosition,
        addMarker,
        removePosition,
        removeMarker,
        setPositionImage,
        reorderPositions,
    }
};


function recalcAllTolerances(protocol: ProtocolDraft, generalTolerance: string) {
    protocol.positions = protocol.positions.map(it => recalcTolerances(it, generalTolerance));
}

const setTolerancesIf = (
    position: PositionDraft,
    changedField: keyof PositionDraft,
    generalTolerance: string
): PositionDraft => {
    const fieldsCauseRecalc: Array<keyof PositionDraft> = ['nominal', 'useGeneralTolerance', 'isChamfer'];
    if (position.useGeneralTolerance && fieldsCauseRecalc.includes(changedField)) {
        const tolerances = getTolerances(position.nominal!, generalTolerance, position.isChamfer!);
        return {
            ...position,
            upperTolerance: tolerances?.upperTolerance ?? '',
            lowerTolerance: tolerances?.lowerTolerance ?? '',
        }
    } else if (!position.useGeneralTolerance && changedField === 'useGeneralTolerance') {
        return {
            ...position,
            upperTolerance: '',
            lowerTolerance: '',
        }
    }
    return position;
};

function recalcTolerances(
    position: PositionDraft,
    generalTolerance: string
): PositionDraft {
    if (position.useGeneralTolerance) {
        const tolerances = getTolerances(position.nominal!, generalTolerance, position.isChamfer!);
        return {
            ...position,
            upperTolerance: tolerances?.upperTolerance ?? '',
            lowerTolerance: tolerances?.lowerTolerance ?? '',
        };
    }
    return position;
}
