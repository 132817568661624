import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { groupBy, sortBy } from '../../../shared/utils/arrayUtils';
import { partDisplayName } from '../../../shared/utils/partDisplayName';
import { fetchProgramNameMappings } from '../../api/requests';
import { ClearFiltersButton } from '../clearFiltersButton';


export default function ProgramNameMappings() {
    const [filterInput, setFilterInput] = useState('');

    const { data: programNameMappings, error, isLoading } = useQuery({
        queryKey: ['programNameMappings'],
        queryFn: () => fetchProgramNameMappings({ isHidden: false }),
        select: mappings => {
            const withDisplayName = mappings.map(mapping => ({
                ...mapping,
                partDisplayName: partDisplayName(mapping.partName, mapping.partRevision)
            }));
            const grouped = groupBy(withDisplayName, it => it.partDisplayName);
            const sorted = sortBy(grouped, it => it[0].partDisplayName);
            return sorted;
        }
    });

    const filteredProgramNameMappings = programNameMappings?.filter(it =>
        it[0].partDisplayName.toLowerCase().includes(filterInput.toLowerCase()) ||
        it.some(mapping => mapping.program.toLowerCase().includes(filterInput.toLowerCase()))
    );

    const handleClearFilters = () => {
        setFilterInput('');
    }


    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>An error has occurred: {error.message}</div>;
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', minWidth: '600px' }}>

                <input
                    style={{ marginLeft: '18px' }}
                    type='text'
                    placeholder='Search'
                    value={filterInput}
                    onChange={e => setFilterInput(e.target.value)}
                />
                <ClearFiltersButton onClick={() => handleClearFilters()} />
            </div>

            <table className='program-name-mapping--table'>
                <thead>
                    <tr>
                        <th>Part number</th>
                        <th>Program Name</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredProgramNameMappings?.map(it => (
                        <tr key={it[0].partDisplayName}>
                            <td>{it[0].partDisplayName}</td>
                            <td>
                                {
                                    it.map(mapping => mapping.program).map((program, index) => (
                                        <div key={index}>{program}</div>
                                    ))
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>

            </table>
        </div>
    );
}
