

interface Props {
    pageCount: number | null;
    page: number;
    setPage: (page: number) => void;
    onFitToHeight: () => void;
}

export default function ButtonBar({
    pageCount,
    page,
    setPage,
    onFitToHeight,
}: Props) {
    return (
        <div className='button-bar'>
            <button className='fit-to-height' onClick={(e) => { e.stopPropagation(); onFitToHeight(); }}>
                <img src='/vertical-icon.svg' alt='Fit to height' />
            </button>

            {pageCount && Array.from({ length: pageCount }, (_, idx) =>
                <button
                    style={idx + 1 === page
                        ? { textDecoration: 'underline', }
                        : {}
                    }
                    key={idx}
                    onClick={() => setPage(idx + 1)}>
                    {`Page ${idx + 1}`}
                </button>
            )}
        </div>
    );
}
